import { useState, useRef, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Popover,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { Partner } from '../../../models/partners/Partner';
import { Form, Formik, FieldArray } from 'formik';
import { FormTextField } from '../../common/input/FormTextField';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Access } from '../../../access/Access';
import { SavePartnerResponse } from '../../../models/partners/SavePartnerResponse';
import { useDispatch, useSelector } from 'react-redux';
import { selectOpenGeneralInfo, setOpenGeneralInfo, updateState } from '../../../slices/partnersSlice';
import { SavePartnerRequest } from '../../../models/partners/SavePartnerRequest';
import { addMessage } from '../../../slices/applicationSlice';
import { Signer } from '../../../models/partners/Signer';
import { FormSelect } from '../../common/input/FormSelect';
import { OpeningHour } from '../../../models/partners/OpeningHour';
import { Day } from '../../../models/partners/Day';
import TimeSelector from '../../common/TimeSelector';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { FormCheckboxWithLabel } from '../../common/input/FormCheckboxWithLabel';

interface Props {
  partner: Partner;
}

interface ContactPersonValues {
  phone: string;
  name: string;
  email: string;
  notes: string;
}

interface EngagementValues {
  notes: string;
  value: number;
  signer: Signer;
}
interface OpeningHourValues {
  id: number;
  from: Day;
  to: Day;
  fromHour: string;
  toHour: string;
  setLunch: boolean;
  lunchFrom: string;
  lunchTo: string;
}

interface GeneralInfoValues {
  phone: string;
  email: string;
  address: string;
  contactPerson: ContactPersonValues;
  openingHours: OpeningHourValues[];
  otherNotes: string;
  engagement: EngagementValues;
}

export const GeneralInfo: React.FC<Props> = ({ partner }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const openGeneralInfo: boolean | undefined = useSelector(selectOpenGeneralInfo);

  let ref = useRef(null) as any;
  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'general-info-popover' : undefined;

  useEffect(() => {
    if (openGeneralInfo) {
      setIsOpen(true);
      dispatch(setOpenGeneralInfo(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openGeneralInfo]);

  const dayOptions = useMemo(
    () =>
      Object.keys(Day)
        .filter((key: any) => !isNaN(Number(Day[key])))
        .map((key) => {
          let value = (Day as any)[key];
          let label = key;
          switch (key) {
            case 'Monday':
              label = 'Hétfő';
              break;
            case 'Tuesday':
              label = 'Kedd';
              break;
            case 'Wednesday':
              label = 'Szerda';
              break;
            case 'Thursday':
              label = 'Csütörtök';
              break;
            case 'Friday':
              label = 'Péntek';
              break;
            case 'Saturday':
              label = 'Szombat';
              break;
            case 'sunday':
              label = 'Vasárnap';
              break;
            default:
          }
          return {
            key: value,
            value: label,
          };
        }),
    []
  );

  const initialValues: GeneralInfoValues = {
    phone: partner.generalInfo.phone ?? '',
    email: partner.generalInfo.email ?? '',
    address: partner.generalInfo.address ?? '',
    contactPerson: {
      name: partner.generalInfo.contactPerson?.name ?? '',
      phone: partner.generalInfo.contactPerson?.phone ?? '',
      email: partner.generalInfo.contactPerson?.email ?? '',
      notes: partner.generalInfo.contactPerson?.notes ?? '',
    },
    otherNotes: partner.generalInfo.otherNotes ?? '',
    openingHours:
      partner.generalInfo.openingHours.map((o) => ({
        ...o,
        setLunch: o.lunchFrom != null && o.lunchFrom !== '' && o.lunchTo != null && o.lunchTo !== '',
        lunchFrom: o.lunchFrom ?? '12:00',
        lunchTo: o.lunchTo ?? '13:00',
      })) ?? [],
    engagement: {
      value: partner.generalSaleData?.engagement?.value ?? 0,
      notes: partner.generalSaleData?.engagement?.notes ?? '',
      signer: partner.generalSaleData?.engagement?.signer ?? Signer.Manager,
    },
  };

  const signerOptions = Object.keys(Signer)
    .filter((key: any) => !isNaN(Number(Signer[key])))
    .map((key) => {
      let value = (Signer as any)[key];
      let label = value;
      switch (key) {
        case 'Manager':
          label = 'Üzletvezető';
          break;
        case 'Owner':
          label = 'Tulajdonos';
          break;
        default:
      }
      return {
        key: value,
        value: label,
      };
    });

  const handleSubmit = (value: GeneralInfoValues): void => {
    const access = new Access();
    const request: SavePartnerRequest = {
      partnerId: partner.id,
      saveGeneralInfo: {
        generalInfo: {
          address: value.address,
          contactPerson: { ...value.contactPerson },
          openingHours: value.openingHours.map(
            (hour: OpeningHourValues) =>
              ({
                id: hour.id,
                from: hour.from,
                to: hour.to,
                fromHour: hour.fromHour,
                toHour: hour.toHour,
                lunchFrom: hour.setLunch ? hour.lunchFrom : undefined,
                lunchTo: hour.setLunch ? hour.lunchTo : undefined,
              } as OpeningHour)
          ),
          otherNotes: value.otherNotes,
          phone: value.phone,
          email: value.email,
        },
        engagement: {
          ...value.engagement,
          value:
            partner.generalSaleData?.engagement?.isEditable === true
              ? value.engagement.value
              : partner.generalSaleData?.engagement?.value ?? 0,
          isActual: partner.generalSaleData?.engagement?.isActual ?? false,
          isEditable: partner.generalSaleData?.engagement?.isEditable ?? false,
          editForYear: partner.generalSaleData?.engagement?.editForYear ?? 0,
          valueForYear: partner.generalSaleData?.engagement?.valueForYear ?? 0,
        },
      },
    };
    access
      .savePartner(request)
      .then((value: SavePartnerResponse | undefined) => {
        if (value?.error == null && value?.partner != null) {
          dispatch(updateState(value));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-partner' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        if (ref) ref.setSubmitting(false);
        setIsOpen(false);
      });
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer}
        color="primary"
        aria-describedby={idPopover}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        aria-haspopup="true"
        aria-owns={openPopover ? idPopover : undefined}
      >
        <PersonOutlineIcon fontSize="large" />
      </IconButton>
      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
        }}
      >
        <Typography sx={{ p: 2 }} whiteSpace="pre-line">
          Partner információ
        </Typography>
      </Popover>
      <SwipeableDrawer anchor="right" open={isOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
          <Typography variant="h5">Partner információ</Typography>
          <Box sx={{ pt: 4 }}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleSubmit}
              innerRef={(instance) => {
                ref = instance;
              }}
            >
              {({ isSubmitting, values, errors, setFieldValue }) => (
                <>
                  {isSubmitting && (
                    <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                      <LinearProgress />
                    </Box>
                  )}
                  <Form>
                    <Box pb={2}>
                      <TextField
                        size="small"
                        label="Név"
                        name="name"
                        id="name"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={partner.name}
                        variant="standard"
                      />
                    </Box>

                    <Box pb={2}>
                      <FormTextField size="small" label="Telefon" name="phone" id="phone" fullWidth />
                    </Box>
                    <Box pb={2}>
                      <FormTextField size="small" label="Email" name="email" id="email" fullWidth />
                    </Box>
                    <Box pb={2}>
                      <FormTextField size="small" multiline label="Cím" name="address" id="address" fullWidth />
                    </Box>

                    <Typography>Nyitvatartás</Typography>
                    <Box mb={3}>
                      <FieldArray name="openingHours">
                        {(arrayHelpers) => (
                          <>
                            {values.openingHours.map((openingHour: OpeningHourValues, i: number) => {
                              const date = moment();
                              const splitedStart = openingHour.fromHour.split(':');
                              const splitedEnd = openingHour.toHour.split(':');
                              const splitedLunchStart = openingHour.lunchFrom.split(':');
                              const splitedLunchEnd = openingHour.lunchTo.split(':');

                              const startLunch = date
                                .set('hours', Number(splitedLunchStart[0]))
                                .set('minutes', Number(splitedLunchStart[1]))
                                .toDate();
                              const endLunch = date
                                .set('hours', Number(splitedLunchEnd[0]))
                                .set('minutes', Number(splitedLunchEnd[1]))
                                .toDate();
                              const startTime = date
                                .set('hours', Number(splitedStart[0]))
                                .set('minutes', Number(splitedStart[1]))
                                .toDate();
                              const endTime = date
                                .set('hours', Number(splitedEnd[0]))
                                .set('minutes', Number(splitedEnd[1]))
                                .toDate();

                              const fromError =
                                errors.openingHours != null ? (errors.openingHours![i] as any).fromHour : undefined;
                              const toError =
                                errors.openingHours != null ? (errors.openingHours![i] as any).toHour : undefined;
                              const lunchFromError =
                                errors.openingHours != null ? (errors.openingHours![i] as any).lunchFrom : undefined;
                              const lunchToError =
                                errors.openingHours != null ? (errors.openingHours![i] as any).lunchTo : undefined;

                              return (
                                <Paper sx={{ p: 2, my: 1 }} key={openingHour.id}>
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <IconButton onClick={() => arrayHelpers.remove(i)}>
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <FormControl fullWidth sx={{ mt: 1, mr: 2 }}>
                                      <InputLabel id={`label.${i}.from`} sx={{ ml: -2 }}>
                                        Nap
                                      </InputLabel>
                                      <FormSelect
                                        labelId={`label.${i}.from`}
                                        name={`openingHours.${i}.from`}
                                        id={`openingHours.${i}.from`}
                                        variant="standard"
                                      >
                                        {(dayOptions ?? []).map((option) => (
                                          <MenuItem value={option.key} key={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        ))}
                                      </FormSelect>
                                    </FormControl>
                                    -
                                    <FormControl fullWidth sx={{ mt: 1, ml: 2 }}>
                                      <InputLabel id={`label.${i}.to`} sx={{ ml: -2 }}>
                                        Nap
                                      </InputLabel>
                                      <FormSelect
                                        labelId={`label.${i}.to`}
                                        name={`openingHours.${i}.to`}
                                        id={`openingHours.${i}.to`}
                                        variant="standard"
                                      >
                                        {(dayOptions ?? []).map((option) => (
                                          <MenuItem
                                            value={option.key}
                                            key={option.key}
                                            disabled={option.key < values.openingHours[i].from}
                                          >
                                            {option.value}
                                          </MenuItem>
                                        ))}
                                      </FormSelect>
                                    </FormControl>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      mt: 1,
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <TimeSelector
                                      label="Nyitás"
                                      value={startTime}
                                      variant="standard"
                                      error={fromError != null || toError != null}
                                      helperText={fromError}
                                      onChange={(time: Date) =>
                                        setFieldValue(`openingHours.${i}.fromHour`, moment(time).format('HH:mm'))
                                      }
                                      size="small"
                                    />
                                    <Typography sx={{ mx: 2 }}> - </Typography>
                                    <TimeSelector
                                      label="Zárás"
                                      value={endTime}
                                      variant="standard"
                                      error={fromError != null || toError != null}
                                      helperText={toError}
                                      onChange={(time: Date) =>
                                        setFieldValue(`openingHours.${i}.toHour`, moment(time).format('HH:mm'))
                                      }
                                      size="small"
                                    />
                                  </Box>
                                  <Box>
                                    <FormCheckboxWithLabel label="Ebédidő" name={`openingHours.${i}.setLunch`} />
                                  </Box>
                                  {openingHour.setLunch && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        mt: 1,
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <TimeSelector
                                        label="Ebédidő kezdete"
                                        value={startLunch}
                                        variant="standard"
                                        error={lunchFromError != null || lunchToError != null}
                                        helperText={lunchFromError}
                                        onChange={(time: Date) =>
                                          setFieldValue(`openingHours.${i}.lunchFrom`, moment(time).format('HH:mm'))
                                        }
                                        size="small"
                                      />
                                      <Typography sx={{ mx: 2 }}> - </Typography>
                                      <TimeSelector
                                        label="Ebédidő vége"
                                        value={endLunch}
                                        variant="standard"
                                        error={lunchFromError != null || lunchToError != null}
                                        helperText={lunchToError}
                                        onChange={(time: Date) =>
                                          setFieldValue(`openingHours.${i}.lunchTo`, moment(time).format('HH:mm'))
                                        }
                                        size="small"
                                      />
                                    </Box>
                                  )}
                                </Paper>
                              );
                            })}

                            <Button
                              sx={{ mt: 2 }}
                              onClick={() => {
                                arrayHelpers.insert(values.openingHours?.length ?? 0, {
                                  id: 0,
                                  from: 1,
                                  to: 1,
                                  fromHour: '08:00',
                                  toHour: '16:00',
                                  lunchFrom: '12:00',
                                  lunchTo: '13:00',
                                  setLunch: false,
                                } as OpeningHourValues);
                              }}
                              startIcon={<AddIcon />}
                            >
                              Hozzáadás
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Box>
                    <Typography>Kapcsolattartó</Typography>
                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label="Név"
                        name="contactPerson.name"
                        id="contactPerson.name"
                        fullWidth
                      />
                    </Box>
                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label="Email"
                        name="contactPerson.email"
                        id="contactPerson.email"
                        fullWidth
                      />
                    </Box>
                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label="Telefon"
                        name="contactPerson.phone"
                        id="contactPerson.phone"
                        fullWidth
                      />
                    </Box>

                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label="Megjegyzés"
                        name="contactPerson.notes"
                        id="contactPerson.notes"
                        fullWidth
                        multiline
                      />
                    </Box>
                    <Typography>{`${partner.generalSaleData.engagement?.editForYear} - Bónusz Megállapodás`}</Typography>
                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label={`${partner.generalSaleData.engagement?.editForYear} - Érték`}
                        name="engagement.value"
                        id="engagement.value"
                        fullWidth
                        type="number"
                        InputProps={{
                          readOnly: partner.generalSaleData?.engagement?.isEditable === false,
                        }}
                      />
                      {partner.generalSaleData?.engagement?.isActual === false && (
                        <Typography variant="caption" color="error">
                          Az érték már nem releváns, előző időszakra vonatkozik.
                        </Typography>
                      )}
                    </Box>
                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label="Megjegyzés"
                        name="engagement.notes"
                        id="engagement.notes"
                        fullWidth
                        multiline
                      />
                    </Box>
                    <Box pb={2} pl={2}>
                      <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel id="signer-label" sx={{ ml: -2 }}>
                          Megkötötte
                        </InputLabel>
                        <FormSelect
                          labelId="signer-label"
                          name="engagement.signer"
                          id="engagement.signer"
                          variant="standard"
                        >
                          {(signerOptions ?? []).map((option) => (
                            <MenuItem value={option.key} key={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      </FormControl>
                    </Box>
                    <Box pb={2}>
                      <FormTextField
                        size="small"
                        label="Egyéb megállapodások és megjegyzések"
                        name="otherNotes"
                        id="otherNotes"
                        fullWidth
                        multiline
                        maxRows={15}
                      />
                    </Box>
                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={toggleDrawer} disabled={isSubmitting} sx={{ mr: 2 }}>
                        Mégse
                      </Button>
                      <Button variant="contained" type="submit" disabled={isSubmitting}>
                        Mentés
                      </Button>
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};
