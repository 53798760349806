import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Access } from '../../../access/Access';
import { useDispatch, useSelector } from 'react-redux';
import { selectTasks, setTasks } from '../../../slices/myTargetSlice';
import { addMessage } from '../../../slices/applicationSlice';
import { Loading } from '../../common/Loading';
import { MyTargetInfoRequest } from '../../../models/myTarget/MyTargetInfoRequest';
import { MyTargetInfoResponse } from '../../../models/myTarget/MyTargetInfoResponse';
import { Tasks } from './task/Tasks';
import { Task } from '../../../models/myTarget/Task';

export interface TargetPartnerValues {
  id: number;
  name: string;
  address: string;
  partnerCode?: string;
}

interface Props {
  agentId: number;
  isAdmin: boolean;
}
export const MyTasks: React.FC<Props> = ({ agentId, isAdmin }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const tasks: Task[] | null | undefined = useSelector(selectTasks);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tasks === undefined) {
      dispatch(setTasks(null));
      setIsLoading(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getTasks: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.tasks != null) {
            dispatch(setTasks(value.agentInfo.tasks));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, tasks]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {tasks != null && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tasks data={tasks} agentId={agentId} isAdmin={isAdmin} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
