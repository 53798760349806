import { ReactElement } from 'react';
import { Chip } from '@mui/material';
import { TaskStatus } from '../../../../models/myTarget/TaskStatus';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  data: TaskStatus | undefined;
  onDelete?: (id: TaskStatus) => void;
}

export const getStatus = (
  status: TaskStatus | undefined
): { label: string; color: any; icon: ReactElement } | undefined => {
  if (status == null) return undefined;
  switch (status) {
    case TaskStatus.open:
      return {
        label: 'Új',
        color: 'secondary',
        icon: <GradeOutlinedIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case TaskStatus.inProgress:
      return {
        label: 'Folyamatban',
        color: 'primary',
        icon: <LoopIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case TaskStatus.done:
      return { label: 'Elkészült', color: 'success', icon: <DoneIcon color="info" sx={{ fill: '#fff' }} /> };
    case TaskStatus.accepted:
      return { label: 'Elfogadott', color: 'info', icon: <DoneAllIcon color="success" sx={{ fill: '#fff' }} /> };
    case TaskStatus.closed:
      return { label: 'Lezárva', color: 'default', icon: <CloseIcon /> };
  }
};

export const Status: React.FC<Props> = ({ data, onDelete }: Props) => {
  const status = getStatus(data);
  return data && status ? (
    <Chip
      size="small"
      avatar={status.icon}
      sx={{ my: 0.3, minWidth: 105 }}
      color={status.color}
      label={status.label}
      onDelete={
        onDelete != null
          ? () => {
              onDelete(data);
            }
          : undefined
      }
    />
  ) : null;
};
