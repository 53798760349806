import { Box, Typography } from '@mui/material';
import GaugeComponent from 'react-gauge-component';
import { Info } from './Info';
import { useFormat } from '../../utility/useFormat';
import { Pointer } from '../../models/common/Pointer';
import { SubArc } from 'react-gauge-component/dist/lib/GaugeComponent/types/Arc';
import { Tick } from 'react-gauge-component/dist/lib/GaugeComponent/types/Tick';

interface Props {
  title: string;
  data: Pointer;
  targetText: string;
  targetInfo?: string;
  completementText: string;
  completementInfo?: string;
  percentText: string;
  unit: string;
}

export const Gauge: React.FC<Props> = ({
  title,
  data,
  targetText,
  targetInfo,
  completementText,
  completementInfo,
  percentText,
  unit,
}: Props) => {
  const { formatNumber } = useFormat();
  const gaugeColors = ['#EA4228', '#E98F29', '#E6DB2A', '#A9E12A', '#A5E22A', '#5BE12C'];

  let currentSubArcs: SubArc[] = [];
  const ticks: Tick[] = [];

  const addValue = (percent: number, tick: number, subArcs: SubArc[], ticks: Tick[]) => {
    if (percent < 100 && !subArcs.some((x) => x.limit === percent)) {
      subArcs.push({ limit: percent });
      subArcs = subArcs.sort((a, b) => (a.limit === b.limit ? 0 : (a.limit ?? 100) < (b.limit ?? 100) ? -1 : 1));

      const newIndex = subArcs.findIndex((x) => x.limit === percent);
      subArcs[newIndex].color = gaugeColors[newIndex];
      ticks.push({
        value: tick,
        valueConfig: {
          style: { fontSize: '10px', fill: '#E26025', textAnchor: 'start', fontWeight: 'bold' },
        },
      });
    }
  };
  if (data.target > 0) {
    for (let i = 1; i <= 5; i++) {
      const currentValue = (data.target / 5) * i;
      currentSubArcs.push(
        i === 5
          ? { color: gaugeColors[i - 1] }
          : {
              limit: data.target > 100 ? i * 20 : currentValue,
              color: gaugeColors[i - 1],
            }
      );
      ticks.push({ value: currentValue });
    }
  }

  const periodSubArcs: SubArc[] = [...currentSubArcs];
  addValue(data.percent, data.completement, currentSubArcs, ticks);

  const maxValue = data.target === 0 ? (data.completement === 0 ? 100 : data.completement) : data.target;
  const value = data.completement > maxValue ? maxValue : data.completement;

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Box
        sx={{
          maxWidth: {
            xs: '50vw',
            md: '20vw',
            '& svg': { overflow: 'visible' },
            '& .value-text>text': { fill: 'rgba(0, 0, 0, 0.37) !important' },
          },
          width: {
            xs: '50vw',
            md: '20vw',
          },
        }}
      >
        <GaugeComponent
          id="gauge-component24"
          type="semicircle"
          arc={{
            gradient: true,
            width: 0.15,
            padding: 0,
            subArcs: periodSubArcs,
          }}
          labels={{
            valueLabel: {
              matchColorWithArc: true,
              formatTextValue: () => `${data.percent}%`,
              style: { fill: '#E26025' },
            },

            tickLabels: {
              ticks: ticks,
              defaultTickValueConfig: {
                formatTextValue: (value: any) => formatNumber(value),
              },
            },
          }}
          minValue={0}
          maxValue={maxValue}
          value={value}
          pointer={{ type: 'arrow', color: '#E26025' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: {
            xs: '55vw',
            md: '20vw',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption">
            {targetText}: {formatNumber(data.target)} {unit} {targetInfo && <Info text={targetInfo} />}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: {
            xs: '55vw',
            md: '20vw',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption">
            {completementText}: {formatNumber(data.completement)} {unit}{' '}
            {completementInfo && <Info text={completementInfo} />}
          </Typography>
        </Box>
      </Box>
      <Typography variant="caption">
        {percentText}: {data.percent} %
      </Typography>
    </Box>
  );
};
