import { ReactElement } from 'react';
import { Button, Theme } from '@mui/material';
import { TaskStatus } from '../../../../models/myTarget/TaskStatus';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Task } from '../../../../models/myTarget/Task';

interface Props {
  data: Task;
  isAdmin: boolean;
  onClick: (task: Task) => void;
}

export const getStatus = (status: TaskStatus): { label: string; color: any; icon: ReactElement } => {
  switch (status) {
    case TaskStatus.open:
      return {
        label: 'Új',
        color: 'secondary',
        icon: <GradeOutlinedIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case TaskStatus.inProgress:
      return {
        label: 'Folyamatban',
        color: 'primary',
        icon: <LoopIcon sx={{ backgroundColor: 'transparent !important' }} />,
      };
    case TaskStatus.done:
      return { label: 'Elkészült', color: 'success', icon: <DoneIcon color="info" sx={{ fill: '#fff' }} /> };
    case TaskStatus.accepted:
      return { label: 'Elfogadott', color: 'info', icon: <DoneAllIcon color="success" sx={{ fill: '#fff' }} /> };
    case TaskStatus.closed:
      return { label: 'Lezárva', color: 'default', icon: <CloseIcon /> };
  }
};

export const TaskButton: React.FC<Props> = ({ data, isAdmin, onClick }: Props) => {
  let label: string | undefined;
  let icon: ReactElement | undefined;

  switch (data.status) {
    case TaskStatus.open: {
      if (!isAdmin) {
        label = 'Elkezdem';
        icon = (
          <LoopIcon
            sx={(theme: Theme) => ({ backgroundColor: 'transparent !important', fill: theme.palette.primary.main })}
          />
        );
      }
      break;
    }
    case TaskStatus.inProgress: {
      if (!isAdmin) {
        label = 'Befejezem';
        icon = <DoneIcon color="info" sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />;
      }
      break;
    }

    case TaskStatus.done: {
      if (isAdmin) {
        label = 'Elfogadom';
        icon = <DoneAllIcon color="info" sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />;
      }
      break;
    }

    case TaskStatus.accepted: {
      if (!isAdmin) {
        label = 'Lezárom';
        icon = <CloseIcon color="info" sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />;
      }
      break;
    }
  }

  return label ? (
    <>
      <Button startIcon={icon} color="primary" size="small" onClick={() => onClick(data)}>
        {label}
      </Button>
    </>
  ) : null;
};
