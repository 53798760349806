import { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Theme, Button, Box, Collapse, Divider } from '@mui/material';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { TargetPartner } from '../../../models/myTarget/TargetPartner';
import { Info } from '../../common/Info';
import { Partner } from '../../../models/myTarget/Partner';
import { useFormat } from '../../../utility/useFormat';
import { useNavigate } from 'react-router-dom';

import { EditTargetPartner } from './EditTargetPartner';
import { Access } from '../../../access/Access';
import { SaveTargetPartnerRequest } from '../../../models/myTarget/SaveTargetPartnerRequest';
import { SaveMyTargetResponse } from '../../../models/myTarget/SaveMyTargetResponse';
import { useDispatch, useSelector } from 'react-redux';
import { selectImportantPartners, setImportantPartners, setReloadVisits } from '../../../slices/myTargetSlice';
import { addMessage } from '../../../slices/applicationSlice';
import { SaveMyTargetRequest } from '../../../models/myTarget/SaveMyTargetRequest';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import { EditVisit } from '../../common/visit/EditVisit';
import { Visit } from '../../../models/visits/Visit';
import { ImportantPartners } from '../../../models/myTarget/ImportantPartners';
import { Loading } from '../../common/Loading';
import { MyTargetInfoRequest } from '../../../models/myTarget/MyTargetInfoRequest';
import { MyTargetInfoResponse } from '../../../models/myTarget/MyTargetInfoResponse';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface TargetPartnerValues {
  id: number;
  name: string;
  address: string;
  partnerCode?: string;
}

interface Props {
  agentId: number;
  isAdmin: boolean;
}
export const Partners: React.FC<Props> = ({ agentId, isAdmin }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTargetPartner, setSelectedTargetPartner] = useState<TargetPartner | undefined>(undefined);
  const [selectedVisit, setSelectedVisit] = useState<Visit | undefined>(undefined);
  const [isCollapsedNewTarget, setIsCollapsedNewTarget] = useState<boolean>(false);
  const [isCollapsedNewRegistration, setIsCollapsedNewRegistration] = useState<boolean>(false);
  const [isCollapsedNeedsReactivation, setIsCollapsedNeedsReactivation] = useState<boolean>(false);
  const [isCollapsedRegistredOnWeb, setIsCollapsedRegistredOnWeb] = useState<boolean>(false);
  const [isCollapsedResidual, setIsCollapsedResidual] = useState<boolean>(false);
  const [isCollapsedAttrition, setIsCollapsedAttrition] = useState<boolean>(false);
  const [isCollapsedUnderPerforming, setIsCollapsedUnderPerforming] = useState<boolean>(false);

  const importantPartners: ImportantPartners | null | undefined = useSelector(selectImportantPartners);
  const { formatDate, formatNumber, formatCurrency } = useFormat();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (importantPartners === undefined) {
      dispatch(setImportantPartners(null));
      setIsLoading(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getImportantPartners: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.importantPartners != null) {
            dispatch(setImportantPartners(value.agentInfo.importantPartners));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, importantPartners]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '40%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '40%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '20%',
    },
  ];

  const newRegistrationCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '30%',
    },
    {
      id: 'registrationDate',
      isNumeric: false,
      label: 'Regisztráció',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'lastPurchase',
      isNumeric: false,
      label: 'Utolsó vásárlás',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'operation',
      isNumeric: true,
      label: '',
      disablePadding: true,
      width: '20%',
    },
  ];

  const needsReactivationCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '30%',
    },
    {
      id: 'registrationDate',
      isNumeric: false,
      label: 'Regisztráció',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'lastPurchase',
      isNumeric: false,
      label: 'Utolsó vásárlás',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'operation',
      isNumeric: true,
      label: '',
      disablePadding: true,
      width: '20%',
    },
  ];

  const registeredOnWebCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '30%',
    },
    {
      id: 'registrationDate',
      isNumeric: false,
      label: 'Regisztráció',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'phone',
      isNumeric: false,
      label: 'Telefonszám',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'operation',
      isNumeric: true,
      label: '',
      disablePadding: true,
      width: '20%',
    },
  ];

  const residualCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '30%',
    },
    {
      id: 'registrationDate',
      isNumeric: false,
      label: 'Legrégebbi számla',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'amount',
      isNumeric: true,
      label: 'Tartozás',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'operation',
      isNumeric: true,
      label: '',
      disablePadding: true,
      width: '20%',
    },
  ];

  const attritionCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'registrationDate',
      isNumeric: false,
      label: 'Regisztráció',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'orderFrequency',
      isNumeric: false,
      label: (
        <>
          Vásárlási gyakorisá
          <Info
            text={`Hány alkalommal vásárol egy évben (alkalom).
           Zárójelben ennek változása a gördülő 12 hónaphoz képest (%)`}
          />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'cartSize',
      isNumeric: false,
      label: (
        <>
          Kosár érték
          <Info
            text={`Egy vásárlás átlagos értéke (Forint).
     Zárójelben ennek változása a gördülő 12 hónaphoz képest (%)`}
          />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'productWidth',
      isNumeric: false,
      label: (
        <>
          Szortiment szélesség
          <Info
            text={`Hány fajta terméket tart a Wolker teljes kínálatából.
            Első érték: Az összes termék hány százalékát tartja (%).
            Második érték: Hány fajta terméket tart (fajta db).
            Harmadik érték: A tartott fajta darabszám hogyan változott a gördülő 12 hónaphoz képest (%)`}
          />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'purchaseChangeValue',
      isNumeric: false,
      label: (
        <>
          Elmaradás
          <Info text={`Időarányosan mekkora árbevétel elmaradásban vagyunk a gördülő 12 hónaphoz képest (Ft)`} />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'lastPurchase',
      isNumeric: false,
      label: 'Utolsó vásárlás',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'operation',
      isNumeric: true,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const underPerformingCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'registrationDate',
      isNumeric: false,
      label: 'Regisztráció',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'orderFrequency',
      isNumeric: false,
      label: (
        <>
          Vásárlási gyakorisá
          <Info
            text={`Hány alkalommal vásárol egy évben (alkalom).
           Zárójelben ennek változása a gördülő 12 hónaphoz képest (%)`}
          />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'cartSize',
      isNumeric: false,
      label: (
        <>
          Kosár érték
          <Info
            text={`Egy vásárlás átlagos értéke (Forint).
     Zárójelben ennek változása a gördülő 12 hónaphoz képest (%)`}
          />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'productWidth',
      isNumeric: false,
      label: (
        <>
          Szortiment szélesség
          <Info
            text={`Hány fajta terméket tart a Wolker teljes kínálatából.
            Első érték: Az összes termék hány százalékát tartja (%).
            Második érték: Hány fajta terméket tart (fajta db).
            Harmadik érték: A tartott fajta darabszám hogyan változott a gördülő 12 hónaphoz képest (%)`}
          />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'purchaseChangeValue',
      isNumeric: false,
      label: (
        <>
          Elmaradás
          <Info text={`Időarányosan mekkora árbevétel elmaradásban vagyunk a gördülő 12 hónaphoz képest (Ft)`} />
        </>
      ),
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'lastPurchase',
      isNumeric: false,
      label: 'Utolsó vásárlás',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'operation',
      isNumeric: true,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const newTargetsData: TableRowData[] = useMemo(
    () =>
      importantPartners?.newTargets != null
        ? importantPartners.newTargets.map((partner: TargetPartner) => ({
            rowId: `new-target-${partner.id}`,
            id: partner.id,
            sx: partner.visitCount > 0 ? { backgroundColor: 'rgba(226, 96, 37, 0.1)' } : undefined,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: partner.name,
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },
              {
                id: 'operation',
                value: (
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      startIcon={<PersonAddIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                      color="primary"
                      size="small"
                      onClick={() => setSelectedTargetPartner(partner)}
                    >
                      Partnerré alakítom
                    </Button>
                    <Button
                      startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                      color="primary"
                      size="small"
                      onClick={() => handleVisitTargetPartner(partner)}
                    >
                      Meglátogatom
                    </Button>
                  </Box>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.newTargets]
  );

  const newRegistrationsData: TableRowData[] = useMemo(
    () =>
      importantPartners?.newRegistrations != null
        ? importantPartners.newRegistrations.map((partner: Partner) => ({
            rowId: `new-registration-${partner.id}`,
            id: partner.id,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: (
                  <Button
                    sx={{ textAlign: 'left', whiteSpace: 'normal' }}
                    onClick={() => openPartner(partner.id.toString())}
                  >
                    {partner.name}
                  </Button>
                ),
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },
              {
                id: 'registrationDate',
                cValue: partner.registrationDate,
                value: formatDate(partner.registrationDate),
              },
              {
                id: 'lastPurchase',
                cValue: partner.lastPurchase,
                value: partner.lastPurchase ? formatDate(partner.lastPurchase) : 'N/A',
              },
              {
                id: 'operation',
                value: (
                  <Button
                    startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                    color="primary"
                    size="small"
                    onClick={() => handleVisitTargetPartner(partner)}
                  >
                    Meglátogatom
                  </Button>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.newRegistrations]
  );

  const residualData: TableRowData[] = useMemo(
    () =>
      importantPartners?.residuals != null
        ? importantPartners.residuals.map((partner: Partner) => ({
            rowId: `residual-${partner.id}`,
            id: partner.id,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: (
                  <Button
                    sx={{ textAlign: 'left', whiteSpace: 'normal' }}
                    onClick={() => openPartner(partner.id.toString())}
                  >
                    {partner.name}
                  </Button>
                ),
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },
              {
                id: 'registrationDate',
                cValue: partner.registrationDate,
                value: formatDate(partner.registrationDate),
              },
              {
                id: 'amount',
                cValue: partner.residualAmount,
                value: partner.residualAmount ? formatCurrency(partner.residualAmount) : 'N/A',
              },
              {
                id: 'operation',
                value: (
                  <Button
                    startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                    color="primary"
                    size="small"
                    onClick={() => handleVisitTargetPartner(partner)}
                  >
                    Meglátogatom
                  </Button>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.newRegistrations]
  );

  const needsReactivationData: TableRowData[] = useMemo(
    () =>
      importantPartners?.needsReactivation != null
        ? importantPartners.needsReactivation.map((partner: Partner) => ({
            rowId: `needs-reactivation-${partner.id}`,
            id: partner.id,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: (
                  <Button
                    sx={{ textAlign: 'left', whiteSpace: 'normal' }}
                    onClick={() => openPartner(partner.id.toString())}
                  >
                    {partner.name}
                  </Button>
                ),
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },
              {
                id: 'registrationDate',
                cValue: partner.registrationDate,
                value: formatDate(partner.registrationDate),
              },
              {
                id: 'lastPurchase',
                cValue: partner.lastPurchase,
                value: partner.lastPurchase ? formatDate(partner.lastPurchase) : 'N/A',
              },
              {
                id: 'operation',
                value: (
                  <Button
                    startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                    color="primary"
                    size="small"
                    onClick={() => handleVisitTargetPartner(partner)}
                  >
                    Meglátogatom
                  </Button>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.needsReactivation]
  );

  const attritionData: TableRowData[] = useMemo(
    () =>
      importantPartners?.attrition != null
        ? importantPartners.attrition.map((partner: Partner) => ({
            rowId: `attrition-${partner.id}`,
            id: partner.id,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: (
                  <Button
                    sx={{ textAlign: 'left', whiteSpace: 'normal' }}
                    onClick={() => openPartner(partner.id.toString())}
                  >
                    {partner.name}
                  </Button>
                ),
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },
              {
                id: 'registrationDate',
                cValue: partner.registrationDate,
                value: formatDate(partner.registrationDate),
              },
              {
                id: 'orderFrequency',
                cValue: partner.orderFrequency,
                value: `${formatNumber(partner.orderFrequency)}  ( ${formatNumber(partner.frequencyChangePercent)}% )`,
              },
              {
                id: 'cartSize',
                cValue: partner.cartSize,
                value: `${formatNumber(partner.cartSize)} ( ${formatNumber(partner.cartChangePercent)}% )`,
              },
              {
                id: 'productWidth',
                cValue: partner.productWidth?.count ?? 0,
                value: `${formatNumber(partner.productWidth?.percent)}% - ${formatNumber(
                  partner.productWidth?.count
                )} | ( ${formatNumber(partner.productWidth?.changePercent)}% )`,
              },
              {
                id: 'purchaseChangeValue',
                cValue: partner.purchaseChangeValue,
                value: formatCurrency(partner.purchaseChangeValue ?? 0),
              },
              {
                id: 'lastPurchase',
                cValue: partner.lastPurchase,
                value: partner.lastPurchase ? formatDate(partner.lastPurchase) : 'N/A',
              },
              {
                id: 'operation',
                value: (
                  <Button
                    startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                    color="primary"
                    size="small"
                    onClick={() => handleVisitTargetPartner(partner)}
                  >
                    Meglátogatom
                  </Button>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.attrition]
  );

  const underPerformingData: TableRowData[] = useMemo(
    () =>
      importantPartners?.underPerforming != null
        ? importantPartners.underPerforming.map((partner: Partner) => ({
            rowId: `unederPerforming-${partner.id}`,
            id: partner.id,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: (
                  <Button
                    sx={{ textAlign: 'left', whiteSpace: 'normal' }}
                    onClick={() => openPartner(partner.id.toString())}
                  >
                    {partner.name}
                  </Button>
                ),
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },
              {
                id: 'registrationDate',
                cValue: partner.registrationDate,
                value: formatDate(partner.registrationDate),
              },
              {
                id: 'orderFrequency',
                cValue: partner.orderFrequency,
                value: `${formatNumber(partner.orderFrequency)}  ( ${formatNumber(partner.frequencyChangePercent)}% )`,
              },
              {
                id: 'cartSize',
                cValue: partner.cartSize,
                value: `${formatNumber(partner.cartSize)} ( ${formatNumber(partner.cartChangePercent)}% )`,
              },
              {
                id: 'productWidth',
                cValue: partner.productWidth?.count ?? 0,
                value: `${formatNumber(partner.productWidth?.percent)}% - ${formatNumber(
                  partner.productWidth?.count
                )} | ( ${formatNumber(partner.productWidth?.changePercent)}% )`,
              },
              {
                id: 'purchaseChangeValue',
                cValue: partner.purchaseChangeValue,
                value: formatCurrency(partner.purchaseChangeValue ?? 0),
              },
              {
                id: 'lastPurchase',
                cValue: partner.lastPurchase,
                value: partner.lastPurchase ? formatDate(partner.lastPurchase) : 'N/A',
              },
              {
                id: 'operation',
                value: (
                  <Button
                    startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                    color="primary"
                    size="small"
                    onClick={() => handleVisitTargetPartner(partner)}
                  >
                    Meglátogatom
                  </Button>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.underPerforming]
  );

  const registeredOnWebData: TableRowData[] = useMemo(
    () =>
      importantPartners?.newWebRegistrations != null
        ? importantPartners.newWebRegistrations.map((partner: Partner) => ({
            rowId: `new-registration-${partner.id}`,
            id: partner.id,
            sx: partner.visitCount > 0 ? { backgroundColor: 'rgba(226, 96, 37, 0.1)' } : undefined,
            columns: [
              {
                id: 'name',
                cValue: partner.name,
                value: (
                  <Typography component="div" variant="body2" sx={{ pl: 1 }}>
                    {partner.name}
                  </Typography>
                ),
              },
              {
                id: 'address',
                cValue: partner.address,
                value: partner.address,
              },

              {
                id: 'registrationDate',
                cValue: partner.registrationDate,
                value: formatDate(partner.registrationDate),
              },
              {
                id: 'phone',
                cValue: partner.phone,
                value: partner.phone,
              },
              {
                id: 'operation',
                value: (
                  <Button
                    startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: theme.palette.primary.main })} />}
                    color="primary"
                    size="small"
                    onClick={() => handleVisitTargetPartner(partner)}
                  >
                    Meglátogatom
                  </Button>
                ),
              },
            ],
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importantPartners?.newRegistrations]
  );

  const openPartner = (id: number | string): void => {
    const queryString = isAdmin && agentId ? `?agent=${agentId}` : '';
    navigate(`/partners/${id}${queryString}`);
  };

  const createNewTarget = (): void => {
    setSelectedTargetPartner({
      id: 0,
      name: '',
      address: '',
      visitCount: 0
    });
  };

  const saveTargetPartner = (values: TargetPartnerValues) => {
    const access = new Access();

    const saveTargetPartner: SaveTargetPartnerRequest =
      values.id === 0
        ? {
            id: values.id,
            address: values.address,
            name: values.name,
            agentId,
          }
        : { id: values.id, partnerCode: values.partnerCode, agentId };

    const request: SaveMyTargetRequest = {
      agentId,
      saveTargetPartner,
    };
    access
      .saveMyTarget(request)
      .then((value: SaveMyTargetResponse | undefined) => {
        if (value?.error == null && value?.agentInfo?.importantPartners != null) {
          dispatch(setImportantPartners(value.agentInfo.importantPartners));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-myTarget' }));
          dispatch(setReloadVisits({ reload: true, scroll: false }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setSelectedTargetPartner(undefined);
      });
  };

  const handleVisitTargetPartner = (targetPartner: TargetPartner): void => {
    setSelectedVisit({
      id: 0,
      date: new Date(),
      from: '09:00',
      to: '10:00',
      notes: '',
      partner: {
        id: targetPartner.id,
        name: targetPartner.name,
        agentId,
        code: '',
      },
    });
  };

  const handleVisitEdited = (): void => {
    setSelectedVisit(undefined);
    dispatch(setReloadVisits({ reload: true, scroll: true }));
  };

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedNewTarget(!isCollapsedNewTarget);
              }}
            >
              <strong>
                Új célpontok (2x pont érték)
                <Info text="Olyan boltok, akik jelenleg nem ügyfeleink, de beazonosításra kerültek, mint lehetséges ügyfelek" />
              </strong>
              {isCollapsedNewTarget ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedNewTarget} unmountOnExit>
              <Button
                variant="contained"
                sx={{ my: 2 }}
                color="primary"
                startIcon={<AddIcon sx={{ fill: '#fff' }} />}
                onClick={createNewTarget}
              >
                Új célpont hozzáadása
              </Button>
              {newTargetsData.length > 0 && (
                <Table
                  id="newTargets"
                  headCells={headCells}
                  rows={newTargetsData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ mt: 2, mb: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedNewRegistration(!isCollapsedNewRegistration);
              }}
            >
              <strong>
                Frissen regisztrált boltok (2x pont érték)
                <Info
                  text={`Olyan boltok, akik a weben vagy más módon regisztráltak.
              Érdemes rájuk odafigyelni, hogy aktív ügyféllé váljanak, ne morzsolódjanak le.`}
                />
              </strong>
              {isCollapsedNewRegistration ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedNewRegistration} unmountOnExit>
              {newRegistrationsData.length > 0 && (
                <Table
                  id="newRegistrations"
                  headCells={newRegistrationCells}
                  rows={newRegistrationsData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ mt: 2, mb: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedNeedsReactivation(!isCollapsedNeedsReactivation);
              }}
            >
              <strong>
                Újra aktiválandó boltok (1.5x pont érték)
                <Info text="Olyan boltok, akik az elmúlt 6 hónapban nem várásoltak, de korábban vásároltak." />
              </strong>
              {isCollapsedNeedsReactivation ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedNeedsReactivation} unmountOnExit>
              {needsReactivationCells.length > 0 && (
                <Table
                  id="needsReactivation"
                  headCells={needsReactivationCells}
                  rows={needsReactivationData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ mt: 2, mb: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedRegistredOnWeb(!isCollapsedRegistredOnWeb);
              }}
            >
              <strong>
                Weben regisztrált, de nem vásárolt boltok
                <Info
                  text={`Olyan boltok, akik a wolker.hu-n beregisztráltak, de még nem vásároltak:
              Ők jelezték, hogy érdeklődnek a Wolker iránt.
              Látogasd meg és fordítsd át aktív ügyféllé!`}
                />
              </strong>
              {isCollapsedRegistredOnWeb ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedRegistredOnWeb} unmountOnExit>
              {registeredOnWebCells.length > 0 && (
                <Table
                  id="registeredOnWeb"
                  headCells={registeredOnWebCells}
                  rows={registeredOnWebData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ mt: 2, mb: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedAttrition(!isCollapsedAttrition);
              }}
            >
              <strong>
                Lemorzsolódás veszélyes boltok
                <Info
                  text={`Olyan boltok, akiknek az aktivitás (vásárlási gyakorisága, kosár mérete)
                jelentősen csökkenő tendenciát mutat a korábbi időszakhoz képest.
                Ez annak az előjele, hogy ha a tendencia folytatódik, el fogjuk veszíteni ezt a partnert.
                Fordíts rá kiemelt figyelmet, értsd meg mi lehet a csökkenő aktivitás oka,
                hogy vissza tudjuk hozni az aktív partnerek közé.`}
                />
              </strong>
              {isCollapsedAttrition ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedAttrition} unmountOnExit>
              {attritionCells.length > 0 && (
                <Table
                  id="attrition"
                  headCells={attritionCells}
                  rows={attritionData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ mt: 2, mb: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedUnderPerforming(!isCollapsedUnderPerforming);
              }}
            >
              <strong>
                Alulteljesítő boltok
                <Info
                  text={`Azok a boltok, amelyek a legnagyobb elmaradást
                mutatják az értékesítésben, abszolút értékben.
                Ezekre figyelj oda, mert a nagy elmaradás jelentősen
                hátrányosan érinti a te személyes pont célodat is. `}
                />
              </strong>
              {isCollapsedUnderPerforming ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedUnderPerforming} unmountOnExit>
              {underPerformingCells.length > 0 && (
                <Table
                  id="underPerforming"
                  headCells={underPerformingCells}
                  rows={underPerformingData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ mt: 2, mb: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsCollapsedResidual(!isCollapsedResidual);
              }}
            >
              <strong>Tartozással rendelkező boltok</strong>
              {isCollapsedResidual ? (
                <KeyboardArrowUpIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={(theme: Theme) => ({
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                  })}
                />
              )}
            </Typography>
            <Collapse in={!isCollapsedResidual} unmountOnExit>
              {residualCells.length > 0 && (
                <Table
                  id="residual"
                  headCells={residualCells}
                  rows={residualData}
                  hideOthersOnSelect={false}
                  selected={undefined}
                  setSelected={() => {}}
                  hidePaper
                  defaultPagination={{ page: 0, rowsPerPage: 10 }}
                  filterByColumnId={['name', 'address']}
                />
              )}
            </Collapse>
            <Divider style={{ background: 'orange', height: '3px' }} sx={{ my: 2 }} />
          </Grid>
        </>
      )}

      {selectedTargetPartner && (
        <EditTargetPartner
          data={selectedTargetPartner}
          onClose={() => {
            setSelectedTargetPartner(undefined);
          }}
          onSave={saveTargetPartner}
        />
      )}
      {selectedVisit && (
        <EditVisit
          visit={selectedVisit}
          onCancel={() => {
            setSelectedVisit(undefined);
          }}
          onEdited={handleVisitEdited}
          isAdmin={isAdmin}
        />
      )}
    </Grid>
  );
};
