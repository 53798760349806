import { Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FileDetails } from './FileDetails';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export const QueueListingItem = ({ file }: { file: FileDetails }) => {
  let action;
  const formatFileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };
  return (
    <ListItem>
      <ListItemIcon>
        <InsertDriveFileOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        key={file.name}
        primary={
          <Grid container direction="row" justifyContent="space-between" alignItems="center" component="span">
            <Grid item component="span">
              {file.name}
            </Grid>
            <Grid item component="span">
              <Typography variant="caption">{formatFileSize(file.size)}</Typography>
            </Grid>
          </Grid>
        }
      ></ListItemText>
      {action}
    </ListItem>
  );
};
