import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { AgentSummary } from '../models/common/AgentSummary';
import { MyTargetState } from '../models/slices/myTargetState';
import { LoadMyTargetResponse } from '../models/myTarget/LoadMyTargetResponse';
import { Statistics } from '../models/myTarget/Statistics';
import { PerformanceReference } from '../models/common/PerformanceReference';
import { PartnerDistribution } from '../models/myTarget/PartnerDistribution';
import { Purchases } from '../models/common/Purchases';
import { ProductWidth } from '../models/common/ProductWidth';
import { ImportantPartners } from '../models/myTarget/ImportantPartners';
import { Task } from '../models/myTarget/Task';

const initialState: MyTargetState = {
  statistics: undefined,
  performanceReference: undefined,
  partnerDistribution: undefined,
  purchases: undefined,
  productWidth: undefined,
  importantPartners: undefined,
  tasks: undefined,

  agents: undefined,
  reloadVisits: false,
  scrollToVisit: false,
};

const myTargetSlice = createSlice({
  name: 'myTarget',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadMyTargetResponse | undefined>) => {
      state.agents = action.payload?.agents;
    },
    setReloadVisits: (state, action: PayloadAction<{ reload: boolean; scroll: boolean }>) => {
      state.reloadVisits = action.payload.reload;
      state.scrollToVisit = action.payload.scroll;
    },
    setAgents: (state, action: PayloadAction<AgentSummary[] | undefined | null>) => {
      state.agents = action.payload;
    },
    resetAgentInfo: (state) => {
      state.statistics = undefined;
      state.importantPartners = undefined;
      state.partnerDistribution = undefined;
      state.performanceReference = undefined;
      state.productWidth = undefined;
      state.purchases = undefined;
      state.tasks = undefined;
      state.reloadVisits = true;
    },

    setStatistics: (state, action: PayloadAction<Statistics | undefined | null>) => {
      state.statistics = action.payload;
    },
    setPerformanceReference: (state, action: PayloadAction<PerformanceReference[] | undefined | null>) => {
      state.performanceReference = action.payload;
    },
    setPartnerDistribution: (state, action: PayloadAction<PartnerDistribution | undefined | null>) => {
      state.partnerDistribution = action.payload;
    },
    setPurchases: (state, action: PayloadAction<Purchases | undefined | null>) => {
      state.purchases = action.payload;
    },
    setProductWidth: (state, action: PayloadAction<ProductWidth | undefined | null>) => {
      state.productWidth = action.payload;
    },
    setImportantPartners: (state, action: PayloadAction<ImportantPartners | undefined | null>) => {
      state.importantPartners = action.payload;
    },
    setTasks: (state, action: PayloadAction<Task[] | undefined | null>) => {
      state.tasks = action.payload;
    },
  },
});

export const {
  setResponse,
  setReloadVisits,
  setAgents,
  resetAgentInfo,
  setStatistics,
  setPerformanceReference,
  setPartnerDistribution,
  setPurchases,
  setProductWidth,
  setImportantPartners,
  setTasks,
} = myTargetSlice.actions;

export const selectAgents = (state: RootState): AgentSummary[] | null | undefined => state.localData.myTarget.agents;
export const selectReloadVisits = (state: RootState): { reload: boolean; scroll: boolean } => ({
  reload: state.localData.myTarget.reloadVisits,
  scroll: state.localData.myTarget.scrollToVisit,
});

export const selectStatistics = (state: RootState): Statistics | null | undefined =>
  state.localData.myTarget.statistics;
export const selectPerformanceReference = (state: RootState): PerformanceReference[] | null | undefined =>
  state.localData.myTarget.performanceReference;
export const selectPartnerDistribution = (state: RootState): PartnerDistribution | null | undefined =>
  state.localData.myTarget.partnerDistribution;
export const selectPurchases = (state: RootState): Purchases | null | undefined => state.localData.myTarget.purchases;
export const selectProductWidth = (state: RootState): ProductWidth | null | undefined =>
  state.localData.myTarget.productWidth;
export const selectImportantPartners = (state: RootState): ImportantPartners | null | undefined =>
  state.localData.myTarget.importantPartners;
export const selectTasks = (state: RootState): Task[] | null | undefined => state.localData.myTarget.tasks;

export default myTargetSlice.reducer;
