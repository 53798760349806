import React from 'react';
import { ErrorMessage } from './ErrorMessage';

interface ErrorBoundaryProps {
  hasError: boolean;
  error: string;
}

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryProps> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error: any, info: any) {
    // We add here a Sentry trigger, or smth similar
    console.info(error);
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorMessage title="Hiba történt!" />;
    }
    return (this.props as any).children;
  }
}
