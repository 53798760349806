import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, List } from '@mui/material';

import { FileDetails } from './FileDetails';
import { QueueListingItem } from './QueueListingItem';

export enum UploadStatus {
  new,
  uploading,
  completed,
  error,
}

export const QueueListing = ({
  files,
  onHide,
  status,
}: {
  files: FileDetails[];
  onHide: () => void;
  status: UploadStatus;
}) => {
  //const filesCount = files.length;
  //const globalProgressColor: 'primary' | 'error' | 'success' = 'primary';

  // let summaryTitle = '';
  let disableCloseButton = true;
  // let progress: JSX.Element | null = <LinearProgress variant="determinate" value={0} />;
  switch (status) {
    case UploadStatus.new:
      //     summaryTitle = filesCount === 1 ? files[0].name : `Uploading ${filesCount} files...`;
      //     progress = null;
      disableCloseButton = false;
      break;
    case UploadStatus.uploading:
      //     summaryTitle = filesCount === 1 ? 'Uploading the file...' : `Uploading ${filesCount} files...`;
      //     progress = <LinearProgress variant="indeterminate" color={globalProgressColor} />;
      //     break;
      //   case UploadStatus.completed:
      //     summaryTitle =
      //       filesCount === 0
      //         ? ''
      //         : filesCount === 1
      //         ? 'File uploaded successfully'
      //         : `All ${filesCount} files completed successfully`;
      disableCloseButton = false;
      //     progress = <LinearProgress variant="determinate" value={100} color="success" />;
      break;
    case UploadStatus.error:
      //     summaryTitle = 'Upload failed';
      //     if (!detailsExpended) setDetailsExpended(true);
      disableCloseButton = false;
      //     progress = <LinearProgress variant="determinate" value={100} color="error" />;
      break;
  }

  return (
    <List dense sx={{ pt: 0 }}>
      {files.map((f, idx) => (
        <Box key={`c-${f.name}`} sx={{ display: 'flex' }}>
          <QueueListingItem file={f} />
          <IconButton onClick={() => onHide()} disabled={disableCloseButton}>
            <CloseIcon fontSize="small" sx={{ color: '#777' }} />
          </IconButton>
        </Box>
      ))}
    </List>
  );
};
