import axios, { AxiosInstance } from "axios";
import { securityClient } from "../security/securityClient";

export async function axiosClientFactory(
  baseURL: string,
  skipToken?: boolean
): Promise<AxiosInstance> {
  let token;

  try {
    token = securityClient.getToken();
  } catch (e) {
    console.log(e);
  }

  const headers = { "Content-Type": "application/json" } as any;

  if (token !== undefined && !skipToken)
    headers.Authorization = `Bearer ${token}`;

  const client = axios.create({
    baseURL,
    timeout: 900000,
    headers,
  });

  client.interceptors.response.use(
    function onSuccess(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      if (response?.data?.token) securityClient.setToken(response.data.token);
      return response;
    },
    function onError(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger

      if (error.response && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.error("Unauthorized");
        securityClient.signOut();
      }
      return Promise.reject(error);
    }
  );

  return client;
}
