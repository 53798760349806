import { useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Theme, Typography } from '@mui/material';
//import { Chip, Popover } from '@mui/material';
import { Partner } from '../../../models/partners/Partner';
//import { Increase } from '../../../assets/Increase';
//import { Decrease } from '../../../assets/Decrease';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InfoBox } from '../../common/InfoBox';
//import { useFormat } from '../../../utility/useFormat';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Purchases } from '../../common/statistics/Purchases';
import { ProductWidth } from '../../common/statistics/ProductWidth';
import { Gauge } from '../../common/Gauge';
import { Loading } from '../../common/Loading';
import { useDispatch } from 'react-redux';
import { Access } from '../../../access/Access';
import { PartnerInfoRequest } from '../../../models/partners/PartnerInfoRequest';
import { PartnerInfoResponse } from '../../../models/partners/PartnerInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { setProductWidth } from '../../../slices/partnersSlice';
import { Module } from '../../../models/Module';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  partner: Partner;
}

// const Completement = (props: { completement: number }) => {
//   const { completement } = props;
//   const { formatNumber } = useFormat();
//   const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
//   const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'info-popover' : undefined;

//   const label = (
//     <>
//       {completement >= 0 ? <Increase style={{ marginRight: 4 }} /> : <Decrease style={{ marginRight: 4 }} />}
//       {formatNumber(completement)}
//     </>
//   );
//   return (
//     <>
//       <Chip
//         size="small"
//         label={label}
//         color={completement >= 0 ? 'success' : 'error'}
//         aria-describedby={id}
//         onClick={handleClick}
//         onMouseEnter={handleClick}
//         onMouseLeave={handleClose}
//         aria-haspopup="true"
//         aria-owns={open ? id : undefined}
//       />
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//         disableRestoreFocus
//         sx={{
//           pointerEvents: 'none',
//         }}
//       >
//         <Typography sx={{ p: 2 }} whiteSpace="pre-line">
//           {completement >= 0 ? 'Túlteljesítés' : 'Alulteljesítés'}
//         </Typography>
//       </Popover>
//     </>
//   );
// };

export const Statistics: React.FC<Props> = ({ partner }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { statistics, id: partnerId } = partner;
  const { highlights, purchases, productWidth } = statistics;

  useEffect(() => {
    if (productWidth === null) {
      dispatch(setProductWidth(undefined));
      setIsLoading(true);
      const access = new Access();
      const criteria: PartnerInfoRequest = {
        partnerId,
        getProductWidth: {},
      };
      access
        .partnerInfo(criteria)
        .then((value: PartnerInfoResponse | undefined) => {
          if (value?.error == null && value?.partner?.statistics?.productWidth != null) {
            dispatch(setProductWidth(value?.partner?.statistics?.productWidth));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId, productWidth]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const periodicSaleData = useMemo(() => {
    const labels = highlights.periodicSaleData.previousYearSale.map((_, index: number) => index + 1);
    const data = {
      labels,
      datasets: [
        {
          label: 'Előző évi értékesítés (Ft)',
          data: highlights.periodicSaleData.previousYearSale,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Bónusz megállapodás (Ft)',
          data: highlights.periodicSaleData.engagement,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
        },
        {
          label: 'Adott évi értékesítés (Ft)',
          data: highlights.periodicSaleData.currentYearSale,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49, 0.5)',
        },
      ],
    };
    const labelsTarget = highlights.periodicSaleData.target.map((_, index: number) => index + 1);
    const dataTarget = {
      labels: labelsTarget,
      datasets: [
        {
          label: 'Pont cél (pont)',
          data: highlights.periodicSaleData.target,
          borderColor: 'rgb(73, 173, 245)',
          backgroundColor: 'rgba(73, 173, 245, 0.5)',
        },
        {
          label: 'Szerzett pontok (pont)',
          data: highlights.periodicSaleData.accomplishedTarget,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
        },
      ],
    };
    return { data, dataTarget };
  }, [highlights.periodicSaleData]);

  const cumulativeSaleData = useMemo(() => {
    const dataLabels: number[] = [];
    const previousYearSale: number[] = [];
    const engagement: number[] = [];
    const currentYearSale: number[] = [];

    highlights.cumulativeSaleData.engagement.forEach((e: number, index: number) => {
      dataLabels.push(index + 1);
      previousYearSale.push(highlights.cumulativeSaleData.previousYearSale[index]);
      engagement.push(e);
      currentYearSale.push(highlights.cumulativeSaleData.currentYearSale[index]);
    });

    const data = {
      labels: dataLabels,
      datasets: [
        {
          label: 'Előző évi értékesítés (Ft)',
          data: previousYearSale,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Bónusz megállapodás (Ft)',
          data: engagement,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
        },
        {
          label: 'Adott évi értékesítés (Ft)',
          data: currentYearSale,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49, 0.5)',
        },
      ],
    };

    const targetLabels: number[] = [];
    const target: number[] = [];
    const accomplishedTarget: number[] = [];

    highlights.cumulativeSaleData.target.forEach((t: number, index: number) => {
      targetLabels.push(index + 1);
      target.push(t);
      accomplishedTarget.push(highlights.cumulativeSaleData.accomplishedTarget[index]);
    });

    const dataTarget = {
      labels: targetLabels,
      datasets: [
        {
          label: 'Pont cél (pont)',
          data: target,
          borderColor: 'rgb(73, 173, 245)',
          backgroundColor: 'rgba(73, 173, 245, 0.5)',
        },
        {
          label: 'Szerzett pontok (pont)',
          data: accomplishedTarget,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
        },
      ],
    };
    return { data, dataTarget };
  }, [highlights.cumulativeSaleData]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Forgalmi statisztikák
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>

      <Collapse in={!isCollapsed}>
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Kiemelt mutatók
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <InfoBox
                title="Pont célod időarányos teljesülése"
                info="Pont célod teljesülése figyelmbe véve az évből eltelt időt és a szezonalitást is"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: 265,
                  }}
                >
                  {highlights.targetFulfillment != null && highlights.targetFulfillment?.target !== 0 ? (
                    <Gauge
                      data={highlights.targetFulfillment}
                      title=""
                      targetText="Pont célod"
                      completementText="Gyűjtött pontjaid"
                      percentText="Teljesülés"
                      unit="Pont"
                    />
                  ) : (
                    <Typography variant="h5">N/A</Typography>
                  )}
                </Box>
              </InfoBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <InfoBox
                title="Bolti bónusz megállapodás időarányos teljesítése"
                info="A bolt bónusz megállapodásának teljesülése figyelmbe véve az évből eltelt időt és a szezonalitást is"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: 265,
                  }}
                >
                  {highlights.engagementFulfillment != null && highlights.engagementFulfillment?.target !== 0 ? (
                    <Gauge
                      data={highlights.engagementFulfillment}
                      title=""
                      targetText="Célod"
                      completementText="Gyűjtött"
                      percentText="Teljesülés"
                      unit="Ft"
                    />
                  ) : (
                    <Typography variant="h5">N/A</Typography>
                  )}
                </Box>
              </InfoBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <InfoBox
                title="Előző évi értékesítés időarányos teljesülése"
                info="Az előző évi értékesítéshez (nettó árbevétel) képest hogyan alakul a forgalom jelen pillanatig"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: 265,
                  }}
                >
                  {highlights.previousYearSalesFulfillment != null &&
                  highlights.previousYearSalesFulfillment?.target !== 0 ? (
                    <Gauge
                      data={highlights.previousYearSalesFulfillment}
                      title=""
                      targetText="Célod"
                      completementText="Gyűjtött"
                      percentText="Teljesülés"
                      unit="Ft"
                    />
                  ) : (
                    <Typography variant="h5">N/A</Typography>
                  )}
                </Box>
              </InfoBox>
            </Grid>

            <Grid item xs={12} md={8} lg={6}>
              <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 500 }}>
                <Line
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Kumulativ értékesítési adatok (Pont)',
                      },
                    },
                  }}
                  data={cumulativeSaleData.dataTarget}
                  updateMode="resize"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 500 }}>
                <Line
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Kumulativ értékesítési adatok (Árbevétel – Ft) ',
                      },
                    },
                  }}
                  data={cumulativeSaleData.data}
                  updateMode="resize"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
                <Line
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Időszaki értékesítési adatok (Pont)',
                      },
                    },
                  }}
                  data={periodicSaleData.dataTarget}
                  updateMode="resize"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
                <Line
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Időszaki értékesítési adatok (Árbevétel – Ft) ',
                      },
                    },
                  }}
                  data={periodicSaleData.data}
                  updateMode="resize"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Purchases data={purchases} module={Module.partners} />
        {isLoading == null ? (
          <Loading />
        ) : (
          <>{productWidth != null && <ProductWidth data={productWidth} module={Module.partners} />}</>
        )}
      </Collapse>
    </Box>
  );
};
