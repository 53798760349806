import { Box, Divider, Typography } from '@mui/material';
import { Partner } from '../../../models/partners/Partner';
import { Summary } from './Summary';
import { Products } from './Products';
// import { Dev } from '../../common/Dev';
import { Statistics } from './Statistics';
import { Visits } from '../../common/visit/Visits';
import { PartnerStatus } from '../../../models/partners/PartnerStatus';
import { Info } from '../../common/Info';
import { useDispatch, useSelector } from 'react-redux';
import { setReloadVisits } from '../../../slices/partnersSlice';
import { selectReloadVisits } from '../../../slices/partnersSlice';
import { Invoices } from './Invoices';

interface Props {
  partner: Partner;
  isAdmin: boolean;
  agentId: number | undefined;
}

const now = new Date();
const before = new Date();
before.setMonth(before.getMonth() - 3);

export const PartnerInfo: React.FC<Props> = ({ partner, isAdmin, agentId }: Props) => {
  const dispatch = useDispatch();
  const reloadVisits = useSelector(selectReloadVisits);

  const getPartnerStatus = (status: PartnerStatus): string => {
    switch (status) {
      case PartnerStatus.Active:
        return 'Aktív ügyfél';
      case PartnerStatus.ShouldBeReActivated:
        return 'Újra aktiválandó ügyfél';
      case PartnerStatus.NewRegistration:
        return 'Új ügyfél';
      case PartnerStatus.RegisteredButNoOrder:
        return 'Lemorzsolódás veszélyes ügyfél';
      case PartnerStatus.NewTarget:
        return 'Új célpont';
      case PartnerStatus.UnderPerforming:
        return 'Alulteljesítő ügyfél';
      case PartnerStatus.WebRegistered:
        return 'Weben regisztrált ügyfél';
      case PartnerStatus.ReActivated:
        return 'Újra aktivált ügyfél';
    }
  };

  const getPartnerStatusInfo = (status: PartnerStatus): string => {
    switch (status) {
      case PartnerStatus.Active:
        return 'Prioritásának megfelelően kezeld';
      case PartnerStatus.ShouldBeReActivated:
        return '6 hónapon belül nem volt vásárlás, de korábban volt - Aktívan keresd - 1.5x pont érték';
      case PartnerStatus.NewRegistration:
        return `Olyan boltok, akik a weben vagy más módon regisztráltak.
        Érdemes rájuk odafigyelni, hogy aktív ügyféllé váljanak, ne morzsolódjanak le.`;
      case PartnerStatus.RegisteredButNoOrder:
        return `Olyan boltok, akiknek az aktivitás (vásárlási gyakorisága, kosár mérete)
        jelentősen csökkenő tendenciát mutat a korábbi időszakhoz képest.
        Ez annak az előjele, hogy ha a tendencia folytatódik, el fogjuk veszíteni ezt a partnert.
        Fordíts rá kiemelt figyelmet, értsd meg mi lehet a csökkenő aktivitás oka,
        hogy vissza tudjuk hozni az aktív partnerek közé.`;
      case PartnerStatus.NewTarget:
        return 'Új célpont';
      case PartnerStatus.UnderPerforming:
        return `Olyan boltok, akiknél a vásárlási érték
        jelentősen csökkenő tendenciát mutat a korábbi időszakhoz képest.`;
      case PartnerStatus.WebRegistered:
        return `Olyan boltok, akik a weben regisztráltak, de még nem vásároltak.`;
      case PartnerStatus.ReActivated:
        return 'Újra aktivált ügyfél - 1.5x pont érték';
    }
  };

  const handleSetReloadVisits = (value: boolean): void => {
    dispatch(setReloadVisits({ reload: value, scroll: value }));
  };

  return (
    <Box pt={2}>
      <Box sx={{ display: 'flex', mb: 2, alignItems: 'flex-start' }}>
        <Typography variant="h6" sx={{ mr: 2 }}>
          Státusz: {getPartnerStatus(partner.generalSaleData.partnerStatus)}
        </Typography>
        <Info text={getPartnerStatusInfo(partner.generalSaleData.partnerStatus)} />
      </Box>
      <Summary partner={partner} />
      <Divider />
      <Statistics partner={partner} />
      <Divider />
      <Products partner={partner} />
      <Divider />
      <Invoices partner={partner} />
      <Box sx={{ mb: 4 }}>
        <Visits
          partnerId={partner.id}
          isAdmin={isAdmin}
          agentId={agentId}
          id="partnerVisits"
          reloadVisits={reloadVisits}
          setReloadVisits={handleSetReloadVisits}
          from={before}
          to={now}
        />
      </Box>
    </Box>
  );
};
