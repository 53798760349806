import React from 'react';
import { Box, LinearProgress } from '@mui/material';

export const Loading: React.FC = () => {
  return (
    <Box>
      <LinearProgress />
    </Box>
  );
};
