import { Box, Button, LinearProgress, SwipeableDrawer, Typography } from '@mui/material';

import { Form, Formik } from 'formik';
import { FormTextField } from '../../common/input/FormTextField';
import * as Yup from 'yup';
import { TargetPartner } from '../../../models/myTarget/TargetPartner';
import { TargetPartnerValues } from './Partners';

interface Props {
  data: TargetPartner;
  onClose: () => void;
  onSave: (values: TargetPartnerValues) => void;
}

export const EditTargetPartner: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  const initialValues: TargetPartnerValues = {
    id: data.id,
    name: data.name ?? '',
    address: data.address ?? '',
    partnerCode: undefined,
  };

  const handleSubmit = (value: TargetPartnerValues): void => onSave(value);
  const validationSchema = Yup.object({
    name: Yup.string().required('Kérem adja meg a nevet'),
    address: Yup.string().required('Kérem adja meg a címet'),
    partnerCode: Yup.string().when('id', (values, schema) => {
      const [id] = values;
      if (id !== 0) return schema.required('Kérem adja meg a partner kódot');
      return schema;
    }),
  });

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{data.id === 0 ? 'Új célpont hozzáadása' : 'Partnerré alakítás'}</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <>
                {isSubmitting && (
                  <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Form>
                  {data.id === 0 ? (
                    <>
                      <Box pb={2}>
                        <FormTextField size="small" label="Név" name="name" id="name" fullWidth />
                      </Box>
                      <Box pb={2}>
                        <FormTextField
                          size="small"
                          label="Cím"
                          name="address"
                          id="address"
                          fullWidth
                          multiline
                          maxRows={15}
                        />
                      </Box>
                    </>
                  ) : (
                    <Box pb={2}>
                      <FormTextField size="small" label="Partner kód" name="partnerCode" id="partnerCode" fullWidth />
                    </Box>
                  )}
                  <Box sx={{ pt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                      Mégse
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Mentés
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
