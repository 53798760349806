import React, { useState } from 'react';
import { useField } from 'formik';
import { DateRangePicker, DateRangeProps as DateRangeProperties } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { Box, InputLabel, Popover } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';

export interface FormDateRangeProperties extends Omit<DateRangeProperties, 'name' | 'value' | 'error' | 'onChange'> {
  nameFrom: string;
  nameTo: string;
  onChange?: DateRangeProperties['onChange'];
  onClose?: () => void;
  minDate?: DateRangeProperties['minDate'];
  popOver?: boolean;
  label?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useFieldToDateRangePicker<Val = unknown>({
  nameFrom,
  nameTo,
  ...props
}: FormDateRangeProperties): DateRangeProperties {
  const fromFieldProps = useField(nameFrom);
  const [fromField, , fromHelpers] = fromFieldProps;
  const toFieldProps = useField(nameTo);
  const [toField, , toHelpers] = toFieldProps;

  return {
    ...props,
    onChange(date: any) {
      fromHelpers.setValue(date.selection.startDate);
      toHelpers.setValue(date.selection.endDate);
      if (props.onChange) props.onChange(date.selection);
    },
    className: 'date-range-picker',
    editableDateInputs: true,
    moveRangeOnFirstSelection: true,
    ranges: [
      {
        startDate: fromField.value,
        endDate: toField.value,
        key: 'selection',
      },
    ],
  };
}

export function FormDateRangePicker({ ...props }: FormDateRangeProperties) {
  const pickerProps = useFieldToDateRangePicker(props);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (props.onClose) props.onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? `date-picker-${props.nameFrom}-${props.nameTo}` : undefined;

  const [firstRange] = (pickerProps.ranges as {
    startDate: any;
    endDate: any;
  }[]) ?? [{ startDate: '' }];

  return props.popOver ? (
    <>
      {props.label && <InputLabel onClick={handleClick}>{props.label}</InputLabel>}
      <Box aria-describedby={id} onClick={handleClick} display="flex" alignItems="end" style={{ cursor: 'pointer' }}>
        <DateRangeIcon style={{ marginRight: '8px' }} />
        {`${firstRange.startDate ? moment(firstRange.startDate).format('YYYY-MM-DD') : 'Earlier'} - ${
          firstRange.endDate ? moment(firstRange.endDate).format('YYYY-MM-DD') : 'Continuos'
        }`}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangePicker {...pickerProps} />
      </Popover>
    </>
  ) : (
    <DateRangePicker {...pickerProps} />
  );
}
