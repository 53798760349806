import React, { useState } from 'react';
import { Box, Collapse, Grid, SxProps, Typography } from '@mui/material';
import { HeatMap as HeatMapModel } from '../../../models/common/HeatMap';
import { HeatMapCategory } from '../../../models/common/HeatMapCategory';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import { useFormat } from '../../../utility/useFormat';
import { Info } from '../Info';
import { Module } from '../../../models/Module';

interface Props {
  data: HeatMapModel;
  module: Module;
}
interface CategoryProps {
  data: HeatMapCategory;
  layer: number;
}

export const Category: React.FC<CategoryProps> = ({ data, layer }: CategoryProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const hasSubCategories = data.categories.length > 0;
  const { formatNumber } = useFormat();

  const toggleCollapse = (): void => {
    if (hasSubCategories) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const heatMapStyle = (value: number): SxProps => {
    let color;
    if (value < 5) color = '#ff0000';
    else if (value < 10) color = '#fa2801';
    else if (value < 15) color = '#f64302';
    else if (value < 20) color = '#f45402';
    else if (value < 25) color = '#f16902';
    else if (value < 30) color = '#ee8303';
    else if (value < 35) color = '#eb9704';
    else if (value < 40) color = '#e8aa05';
    else if (value < 45) color = '#e4c806';
    else if (value < 50) color = '#e1dc07';
    else if (value < 55) color = '#def408';
    else if (value < 60) color = '#d0eb0d';
    else if (value < 65) color = '#c5e311';
    else if (value < 70) color = '#bbdc15';
    else if (value < 75) color = '#afd31a';
    else if (value < 80) color = '#a3ca1f';
    else if (value < 85) color = '#94bf25';
    else if (value < 90) color = '#84b42b';
    else if (value < 95) color = '#70a633';
    else color = '#659e37';
    return {
      backgroundColor: color,
      p: 1,
      pl: 2,
      display: { xs: 'block', md: 'flex' },
      minHeight: { xs: '74px', md: 'unset' },
      '& .additional-text::before': {
        md: {
          content: '"-"',
          marginLeft: 4,
          marginRight: 4,
        },
      },
    };
  };

  const hasSimilarStoresPercent = data.similarStoresPercent != null && data.similarStoresColumnPercent != null;
  const row = hasSimilarStoresPercent ? 2 : 3;
  const label = hasSimilarStoresPercent ? 4 : 3;

  return (
    <>
      <Grid
        container
        onClick={toggleCollapse}
        spacing={1}
        sx={{
          cursor: hasSubCategories ? 'pointer' : 'default',
          borderBottom: 'solid 1px  rgb(224, 224, 224)',
          mb: 1,
          pb: 1,
        }}
      >
        <Grid item xs={label} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ ml: layer }}>
            {data.name}
          </Typography>{' '}
          {hasSubCategories && <>{isCollapsed ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}</>}
        </Grid>
        <Grid item xs={row}>
          <Box sx={heatMapStyle(data.storeColumnPercent)}>
            <Typography>{data.storePercent.toFixed(2)}%</Typography>
            <Typography className="additional-text">
              {formatNumber(data.storeCount)} / {formatNumber(data.totalProductCount)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={row}>
          <Box sx={heatMapStyle(data.storeColumnPercentLastYear)}>
            <Typography>{data.storePercentLastYear.toFixed(2)}%</Typography>
            <Typography className="additional-text">
              {formatNumber(data.storeCountLastYear)} / {formatNumber(data.totalProductCount)}
            </Typography>
          </Box>
        </Grid>
        {hasSimilarStoresPercent && (
          <Grid item xs={row}>
            <Box sx={heatMapStyle(data.similarStoresColumnPercent!)}>
              <Typography>{data.similarStoresPercent!.toFixed(2)}%</Typography>
              <Typography className="additional-text">
                {formatNumber(data.similarStoresCount)} / {formatNumber(data.totalProductCount)}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={row}>
          <Box sx={heatMapStyle(data.allStoresColumnPercent)}>
            <Typography> {data.allStoresPercent.toFixed(2)}%</Typography>
            <Typography className="additional-text">
              {formatNumber(data.allStoresCount)} / {formatNumber(data.totalProductCount)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={isCollapsed} sx={{ width: '100%' }}>
        {data.categories.map((category: HeatMapCategory) => (
          <React.Fragment key={category.name}>
            <Category data={category} layer={layer + 1} />
          </React.Fragment>
        ))}
      </Collapse>
    </>
  );
};

export const HeatMap: React.FC<Props> = ({ data, module }: Props) => {
  const hasSimilarStoresPercent = data.categories.some((c: HeatMapCategory) => c.similarStoresPercent != null);
  const row = hasSimilarStoresPercent ? 2 : 3;
  const label = hasSimilarStoresPercent ? 4 : 3;
  const info = {
    [Module.partners]: `Mely termék katgóriákban érdemes fejlődést keresni a többi bolttal összehasonlítva.
    A Referencia partnerek szerinti átlag.`,
    [Module.myTarget]: `Mely termék katgóriákban érdemes fejlődést keresni a többi bolttal összehasonlítva.
    A Referencia értékesítők szerinti átlag.`,
    [Module.admin]: '',
  };
  const title = {
    [Module.partners]: `Termék szélesség hőtérkép - A Referencia partnerek szerinti átlag.`,
    [Module.myTarget]: `Termék szélesség hőtérkép - A Referencia értékesítők szerinti átlag.`,
    [Module.admin]: '',
  };
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title[module]}
        <Info text={info[module]} />
      </Typography>

      <Grid container sx={{ borderBottom: 'solid 1px  rgb(224, 224, 224)', mb: 1 }}>
        <Grid item xs={label}></Grid>
        <Grid
          item
          xs={row}
          sx={{
            my: 1,
            fontWeight: 500,
            fontSize: '0.75rem',
            lineHeight: '1.2857142857142856rem',
            letterSpacing: '0.01071em',
          }}
        >
          {hasSimilarStoresPercent ? 'Aktuális bolt' : 'Gördülő 12 havi'}
        </Grid>
        <Grid
          item
          xs={row}
          sx={{
            my: 1,
            fontWeight: 500,
            fontSize: '0.75rem',
            lineHeight: '1.2857142857142856rem',
            letterSpacing: '0.01071em',
          }}
        >
          Előző évi
        </Grid>
        {hasSimilarStoresPercent && (
          <Grid
            item
            xs={row}
            sx={{
              my: 1,
              fontWeight: 500,
              fontSize: '0.75rem',
              lineHeight: '1.2857142857142856rem',
              letterSpacing: '0.01071em',
            }}
          >
            Referencia - saját kategória
          </Grid>
        )}
        <Grid
          item
          xs={row}
          sx={{
            my: 1,
            fontWeight: 500,
            fontSize: '0.75rem',
            lineHeight: '1.2857142857142856rem',
            letterSpacing: '0.01071em',
          }}
        >
          Referencia - teljes Wolker
        </Grid>
      </Grid>
      {data.categories.map((category: HeatMapCategory) => (
        <React.Fragment key={category.name}>
          <Category data={category} layer={0} />
        </React.Fragment>
      ))}
    </>
  );
};
