import { Suspense } from 'react';
import { AppRoutes } from './router/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './components/common/Layout';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { materialTheme } from './style/materialTheme';
import { PleaseWait } from './components/common/PleaseWait';
import ErrorBoundary from './components/common/ErrorBoundary';
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { huHU } from '@mui/x-date-pickers/locales';
import 'moment/locale/hu';
import moment from 'moment';

const locale = 'hu';

function App() {
  if (moment.locale() !== locale) {
    moment.locale(locale);
  }
  return (
    <Suspense fallback={<PleaseWait />}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={materialTheme}>
              <CssBaseline />
              <StyledEngineProvider injectFirst>
                <LocalizationProvider
                  localeText={huHU.components.MuiLocalizationProvider.defaultProps.localeText}
                  dateAdapter={AdapterMoment}
                  adapterLocale={locale}
                >
                  <BrowserRouter>
                    <Layout>
                      <AppRoutes />
                    </Layout>
                  </BrowserRouter>
                </LocalizationProvider>
              </StyledEngineProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
