import { Box, Typography } from '@mui/material';
import { MonthlySalary } from './MonthlySalary';
import { QuarterlyBonuses } from './QuarterlyBonuses';

export const Salary: React.FC = () => {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Bér és bónusz számítás
      </Typography>
      <MonthlySalary />
      <QuarterlyBonuses />
    </Box>
  );
};
