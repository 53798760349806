import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { Partner } from '../../../models/partners/Partner';
import { Product } from '../../../models/partners/Product';
import { useMemo } from 'react';
import { Box, Theme, Collapse, Typography, Alert } from '@mui/material';
import { useFormat } from '../../../utility/useFormat';
import { Invoice } from '../../../models/partners/Invoice';

interface Props {
  partner: Partner;
}

export const Invoices: React.FC<Props> = ({ partner }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { formatDate, formatCurrency } = useFormat();
  const unpaidHeadCells: TableHeaderCell[] = [
    {
      id: 'invoiceNr',
      isNumeric: false,
      label: 'Számlaszám',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'createdDate',
      isNumeric: false,
      label: 'Keltezés',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'dueDate',
      isNumeric: false,
      label: 'Esedékes',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'daysDue',
      isNumeric: false,
      label: 'Csúszás (nap)',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'amount',
      isNumeric: true,
      label: 'Összeg',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'balance',
      isNumeric: true,
      label: 'Egyenleg',
      disablePadding: true,
      width: '15%',
    },
  ];

  const unpaidData: TableRowData[] = useMemo(
    () =>
      partner.unpaidInvoices.map((invoice: Invoice) => ({
        id: invoice.invoiceNr,
        rowId: invoice.invoiceNr,
        columns: [
          {
            id: 'invoiceNr',
            cValue: invoice.invoiceNr,
            value: invoice.invoiceNr,
          },
          {
            id: 'createdDate',
            value: formatDate(invoice.createdDate),
            cValue: invoice.createdDate,
          },
          {
            id: 'dueDate',
            value: formatDate(invoice.dueDate),
            cValue: invoice.dueDate,
          },
          {
            id: 'daysDue',
            value: invoice.daysDue,
            cValue: invoice.daysDue,
          },
          {
            id: 'amount',
            value: formatCurrency(invoice.amount, 2),
            cValue: invoice.amount,
          },
          {
            id: 'balance',
            value: formatCurrency(invoice.balance, 2),
            cValue: invoice.balance,
          },
        ],
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partner.unpaidInvoices]
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Számlák
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        {unpaidData.length > 0 ? (
          <Table
            id="unpaid-invoices"
            title="Tartozások"
            headCells={unpaidHeadCells}
            rows={unpaidData}
            hideOthersOnSelect={true}
            selected={undefined}
            setSelected={() => {}}
            hidePaper
            defaultPagination={{ page: 0, rowsPerPage: 25 }}
            filterByColumnId={['invoiceNr']}
          />
        ) : (
          <Alert severity="info" sx={{ my: 2 }} variant="standard">
            Nincsenek tartozások
          </Alert>
        )}
      </Collapse>
    </Box>
  );
};
