import * as React from 'react';
import MCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FieldInputProps, FieldMetaProps, FieldHelperProps } from 'formik';

import { CheckboxProps, useFieldToCheckbox } from './FormCheckbox';

export interface CheckboxWithLabelProps extends CheckboxProps {
  label: string;
}

export function FormCheckboxWithLabel<Val = unknown>({
  customize,
  label,
  ...props
}: CheckboxWithLabelProps & {
  customize?: (props: [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>]) => Partial<Omit<CheckboxProps, 'name'>>;
}) {
  return <FormControlLabel control={<MCheckbox {...useFieldToCheckbox(props, customize)} />} label={label} />;
}
