import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, Container, Grid, LinearProgress, Typography, Link } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FormTextField } from '../../common/input/FormTextField';
import { Card } from '../../common/Card';
import { NavLink, useNavigate } from 'react-router-dom';
import { securityClient } from '../../../security/securityClient';
import { ForgotPasswordRequest } from '../../../models/ForgotPasswordRequest';
import { selectMessages, selectToken, setMessages } from '../../../slices/applicationSlice';
import { Access } from '../../../access/Access';

export interface ForgotPasswordValues {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let ref = useRef(null) as any;

  const errorMessages = useSelector(selectMessages);
  const appToken = useSelector(selectToken);
  const token = appToken ?? securityClient.getToken();

  const initialValues: ForgotPasswordValues = {
    email: '',
  };

  useEffect(() => {
    if (errorMessages.length > 0 && ref) {
      ref.setSubmitting(false);
    }
  }, [errorMessages, ref]);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Kérem email címet adjon meg').required('Kérem adja meg email címét'),
  });

  const handleSubmit = (values: ForgotPasswordValues): void => {
    const criteria: ForgotPasswordRequest = {
      userName: values.email,
    };
    const access = new Access();
    access
      .forgotPassword(criteria)
      .then((value) => {
        if (value?.error == null) {
          dispatch(
            setMessages([
              {
                message: 'Ellenőrizze az email fiókját!',
                severity: 'success',
                key: 'forgot-password',
                hideSnackBar: false,
              },
            ])
          );
          if (ref) {
            ref.resetForm();
          }
        } else {
          dispatch(
            setMessages([{ message: value?.error ?? 'Hiba történt', key: 'forgot-password', hideSnackBar: true }])
          );
        }
      })
      .catch((reason: any) => {
        if (reason.message) {
          dispatch(setMessages([{ message: reason.message, key: 'forgot-password', hideSnackBar: true }]));
        }
      })
      .finally(() => {
        if (ref) {
          ref.setSubmitting(false);
        }
      });
  };

  return (
    <Container maxWidth="sm">
      <Card title="Elfelejtett jelszó">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={(instance) => {
            ref = instance;
          }}
        >
          {({ isSubmitting, values, errors, touched }) => (
            <>
              {isSubmitting && (
                <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                  <LinearProgress />
                </Box>
              )}
              <Form>
                <Grid container alignItems="stretch" justifyContent="center">
                  <Grid item component={Box} paddingTop={2} xs={10}>
                    <FormTextField label="Email" name="email" id="email" fullWidth variant="outlined" />
                  </Grid>
                  <Grid item component={Box} paddingTop={2} xs={10}>
                    <Typography variant="body2">
                      Adja meg email címét, hamarosan emailben tájékoztatjuk a további teendőkről.
                    </Typography>
                  </Grid>
                  {errorMessages.length > 0 && errorMessages[0].severity !== 'success' && (
                    <Grid item component={Box} paddingTop={2} xs={10}>
                      <Alert severity="error">{errorMessages[0].message}</Alert>
                    </Grid>
                  )}
                </Grid>

                <Box sx={{ pt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Link component={NavLink} to="/" underline="none" color="primary">
                    Bejelentkezés
                  </Link>
                  <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                    Küldés
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </Card>
    </Container>
  );
};
