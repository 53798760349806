import { useMemo, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Table, TableHeaderCell, TableRowData } from '../../../common/Table';
import { useFormat } from '../../../../utility/useFormat';
import { Task } from '../../../../models/myTarget/Task';
import { ReadMore } from '../../../common/ReadMore';
import { TaskStatus } from '../../../../models/myTarget/TaskStatus';
import { StatusFilter } from './StatusFilter';
import { Status } from './Status';
import { TaskButton } from './TaskButton';
import { Access } from '../../../../access/Access';
import { SaveMyTargetRequest } from '../../../../models/myTarget/SaveMyTargetRequest';
import { SaveMyTargetResponse } from '../../../../models/myTarget/SaveMyTargetResponse';
import { useDispatch } from 'react-redux';
import { setTasks } from '../../../../slices/myTargetSlice';
import { addMessage } from '../../../../slices/applicationSlice';
import { SaveTaskRequest } from '../../../../models/myTarget/SaveTaskRequest';
import { EditTask } from './EditTask';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  data: Task[];
  agentId: number;
  isAdmin: boolean;
}

export interface TaskValues {
  id: number;
  name: string;
  description: string;
  status?: TaskStatus;
  notes: string;
  adminNotes: string;
}

export const Tasks: React.FC<Props> = ({ data, agentId, isAdmin }: Props) => {
  const { formatDate } = useFormat();
  const dispatch = useDispatch();
  const [selectedStatuses, setSelectedStatuses] = useState<TaskStatus[]>([
    TaskStatus.open,
    TaskStatus.inProgress,
    TaskStatus.done,
    TaskStatus.accepted,
  ]);
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const headCells: TableHeaderCell[] = [
    {
      id: 'date',
      isNumeric: false,
      label: 'Dátum',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'status',
      isNumeric: false,
      label: 'Státus',
      disablePadding: true,
      width: '10%',
    },
    {
      id: 'name',
      isNumeric: false,
      label: 'Név',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'description',
      isNumeric: false,
      label: 'Leírás',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'notes',
      isNumeric: false,
      label: 'Eredmény leírása (Értékesítő)',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'adminNotes',
      isNumeric: false,
      label: 'Visszajelzés (Értékesítési vezető)',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const handleClick = (task: Task) => {
    switch (task.status) {
      case TaskStatus.open:
      case TaskStatus.accepted:
        saveTask({
          id: task.id,
          name: task.name ?? '',
          description: task.description ?? '',
          status: task.status,
          notes: '',
          adminNotes: '',
        });
        break;
      case TaskStatus.inProgress:
      case TaskStatus.done:
      case undefined:
        setSelectedTask(task);
        break;
    }
  };

  const saveTask = (values: TaskValues) => {
    const access = new Access();
    const taskCriteria: Task = {
      id: values.id,
    };

    if (values != null) {
      if (values.status == null) {
        taskCriteria.name = values.name;
        taskCriteria.description = values.description;
        taskCriteria.status = TaskStatus.open;
      }
      if (values.status === TaskStatus.inProgress) {
        taskCriteria.notes = values.notes;
      } else if (values.status === TaskStatus.done && isAdmin) {
        taskCriteria.adminNotes = values.adminNotes;
      }
    }

    const saveTask: SaveTaskRequest = {
      task: taskCriteria,
    };

    const request: SaveMyTargetRequest = {
      agentId,
      saveTask,
    };
    access
      .saveMyTarget(request)
      .then((value: SaveMyTargetResponse | undefined) => {
        if (value?.error == null && value?.agentInfo?.tasks != null) {
          dispatch(setTasks(value.agentInfo.tasks));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-myTarget' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setSelectedTask(undefined);
      });
  };

  const handleCreateTask = (): void => {
    handleClick({
      id: 0,
    });
  };

  const taskData: TableRowData[] = useMemo(
    () => {
      const term = searchTerm ? searchTerm.toLowerCase() : undefined;
      return data
        .filter(
          (task: Task) =>
            (selectedStatuses.length === 0 ||
              selectedStatuses.some((s: TaskStatus) => (s as unknown as TaskStatus) === task.status)) &&
            (!term ||
              task.name == null ||
              task.name.toLowerCase().indexOf(term) > -1 ||
              task.description == null ||
              task.description.toLowerCase().indexOf(term) > -1 ||
              task.notes == null ||
              task.notes.toLowerCase().indexOf(term) > -1 ||
              task.adminNotes == null ||
              task.adminNotes.toLowerCase().indexOf(term) > -1)
        )
        .map((task: Task) => ({
          rowId: `task-${task.id}`,
          id: task.id,
          columns: [
            {
              id: 'date',
              cValue: task.date,
              value: task.date ? formatDate(task.date) : '',
            },
            {
              id: 'status',
              cValue: task.status,
              value: <Status data={task.status} />,
            },
            {
              id: 'name',
              cValue: task.name,
              value: task.name,
            },
            {
              id: 'description',
              cValue: task.description,
              value: task.description ? <ReadMore text={task.description} maxLength={100} variant="caption" /> : '',
            },
            {
              id: 'notes',
              cValue: task.notes,
              value: task.notes ? <ReadMore text={task.notes} maxLength={100} variant="caption" /> : '',
            },
            {
              id: 'adminNotes',
              cValue: task.adminNotes,
              value: task.adminNotes ? <ReadMore text={task.adminNotes} maxLength={100} variant="caption" /> : '',
            },
            {
              id: 'operation',
              cValue: '',
              value: <TaskButton data={task} isAdmin={isAdmin} onClick={handleClick} />,
            },
          ],
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, selectedStatuses, searchTerm]
  );

  const handleChangeSearchTerm = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  return (
    <>
      <Typography variant="h6" fontWeight="bold">
        Egyéb feladataim
      </Typography>

      {isAdmin && (
        <Button
          variant="contained"
          sx={{ my: 2 }}
          startIcon={<AddIcon sx={{ fill: '#fff' }} />}
          color="primary"
          onClick={handleCreateTask}
        >
          Új feladat
        </Button>
      )}
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 4 }}>
          <StatusFilter selected={selectedStatuses} setSelected={setSelectedStatuses} />
        </Box>
        <TextField
          id="search-term-tasks"
          label="Szűrés"
          variant="standard"
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          size="small"
          sx={{ flexGrow: 1, ml: 2, mt: 1.7 }}
        />
      </Box>
      <Table
        id="tasks"
        headCells={headCells}
        rows={taskData}
        hideOthersOnSelect={true}
        selected={undefined}
        setSelected={() => {}}
        hidePaper
        defaultPagination={{ page: 0, rowsPerPage: 25 }}
      />
      {selectedTask && (
        <EditTask data={selectedTask} isAdmin={isAdmin} onClose={() => setSelectedTask(undefined)} onSave={saveTask} />
      )}
    </>
  );
};
