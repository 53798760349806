import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  SwipeableDrawer,
  Theme,
  Typography,
} from '@mui/material';
import { EditableValue as EditableValueModel } from '../../models/partners/EditableValue';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Form, Formik, FormikHelpers } from 'formik';
import { Access } from '../../access/Access';
import { SavePartnerRequest } from '../../models/partners/SavePartnerRequest';
import { SavePartnerResponse } from '../../models/partners/SavePartnerResponse';
import { selectPromptEditor, updateState } from '../../slices/partnersSlice';
import { addMessage } from '../../slices/applicationSlice';
import { FormTextField } from './input/FormTextField';
import { useFormat } from '../../utility/useFormat';
import { EditableCriteria } from '../../models/partners/EditableCriteria';
import { PromptEdit } from '../../models/partners/PromptEdit';

interface Props {
  id: string;
  title: string;
  label: string;
  actualLabel?: string;
  data: EditableValueModel;
  partnerId: number;
  format?: (value: number) => string;
  propertyName: string;
}

interface EditableValues {
  value: number;
  editForYear: number;
  isEditable: boolean;
}

export const EditableValue = <T extends EditableCriteria>({
  id,
  title,
  actualLabel,
  label,
  data,
  partnerId,
  format,
  propertyName,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenPrompt, setIsOpenPrompt] = useState<boolean>(false);
  const prompt: PromptEdit | undefined = useSelector(selectPromptEditor);
  const dispatch = useDispatch();
  const { formatCurrency } = useFormat();
  const formatter = format != null ? format : formatCurrency;

  useEffect(() => {
    if (prompt?.property === id) {
      setIsOpenPrompt(true);
      dispatch(updateState({ promptEditor: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt, id]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(!isOpen);
  };

  const initialValues: EditableValues = {
    value: data.value ?? 0,
    editForYear: data.editForYear ?? 0,
    isEditable: data.isEditable ?? false,
  };

  const handleSubmit = (value: EditableValues, formikHelpers: FormikHelpers<EditableValues>): void => {
    const access = new Access();
    const criteria: T = {
      value: value.value,
      editForYear: value.editForYear,
      isEditable: value.isEditable,
    } as T;

    const request: SavePartnerRequest = {
      partnerId: partnerId,
      [propertyName]: criteria,
    };
    access
      .savePartner(request)
      .then((value: SavePartnerResponse | undefined) => {
        if (value?.error == null && value?.partner != null) {
          dispatch(updateState(value));
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-partner' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        setIsOpen(false);
      });
  };

  const handleClosePrompt = (): void => {
    setIsOpenPrompt(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={
            data.isActual === false
              ? (theme: Theme) => ({ textDecoration: 'line-through', textDecorationColor: theme.palette.error.light })
              : undefined
          }
        >
          {data.value ? formatter(data.value) : 'N/A'}
        </Typography>
        {data.isEditable && (
          <IconButton title="Módosítás" onClick={toggleDrawer}>
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      {data.isActual === false && (
        <Typography variant="caption" color="error" sx={{ display: 'block', mt: -1, mb: -2 }}>
          Az érték már nem releváns.
        </Typography>
      )}

      <SwipeableDrawer anchor="right" open={isOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <Box sx={{ width: { xs: '100', sm: 400 }, p: 3 }}>
          <Typography variant="h5">{title}</Typography>
          <Box sx={{ pt: 4 }}>
            <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <>
                  {isSubmitting && (
                    <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                      <LinearProgress />
                    </Box>
                  )}
                  <Form>
                    {actualLabel && (
                      <Box pb={2}>
                        <Typography>{`${actualLabel}: ${data.value ? formatter(data.value) : 'N/A'}`}</Typography>
                      </Box>
                    )}
                    <Box pb={2}>
                      <FormTextField size="small" label={label} name="value" id="value" fullWidth type="number" />
                    </Box>

                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={toggleDrawer} disabled={isSubmitting} sx={{ mr: 2 }}>
                        Mégse
                      </Button>
                      <Button variant="contained" type="submit" disabled={isSubmitting}>
                        Mentés
                      </Button>
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        </Box>
      </SwipeableDrawer>
      {prompt?.property === id && (
        <Dialog
          open={isOpenPrompt}
          onClose={handleClosePrompt}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{prompt.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{prompt.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePrompt} color="primary">
              Nem
            </Button>
            <Button
              onClick={() => {
                handleClosePrompt();
                setIsOpen(true);
              }}
              autoFocus
              variant="contained"
              color="primary"
            >
              Igen
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
