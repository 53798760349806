import { useMemo } from 'react';
import { Statistics } from '../../../models/myTarget/Statistics';
import { Grid, Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { TargetFulfillment } from '../../../models/common/TargetFulfillment';
import { useFormat } from '../../../utility/useFormat';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Gauge } from '../../common/Gauge';

interface Props {
  statistics: Statistics;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Filler, Legend);

export const Fulfillment: React.FC<Props> = ({ statistics }: Props) => {
  const { currentFulfillment, periodFulfillment, yearlyTargetFulfillment, monthlyFulfillment } = statistics;
  const { formatNumber } = useFormat();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const getMonth = (month: number, quarter: number): string => {
    switch (quarter) {
      case 0:
        if (month === 1) return 'Január';
        if (month === 2) return 'Február';
        if (month === 3) return 'Március';
        break;
      case 1:
        if (month === 1) return 'Április';
        if (month === 2) return 'Május';
        if (month === 3) return 'Június';
        break;
      case 2:
        if (month === 1) return 'Július';
        if (month === 2) return 'Augusztus';
        if (month === 3) return 'Szeptember';
        break;
      case 3:
        if (month === 1) return 'Október';
        if (month === 2) return 'November';
        if (month === 3) return 'December';
        break;
    }
    return '';
  };

  const qFulfillmentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Negyedéves célok teljesülése (Pont)',
      },
      legend: {
        position: 'bottom' as const,
        labels: {
          filter: (item: any) => item.datasetIndex !== 1 && item.datasetIndex !== 2,
        },
      },
      datalabels: {
        labels: {
          title: {
            align: 'top' as any,
            anchor: 'end' as any,
            offset: 0,
            color: 'rgba(0, 0, 0, 0.87)',
            formatter: function (value: any, context: any) {
              if (context.dataset.percent != null && context.dataset.percent[context.dataIndex]) {
                return formatNumber(context.dataset.percent[context.dataIndex], '%');
              }

              if (context.dataset.sum != null && context.dataset.sum[context.dataIndex]) {
                return formatNumber(context.dataset.sum[context.dataIndex]);
              }

              return null;
            },
          },
          value: {
            align: 'center' as any,
            formatter: (value: any) => formatNumber(value),
          },
        },
      },

      tooltip: {
        callbacks: {
          label: (context: any) => {
            const labels: string[] = [];
            if (context.dataset.stack === 'target') {
              labels.push(getMonth(context.dataset.month, context.dataIndex));
            }
            labels.push(`${context.dataset.label}: ${context.formattedValue}`);

            return labels;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const fulfillmentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        labels: {
          title: {
            align: 'top' as any,
            anchor: 'end' as any,
            offset: 0,
            color: 'rgba(0, 0, 0, 0.87)',
            formatter: function (value: any, context: any) {
              if (context.dataset.percent != null && context.dataset.percent[context.dataIndex]) {
                return formatNumber(context.dataset.percent[context.dataIndex], '%');
              }

              return null;
            },
          },
          value: {
            align: 'center' as any,
            rotation: 270,
            formatter: (value: any) => formatNumber(value),
          },
        },
      },
    },
  };

  const data = useMemo(() => {
    const labels: string[] = [];
    const targetData: number[] = [];
    const accomplishedData: number[] = [];
    yearlyTargetFulfillment.forEach((y: TargetFulfillment) => {
      labels.push(y.label);
      targetData.push(y.target);
      accomplishedData.push(y.accomplished);
    });

    return {
      labels,
      datasets: [
        {
          fill: false,
          label: 'Elért pontjaim (Pont)',
          data: accomplishedData,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49, 0.5)',
        },
        {
          fill: false,
          label: 'Pontcélom (Pont)',
          data: targetData,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
        },
      ],
    };
  }, [yearlyTargetFulfillment]);

  const quarterlyData = useMemo(() => {
    const labels: string[] = [];
    const accomplished: number[] = [];
    const target1: number[] = [];
    const target2: number[] = [];
    const target3: number[] = [];
    const percent: number[] = [];
    const sum: number[] = [];

    let month = 0;
    for (let i = 0; i < 4; i++) {
      const label = (i + 1).toString();
      labels.push(label);
      let accomplishedQuarter = 0;
      let targetQuarter = 0;

      target1.push(monthlyFulfillment[i * 3 + 0].target);
      target2.push(monthlyFulfillment[i * 3 + 1].target);
      target3.push(monthlyFulfillment[i * 3 + 2].target);
      for (let j = 1; j <= 3; j++) {
        accomplishedQuarter += monthlyFulfillment[month].accomplished;
        targetQuarter += monthlyFulfillment[month].target;
        month++;
      }

      const p = targetQuarter !== 0 ? (accomplishedQuarter * 100) / targetQuarter : 0;
      percent.push(p);
      accomplished.push(accomplishedQuarter);
      sum.push(targetQuarter);
    }

    const data = {
      labels,
      datasets: [
        {
          label: 'Pontcélom (Pont)',
          data: target1,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
          stack: 'target',
          month: 1,
        },
        {
          label: 'Pontcélom (Pont)',
          data: target2,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
          stack: 'target',
          month: 2,
        },
        {
          label: 'Pontcélom (Pont)',
          data: target3,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
          stack: 'target',
          month: 3,
          sum,
        },
        {
          label: 'Elért pontjaim (Pont)',
          data: accomplished,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49)',
          stack: 'accomplished',
          percent,
        },
      ],
    };
    return data;
  }, [monthlyFulfillment]);

  const monthlyData = useMemo(() => {
    const labels: string[] = [];
    const accomplished: number[] = [];
    const target: number[] = [];
    const percent: number[] = [];

    monthlyFulfillment.forEach((f: TargetFulfillment) => {
      labels.push(f.label);
      accomplished.push(f.accomplished);
      target.push(f.target);
      const p = f.target !== 0 ? (f.accomplished * 100) / f.target : 0;
      percent.push(p);
    });

    const data = {
      labels,
      datasets: [
        {
          label: 'Pontcélom (Pont)',
          data: target,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
        },
        {
          label: 'Elért pontjaim (Pont)',
          data: accomplished,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49)',
          percent,
        },
      ],
    };
    return data;
  }, [monthlyFulfillment]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Gauge
          title="Időarányos teljesítmény"
          data={periodFulfillment}
          targetText={'Megelőző év azonos időszakának teljesítménye alapján várható pontjaim'}
          targetInfo="Az éves pont célom mai napig eltelt időre jutó része"
          completementText="Gyűjtött pontjaim"
          completementInfo="A mai napig gyűjtött pontjaim"
          percentText="Időarányos teljesítmény"
          unit="Pont"
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Gauge
          title="Éves teljesítmény"
          data={currentFulfillment}
          targetText="Éves pont célom"
          targetInfo="Az évben elérendő összes pont"
          completementText="Idén elért pontjaim"
          completementInfo="Az évben eddig gyűjtött összes pont"
          percentText="Teljesülés"
          unit="Pont"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Időarányos teljesítmény (Pont)
        </Typography>
        <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
          <Line
            options={{
              ...options,
              plugins: {
                ...options.plugins,
                title: {
                  display: true,
                  text: 'Megelőző év azonos időszakának teljesítménye alapján várható pontjaim vs. Időarányos teljesítményem (Pont)',
                },
              },
            }}
            data={data}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Negyedéves célok teljesülése (Pont)
        </Typography>
        <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
          <Bar options={qFulfillmentOptions} data={quarterlyData} plugins={[ChartDataLabels]} />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Havi célok teljesülése (Pont)
        </Typography>
        <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
          <Bar
            options={{
              ...fulfillmentOptions,
              plugins: {
                ...fulfillmentOptions.plugins,
                title: {
                  display: true,
                  text: 'Havi célok teljesülése (Pont)',
                },
              },
            }}
            data={monthlyData}
            plugins={[ChartDataLabels]}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
