import { Box, Paper, Theme, Typography } from '@mui/material';
import { Info } from './Info';

interface Props {
  title: string;
  info?: string;
  children: any;
}

export const InfoBox: React.FC<Props> = ({ title, info, children }: Props) => (
  <Paper sx={{ p: 2, pb: 2.5 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <Typography sx={(theme: Theme) => ({ opacity: 0.8, minHeight: theme.spacing(6) })} variant="body2">
        {title}
      </Typography>
      {info && <Info text={info} />}
    </Box>

    {children}
  </Paper>
);
