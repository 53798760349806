import { useState, useEffect } from 'react';

export const useSessionStorage = <T>(key: string, isFakeStorage: boolean, defaultValue: T) => {
  const getStorageValue = (key: string, isFakeStorage: boolean, defaultValue: T): T => {
    if (isFakeStorage) {
      return defaultValue;
    }
    // getting stored value
    const saved = sessionStorage.getItem(key);
    const initial = saved != null ? JSON.parse(saved) : undefined;
    return initial ?? defaultValue;
      };

  const [value, setValueHandler] = useState<T>(() => {
    return getStorageValue(key, isFakeStorage, defaultValue);
  });

  const setValue = (value: T) => {
    setValueHandler(value);
  };

  useEffect(() => {
    if (!isFakeStorage) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isFakeStorage]);

  return { value, setValue };
};
