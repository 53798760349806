import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { AdminState } from '../models/slices/AdminState';
import { KeyPerformanceIndicators } from '../models/admin/KeyPerformanceIndicators';
import { FinancialPerformanceIndicators } from '../models/admin/FinancialPerformanceIndicators';
import { AgentsPerformance } from '../models/admin/AgentsPerformance';
import { AgentsDetailedIndicators } from '../models/admin/AgentsDetailedIndicators';
import { Salary } from '../models/admin/Salary';
import { Bonus } from '../models/admin/Bonus';

const initialState: AdminState = {
  keyPerformanceIndicators: undefined,
  financialPerformanceIndicators: undefined,
  agentsPerformance: undefined,
  agentsDetailedIndicators: undefined,
  salary: undefined,
  bonus: undefined,
};

const adminSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    // setResponse: (state, action: PayloadAction<LoadPartnerResponse | undefined>) => {
    //   state.agents = action.payload?.agents;
    //   state.partners = action.payload?.partners;
    // },
    setKeyPerformanceIndicators: (state, action: PayloadAction<KeyPerformanceIndicators | undefined | null>) => {
      state.keyPerformanceIndicators = action.payload;
    },
    setFinancialPerformanceIndicators: (
      state,
      action: PayloadAction<FinancialPerformanceIndicators | undefined | null>
    ) => {
      state.financialPerformanceIndicators = action.payload;
    },
    setAgentsPerformance: (state, action: PayloadAction<AgentsPerformance | undefined | null>) => {
      state.agentsPerformance = action.payload;
    },
    setAgentsDetailedIndicators: (state, action: PayloadAction<AgentsDetailedIndicators | undefined | null>) => {
      state.agentsDetailedIndicators = action.payload;
    },
    setSalary: (state, action: PayloadAction<Salary[] | undefined | null>) => {
      state.salary = action.payload;
    },
    setBonus: (state, action: PayloadAction<Bonus[] | undefined | null>) => {
      state.bonus = action.payload;
    },
  },
});

export const {
  setKeyPerformanceIndicators,
  setFinancialPerformanceIndicators,
  setAgentsPerformance,
  setAgentsDetailedIndicators,
  setSalary,
  setBonus,
} = adminSlice.actions;

export const selectKeyPerformanceIndicators = (state: RootState) => state.localData.admin.keyPerformanceIndicators;
export const selectFinancialPerformanceIndicators = (state: RootState) =>
  state.localData.admin.financialPerformanceIndicators;
export const selectAgentsPerformance = (state: RootState) => state.localData.admin.agentsPerformance;
export const selectAgentsDetailedIndicators = (state: RootState) => state.localData.admin.agentsDetailedIndicators;
export const selectSalary = (state: RootState) => state.localData.admin.salary;
export const selectBonus = (state: RootState) => state.localData.admin.bonus;
export default adminSlice.reducer;
