import { useRef } from 'react';
import { Visit } from '../../../models/visits/Visit';
import { Box, Button, LinearProgress, SwipeableDrawer, Typography } from '@mui/material';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Access } from '../../../access/Access';
import { SavePartnerResponse } from '../../../models/partners/SavePartnerResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { FormTextField } from '../input/FormTextField';
import TimeSelector from '../TimeSelector';
import moment from 'moment';
import { FormDatePicker } from '../input/FormDatePicker';
import { SaveVisitRequest } from '../../../models/visits/SaveVisitRequest';
import { FileUpload } from '../fileUpload/FileUpload';
import { AddFile } from '../fileUpload/FileConfiguration';

interface Props {
  visit: Visit;
  onCancel: () => void;
  onEdited: () => void;
  isAdmin: boolean;
}

interface VisitValues {
  id: number;
  date: any;
  from: string;
  to: string;
  notes: string;
  adminNotes?: string;
  photo?: string;
}

export const EditVisit: React.FC<Props> = ({ visit, onCancel, onEdited, isAdmin }: Props) => {
  const dispatch = useDispatch();

  let ref = useRef(null) as any;

  const initialValues: VisitValues = {
    ...visit,
    date: moment(visit.date),
  };

  const handleSubmit = (value: VisitValues): void => {
    const access = new Access();
    const request: SaveVisitRequest = {
      visit: {
        ...value,
        adminNotes: isAdmin ? value.adminNotes : undefined,
        partner: visit.partner
          ? {
              ...visit.partner,
            }
          : undefined,
      },
    };
    access
      .saveVisit(request)
      .then((value: SavePartnerResponse | undefined) => {
        if (value?.error == null) {
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-partner' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        if (ref) ref.setSubmitting(false);
        onEdited();
      });
  };

  return (
    <SwipeableDrawer anchor="right" open onOpen={() => {}} onClose={onCancel}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">Látogatás</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            innerRef={(instance) => {
              ref = instance;
            }}
          >
            {({ isSubmitting, values, errors, setFieldValue }) => {
              const date = moment();
              const splitedStart = values.from.split(':');
              const splitedEnd = values.to.split(':');
              const startTime = date
                .set('hours', Number(splitedStart[0]))
                .set('minutes', Number(splitedStart[1]))
                .toDate();
              const endTime = date.set('hours', Number(splitedEnd[0])).set('minutes', Number(splitedEnd[1])).toDate();

              const fromError = errors.from;
              const toError = errors.to;
              return (
                <>
                  {isSubmitting && (
                    <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                      <LinearProgress />
                    </Box>
                  )}
                  <Form>
                    {visit.id === 0 && (
                      <>
                        <FormDatePicker
                          label="Dátum"
                          name="date"
                          closeOnSelect
                          disableFuture
                          views={['year', 'month', 'day']}
                          slotProps={{
                            textField: { variant: 'standard', fullWidth: true, id: 'date', size: 'small' },
                          }}
                        />

                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <TimeSelector
                            label="Érkezés"
                            value={startTime}
                            variant="standard"
                            error={fromError != null || toError != null}
                            helperText={fromError}
                            onChange={(time: Date) => setFieldValue('from', moment(time).format('HH:mm'))}
                            size="small"
                          />
                          <Typography sx={{ mx: 2 }}> - </Typography>
                          <TimeSelector
                            label="Távozás"
                            value={endTime}
                            variant="standard"
                            error={fromError != null || toError != null}
                            helperText={toError}
                            onChange={(time: Date) => setFieldValue('to', moment(time).format('HH:mm'))}
                            size="small"
                          />
                        </Box>

                        <Box my={2}>
                          <FormTextField
                            size="small"
                            label="Megjegyzés"
                            name="notes"
                            id="notes"
                            fullWidth
                            multiline
                            maxRows={15}
                          />
                        </Box>
                      </>
                    )}
                    {isAdmin && (
                      <Box my={2}>
                        <FormTextField
                          size="small"
                          label="Megjegyzés (Admin)"
                          name="adminNotes"
                          id="adminNotes"
                          fullWidth
                          multiline
                          maxRows={15}
                        />
                      </Box>
                    )}
                    {visit.id === 0 && (
                      <Box my={2}>
                        <FileUpload
                          disabled={isSubmitting}
                          acceptedFiles=""
                          onDropFiles={(files: AddFile[]) => {
                            if (files.length) {
                              setFieldValue('photo', files[0].content);
                            } else {
                              setFieldValue('photo', undefined);
                            }
                          }}
                        />
                      </Box>
                    )}
                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={onCancel} disabled={isSubmitting} sx={{ mr: 2 }}>
                        Mégse
                      </Button>
                      <Button variant="contained" type="submit" disabled={isSubmitting}>
                        Mentés
                      </Button>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
