import { Box, Typography } from '@mui/material';
import React from 'react';
import { Logo } from '../../assets/Logo';

//import "./PleaseWait.scss";

export const PleaseWait: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Logo
        style={{
          animation: 'bounceIn 5s infinite',
          transform: 'rotate(0deg) scale(1) translateZ(0)',
          transition: 'all 0.4s cubic-bezier(0.8, 1.8, 0.75, 0.75)',
          width: 64,
          height: 95,
        }}
      />
      <Box paddingTop={2}>
        <Typography variant="caption">Kérem várjon...</Typography>
      </Box>
    </Box>
  );
};
