import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Popover, Typography } from '@mui/material';
import { useState } from 'react';

interface Props {
  text: string;
}
export const Info: React.FC<Props> = ({ text }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popover' : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        aria-haspopup="true"
        aria-owns={open ? id : undefined}
        size="small"
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
        }}
      >
        <Typography sx={{ p: 2 }} whiteSpace="pre-line">
          {text}
        </Typography>
      </Popover>
    </>
  );
};
