import { Box, Grid, Typography } from '@mui/material';
import { IncomeContribution as IncomeContributionModel } from '../../../models/admin/IncomeContribution';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { useFormat } from '../../../utility/useFormat';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo } from 'react';
import { Info } from '../../common/Info';

interface Props {
  data: IncomeContributionModel[];
}

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Filler, Legend);

export const IncomeContribution: React.FC<Props> = ({ data }) => {
  const { formatNumber } = useFormat();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Értékesítői árbevétel és eredmény kontribúció - Idei év (Millió Ft)',
      },
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        labels: {
          title: {
            align: 'top' as any,
            anchor: 'end' as any,
            offset: 0,
            color: 'rgba(0, 0, 0, 0.87)',
            formatter: function (value: any, context: any) {
              if (context.dataset.sum != null && context.dataset.sum[context.dataIndex]) {
                const total =
                  context.dataset.sumPercent != null && context.dataset.sumPercent[context.dataIndex]
                    ? ` (${formatNumber(context.dataset.sumPercent[context.dataIndex])}%)`
                    : '';
                return `${formatNumber(context.dataset.sum[context.dataIndex], '', 1)}${total}`;
              }

              return null;
            },
          },
          value: {
            align: 'center' as any,
            font: {
              size: 8,
            },
            formatter: (value: any, context: any) => {
              if (context.dataset.percent != null && context.dataset.percent[context.dataIndex]) {
                return `${formatNumber(context.dataset.value[context.dataIndex], '', 1)} (${formatNumber(
                  context.dataset.percent[context.dataIndex]
                )}%)`;
              }
              return formatNumber(context.dataset.value[context.dataIndex], '', 1);
            },
          },
        },
      },

      tooltip: {
        callbacks: {
          label: (context: any) => {
            const labels: string[] = [];
            let label = '';
            if (context.dataset.percent != null && context.dataset.percent[context.dataIndex]) {
              label = `${formatNumber(context.dataset.value[context.dataIndex], ' Millió Ft', 1)} (${formatNumber(
                context.dataset.percent[context.dataIndex]
              )}%)`;
            } else {
              label = formatNumber(context.dataset.value[context.dataIndex], ' Millió Ft', 1);
            }
            labels.push(`${context.dataset.label}: ${label}`);

            return labels;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const chartData = useMemo(() => {
    const labels: string[] = [];
    const incomeValue: number[] = [];
    const marginValue: number[] = [];
    const income: number[][] = [];
    const margin: number[][] = [];
    const percent: number[] = [];
    const sum: number[] = [];
    const sumPercent: number[] = [];
    let totalIncome = 0;
    let totalMargin = 0;

    data.forEach((item: IncomeContributionModel, index: number) => {
      incomeValue.push(item.income);
      marginValue.push(item.margin);
      labels.push(item.label);
      margin.push([0, item.margin]);
      percent.push(item.marginPercent);
      sum.push(item.total);
      sumPercent.push(item.totalPercent);
      const startIncome = totalIncome + totalMargin;
      const endIncome = startIncome + item.income;
      income.push([startIncome, endIncome]);
      totalIncome += item.income;
      totalMargin += item.margin;
    });
    labels.push('Teljes');
    incomeValue.push(totalIncome);
    marginValue.push(totalMargin);
    income.push([0, totalIncome]);
    margin.push([0, totalMargin]);
    const totalSum = totalIncome + totalMargin;
    const totalPercent = Math.ceil((totalMargin * 100) / totalSum);
    sum.push(totalSum);
    percent.push(totalPercent);

    const value = {
      labels,
      datasets: [
        {
          label: 'ELÁBÉ',
          data: income,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
          value: incomeValue,
          stack: 'price',
        },
        {
          label: 'Marzs',
          data: margin,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49)',
          percent,
          value: marginValue,
          stack: 'price',
          sum,
          sumPercent,
        },
      ],
    };
    return value;
  }, [data]);

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Értékesítői árbevétel és eredmény kontribúció - Idei év (Millió Ft)
        <Info
          text={`Az idei évre számított értékesítői abszolut érbevétel, valamint a teljes összeg százalékos kontribuciója.
        Marzs, valamint az árbevételen belüli aránya.`}
        />
      </Typography>
      <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
        <Bar options={options} data={chartData} plugins={[ChartDataLabels]} />
      </Box>
    </Grid>
  );
};
