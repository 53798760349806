import { useField } from 'formik';
import { TextField } from '@mui/material';

export const FormTextField = (props: any) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      variant="standard"
      {...field}
      {...props}
      error={!!meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ''}
    />
  );
};
