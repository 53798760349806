import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { ApplicationContext, Message } from '../models/slices/ApplicationContext';
import { v4 as uuidv4 } from 'uuid';

const initialState: ApplicationContext = {
  messages: [],
  token: undefined,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<string | Message>) => {
      const message = typeof action.payload === 'string' ? { message: action.payload, key: uuidv4() } : action.payload;
      state.messages = [...state.messages, message];
    },
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = action.payload;
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((message: Message) => message.key !== action.payload);
    },
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
  },
});

export const { addMessage, setMessages, removeMessage, setToken } = applicationSlice.actions;

export const selectApplication = (state: RootState) => state.localData.application;
export const selectMessages = (state: RootState) => state.localData.application.messages;
export const selectToken = (state: RootState) => state.localData.application.token;

export default applicationSlice.reducer;
