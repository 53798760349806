import React from 'react';
import { FieldInputProps, FieldMetaProps, FieldHelperProps, useField } from 'formik';
import { FormHelperText, SelectChangeEvent, SelectProps, Select } from '@mui/material';

interface Props extends SelectProps {
  helperText?: string;
}

export interface FormSelectProps extends Omit<Props, 'name' | 'value'> {
  name: string;
}

export function useFieldToSelect<Val = unknown>(
  { disabled, name, ...props }: FormSelectProps,
  customize?: (
    props: [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>]
  ) => Partial<Omit<Props, 'name'>>
): Props {
  const fieldProps = useField(name);

  const [field, meta, helpers] = fieldProps;

  const onChange = React.useCallback(
    (event: SelectChangeEvent<unknown>, child: any) => {
      // Special case for multiple and native
      if (props.multiple && props.native) {
        const { options } = event.target as HTMLSelectElement;
        const value: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }

        helpers.setValue(value);
      } else {
        field.onChange(event);
      }

      if (props.onChange) props.onChange(event, child);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, helpers, props.multiple, props.native]
  );

  return {
    disabled,
    ...props,
    ...field,
    error: !!meta.touched && !!meta.error,
    helperText: meta.error,
    onChange,
    ...(customize ? fieldProps : undefined),
  };
}

export const FormSelect = (props: FormSelectProps) => {
  const properties = useFieldToSelect(props);
  const { helperText, ...selectProps } = properties;
  return (
    <>
      <Select {...selectProps} />
      {properties.error && <FormHelperText className="error">{helperText}</FormHelperText>}
    </>
  );
};
