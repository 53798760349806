import { Typography, Divider } from '@mui/material';

import { Partners } from './Partners';
import { MyTasks } from './MyTasks';

export interface TargetPartnerValues {
  id: number;
  name: string;
  address: string;
  partnerCode?: string;
}

interface Props {
  agentId: number;
  isAdmin: boolean;
}
export const PartnersAndTasks: React.FC<Props> = ({ agentId, isAdmin }: Props) => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        Kiemelt figyelmet igénylő partnerek és feladatok
      </Typography>
      <Partners agentId={agentId} isAdmin={isAdmin} />
      <MyTasks agentId={agentId} isAdmin={isAdmin} />
      <Divider sx={{ my: 3 }} />
    </>
  );
};
