import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { Partner } from '../../../models/partners/Partner';
import { Product } from '../../../models/partners/Product';
import { useMemo } from 'react';
import { Box, Card, CardActionArea, CardMedia, Collapse, Fade, Grid, Modal, Theme, Typography } from '@mui/material';
import { useFormat } from '../../../utility/useFormat';

interface Props {
  partner: Partner;
}

export const Products: React.FC<Props> = ({ partner }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [image, setImage] = useState<string | undefined>(undefined);
  const { formatDate, formatCurrency } = useFormat();
  const oftenOrderedHeadCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Megnevezés',
      disablePadding: true,
      width: '50%',
    },
    {
      id: 'code',
      isNumeric: false,
      label: 'Cikkszám',
      disablePadding: true,
      width: '33%',
    },
    {
      id: 'point',
      isNumeric: true,
      label: 'Pont/Db',
      disablePadding: false,
      width: '17%',
    },
  ];
  const lastOrderedHeadCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Megnevezés',
      disablePadding: true,
      width: '60%',
    },
    {
      id: 'code',
      isNumeric: false,
      label: 'Cikkszám',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'point',
      isNumeric: true,
      label: 'Pont/Db',
      disablePadding: false,
      width: '10%',
    },
    {
      id: 'count',
      isNumeric: true,
      label: 'Darab',
      disablePadding: false,
      width: '10%',
    },
  ];
  const oftenOrderedBySimilarStoresCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Megnevezés',
      disablePadding: true,
      width: '50%',
    },
    {
      id: 'code',
      isNumeric: false,
      label: 'Cikkszám',
      disablePadding: true,
      width: '40%',
    },
    {
      id: 'point',
      isNumeric: true,
      label: 'Pont/Db',
      disablePadding: false,
      width: '10%',
    },
  ];

  const newProductsCells: TableHeaderCell[] = [
    {
      id: 'name',
      isNumeric: false,
      label: 'Megnevezés',
      disablePadding: true,
      width: '33%',
    },
    {
      id: 'code',
      isNumeric: false,
      label: 'Cikkszám',
      disablePadding: true,
      width: '33%',
    },
    {
      id: 'pic',
      isNumeric: false,
      label: '',
      disablePadding: false,
      width: '18%',
    },
    {
      id: 'point',
      isNumeric: true,
      label: 'Pont',
      disablePadding: false,
      width: '15%',
    },
  ];

  const oftenOrderedProducts: TableRowData[] = useMemo(
    () =>
      partner.oftenOrderedProducts.map((product: Product) => ({
        id: product.id,
        rowId: product.id,
        columns: [
          {
            id: 'name',
            cValue: product.name,
            value: product.name,
          },
          {
            id: 'code',
            cValue: product.code,
            value: product.code,
          },
          {
            id: 'point',
            cValue: product.point,
            value: product.point,
          },
        ],
      })),
    [partner.oftenOrderedProducts]
  );

  const lastOrderedProducts: TableRowData[] = useMemo(
    () =>
      partner.lastOrderedProducts.products.map((product: Product) => ({
        id: product.id,
        rowId: product.id,
        columns: [
          {
            id: 'name',
            cValue: product.name,
            value: product.name,
          },
          {
            id: 'code',
            cValue: product.code,
            value: product.code,
          },
          {
            id: 'point',
            cValue: product.point,
            value: product.point,
          },
          {
            id: 'count',
            cValue: product.count,
            value: product.count,
          },
        ],
      })),
    [partner.lastOrderedProducts]
  );

  const oftenOrderedBySimilarStores: TableRowData[] = useMemo(
    () =>
      partner.oftenOrderedBySimilarStores.map((product: Product) => ({
        id: product.id,
        rowId: product.id,
        columns: [
          {
            id: 'name',
            cValue: product.name,
            value: product.name,
          },
          {
            id: 'code',
            cValue: product.code,
            value: product.code,
          },
          {
            id: 'point',
            cValue: product.point,
            value: product.point,
          },
        ],
      })),
    [partner.oftenOrderedBySimilarStores]
  );
  const newProducts: TableRowData[] = useMemo(
    () =>
      partner.newProducts.map((product: Product) => ({
        id: product.id,
        rowId: product.id,
        columns: [
          {
            id: 'name',
            cValue: product.name,
            value: product.name,
          },
          {
            id: 'code',
            cValue: product.code,
            value: product.code,
          },
          {
            id: 'pic',
            value: (
              <Card sx={{ maxWidth: 80 }}>
                <CardActionArea>
                  <CardMedia
                    onClick={() => setImage(product.imageUrl)}
                    component="img"
                    sx={{ width: 80, minHeight: 80 }}
                    image={product.imageUrl}
                    loading="lazy"
                    alt={product.name}
                  />
                </CardActionArea>
              </Card>
            ),
          },
          {
            id: 'point',
            cValue: product.point,
            value: product.point,
          },
        ],
      })),
    [partner.newProducts]
  );
  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Termék ajánló
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Table
              id="lastOrderedProducts"
              title={`Utoljára rendelt termékek - ${formatDate(partner.lastOrderedProducts.date)} - ${formatCurrency(
                partner.lastOrderedProducts.total
              )}`}
              headCells={lastOrderedHeadCells}
              rows={lastOrderedProducts}
              hideOthersOnSelect={true}
              selected={undefined}
              setSelected={() => {}}
              hidePaper
              defaultPagination={{ page: 0, rowsPerPage: 25 }}
              filterByColumnId={['name', 'code']}
            />
          </Grid>
          <Grid item lg={4}>
            <Table
              id="oftenOrderedProducts"
              title="Korábbi saját vásárlásai alapján ajánljuk"
              headCells={oftenOrderedHeadCells}
              rows={oftenOrderedProducts}
              hideOthersOnSelect={true}
              selected={undefined}
              setSelected={() => {}}
              hidePaper
              defaultPagination={{ page: 0, rowsPerPage: 25 }}
              filterByColumnId={['name', 'code']}
            />
          </Grid>
          <Grid item lg={4}>
            <Table
              id="oftenOrderedBySimilarStores"
              title="Hasonló boltok vásárlásai alapján ajánljuk"
              headCells={oftenOrderedBySimilarStoresCells}
              rows={oftenOrderedBySimilarStores}
              hideOthersOnSelect={true}
              selected={undefined}
              setSelected={() => {}}
              hidePaper
              defaultPagination={{ page: 0, rowsPerPage: 25 }}
              filterByColumnId={['name', 'code']}
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              id="newProducts"
              title="Újdonságok"
              headCells={newProductsCells}
              rows={newProducts}
              hideOthersOnSelect={true}
              selected={undefined}
              setSelected={() => {}}
              hidePaper
              defaultPagination={{ page: 0, rowsPerPage: 10 }}
              filterByColumnId={['name', 'code']}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={image != null}
        onClose={() => setImage(undefined)}
        closeAfterTransition
      >
        <Fade in={image != null} timeout={500} style={{ outline: 'none' }}>
          <img src={image} alt="Zoomed" style={{ maxHeight: '90%', maxWidth: '90%' }} />
        </Fade>
      </Modal>
    </Box>
  );
};
