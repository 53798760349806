import React, { useRef, useState, useEffect } from 'react';
import { Box, Popover, TextField, Grid, IconButton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { SelectDial, SelectDialItem } from './SelectDial';

export interface TimeSelectorProps {
  variant?: 'standard' | 'outlined' | 'filled';
  size?: 'small' | 'medium';
  value: Date;
  onChange: (value: Date) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  showFormatWarning?: () => void;
  onblur?: () => void;
  ampm?: boolean;
}

export enum TimeSections {
  none = -1,
  hour = 0,
  minute = 1,
  period = 2,
}

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  variant,
  size,
  value,
  onChange,
  error,
  helperText,
  label,
  ampm,
  showFormatWarning,
  onblur,
}: TimeSelectorProps) => {
  value.setSeconds(0);
  value.setMilliseconds(0);

  let hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  if (!ampm) {
    hours = [...hours, '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  }

  const sectionListValues = [
    hours.map((txt) => {
      return { text: txt, value: parseInt(txt, 10) } as SelectDialItem;
    }),
    ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'].map((txt) => {
      return { text: txt, value: parseInt(txt, 10) } as SelectDialItem;
    }),
    ['AM', 'PM'].map((txt) => {
      return { text: txt, value: txt } as SelectDialItem;
    }),
  ];

  const tfRef = useRef<HTMLInputElement>(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [typedChars, setTypedChars] = useState('');
  const [isFocused, setFocused] = useState(false);
  const [selectedSection, setSelectedSection] = useState(TimeSections.none);
  const [lastSelectedSection, setLastSelectedSection] = useState(TimeSections.hour);
  const [lastChangeValue, setLastChangeValue] = useState(value.getTime());

  const [selectedHour, setSelectedHour] = React.useState<number>(() => {
    let h = value.getHours();
    if (ampm && h > 12) {
      h = h - 12;

      if (h === 0) h = 12;
    }
    return sectionListValues[0].findIndex((x) => x.value === h);
  });
  const [selectedMinute, setSelectedMinute] = React.useState<number>(() => {
    const m = value.getMinutes();
    return sectionListValues[1].findIndex((x) => x.value === m);
  });
  const [selectedPeriod, setSelectedPeriod] = React.useState<number>(() => {
    if (ampm) {
      const p = value.getHours() >= 12 ? 'PM' : 'AM';
      return sectionListValues[2].findIndex((x) => x.value === p);
    }
    return 0;
  });

  const sections = [
    {
      timeSection: TimeSections.hour,
      list: sectionListValues[0],
      stateValue: selectedHour,
      setStateValue: setSelectedHour,
    },
    {
      timeSection: TimeSections.minute,
      list: sectionListValues[1],
      stateValue: selectedMinute,
      setStateValue: setSelectedMinute,
    },
    {
      timeSection: TimeSections.period,
      list: sectionListValues[2],
      stateValue: selectedPeriod,
      setStateValue: setSelectedPeriod,
    },
  ];

  const onFocusHandler = () => {
    setFocused(true);
  };

  const onBlurHandler = (ev: any) => {
    applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false);

    setFocused(false);
    if (onblur) onblur();
  };

  const onSelectHandler = (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
    const el = ev.target;

    const newSection = getCursorSection(el);
    if (newSection === TimeSections.none) {
      selectSection(lastSelectedSection, el);
    } else {
      selectSection(newSection, el);
    }
  };

  const textFieldOnClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    const el: any = event.target;
    el.focus();
  };

  useEffect(() => {
    if (!isFocused) {
      sendOnChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const applyFieldValue = (
    section: TimeSections,
    newTypedChars: string,
    inputField: HTMLInputElement,
    moveNext: boolean,
    increment: number = 0
  ) => {
    const getIncrementedIndex = (list: SelectDialItem[], currentIndex: number, inc: number) => {
      const idx = currentIndex + inc;
      if (idx < 0) {
        return list.length - 1;
      }
      if (idx > list.length - 1) {
        return 0;
      }
      return idx;
    };

    const { list, setStateValue, stateValue } = sections[section];

    if (newTypedChars.length >= 1) {
      if (section === TimeSections.hour || section === TimeSections.minute) {
        const val = parseInt(newTypedChars, 10);
        let idx = list.findIndex((x) => x.value === val);

        if (idx === -1) {
          const searchList = [...list, { value: 999 } as SelectDialItem];
          for (let i = 0; i < searchList.length - 1; i += 1) {
            const currentValue =
              typeof searchList[i].value === 'string'
                ? parseInt(searchList[i].value as string)
                : (searchList[i].value as number);
            const nextValue =
              typeof searchList[i + 1].value === 'string'
                ? parseInt(searchList[i + 1].value as string)
                : (searchList[i + 1].value as number);

            if (currentValue < val && val < nextValue) {
              // closest value
              idx = val - (searchList[i].value as number) < (searchList[i + 1].value as number) - val ? i : i + 1;
              i += 999;
            }
          }

          if (showFormatWarning) showFormatWarning();
        }

        setStateValue(getIncrementedIndex(list, idx, increment));
        setTypedChars('');
        if (moveNext) {
          const newSection = ampm
            ? section === TimeSections.hour
              ? TimeSections.minute
              : TimeSections.period
            : section === TimeSections.hour
            ? TimeSections.minute
            : TimeSections.hour;
          selectSection(newSection, inputField);
          setLastSelectedSection(newSection);
        }
      }

      if (section === TimeSections.period) {
        let idx = list.findIndex((x) => x.text.toLowerCase() === newTypedChars.toLowerCase());
        if (idx === -1) {
          // approximate
          const indexP = newTypedChars.toLowerCase().indexOf('p');
          const indexA = newTypedChars.toLowerCase().indexOf('a');
          const val =
            indexA === indexP || // =-1
            indexP === -1 ||
            (indexA > -1 && indexA < indexP)
              ? 'am'
              : 'pm';
          idx = list.findIndex((x) => (x.text as string).toLowerCase() === val);

          if (showFormatWarning) showFormatWarning();
        }
        setStateValue(getIncrementedIndex(list, idx, increment));
        setTypedChars('');
      }
    } else {
      // without correction
      setStateValue(getIncrementedIndex(list, stateValue, increment));
    }
  };

  const onCloseHandler = () => {
    setPopupOpen(false);
    if (tfRef.current !== null) {
      tfRef.current.focus();
    }
    sendOnChange();
  };

  const onKeyDownHandler = (ev: React.KeyboardEvent) => {
    if ((ev.target as HTMLElement).nodeName.toLowerCase() === 'input') {
      const el = ev.target as HTMLInputElement;

      if (ev.keyCode === 38) {
        // up
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false, +1);
        ev.preventDefault();
      }

      if (ev.keyCode === 40) {
        // down
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false, -1);
        ev.preventDefault();
      }

      if (ev.keyCode === 39) {
        // right
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false);
        switchToNextSectionGroup(ev.target as HTMLInputElement);
        ev.preventDefault();
      }

      if (ev.keyCode === 37) {
        // left
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false);
        switchToNextSectionGroup(ev.target as HTMLInputElement, true);
        ev.preventDefault();
      }

      if (ev.keyCode === 32) {
        // space
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false);
        setPopupOpen(true);
        ev.preventDefault();
      }

      if (ev.keyCode === 27) {
        // esc
        setTypedChars('');
        setPopupOpen(false);
        ev.preventDefault();
      }

      if (ev.keyCode === 13) {
        // enter
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false);
        ev.preventDefault();
      }

      if (ev.keyCode === 9) {
        // tab
        applyFieldValue(selectedSection, typedChars, ev.target as HTMLInputElement, false);

        if (ev.shiftKey) {
          if (selectedSection !== TimeSections.hour && ev.preventDefault) {
            ev.preventDefault();
          }
          switchToNextSectionGroup(ev.target as HTMLInputElement, true);
        } else {
          if (selectedSection !== TimeSections.period && ev.preventDefault) {
            ev.preventDefault();
          }
          switchToNextSectionGroup(ev.target as HTMLInputElement);
        }
      }

      if ('0123456789apAPmM'.indexOf(ev.key) > -1) {
        if (el.selectionStart != null && el.selectionEnd != null) {
          const { timeSection: section } = sections[getCursorSection(el)];
          const newTypedChars = typedChars + ev.key.toLowerCase();

          if (
            ((section === TimeSections.hour || section === TimeSections.minute) && '0123456789'.indexOf(ev.key) > -1) ||
            (section === TimeSections.period && 'apAPmM'.indexOf(ev.key) > -1)
          ) {
            if (newTypedChars.length === 2) {
              applyFieldValue(section, newTypedChars, ev.target as HTMLInputElement, true);
            } else {
              setTypedChars(newTypedChars);
            }
          }
        }
      }
    }
  };

  const switchToNextSectionGroup = (inputElement: HTMLInputElement, reverse: boolean = false) => {
    let newSection: TimeSections;
    if (ampm) {
      if (reverse) {
        newSection = selectedSection === TimeSections.period ? TimeSections.minute : TimeSections.hour;
      } else {
        newSection = selectedSection === TimeSections.hour ? TimeSections.minute : TimeSections.period;
      }
    } else {
      newSection = selectedSection === TimeSections.hour ? TimeSections.minute : TimeSections.hour;
    }
    selectSection(newSection, inputElement);
    setLastSelectedSection(newSection);
  };

  const getCursorSection = (el: HTMLInputElement): TimeSections => {
    if (el && el.selectionStart !== null) {
      if (el.selectionStart < 2) {
        return TimeSections.hour;
      }
      if (el.selectionStart < 5) {
        return TimeSections.minute;
      }
      if (el.selectionStart < 8) {
        return ampm ? TimeSections.period : TimeSections.none;
      }
    }
    return TimeSections.none;
  };

  const selectSection = (section: TimeSections, el: HTMLInputElement) => {
    switch (section) {
      case TimeSections.hour:
        el.setSelectionRange(0, 2);
        break;
      case TimeSections.minute:
        el.setSelectionRange(3, 5);
        break;
      case TimeSections.period:
        el.setSelectionRange(6, 8);
        break;
      default:
    }
    setLastSelectedSection(selectedSection);
    setSelectedSection(section);
  };

  const sendOnChange = () => {
    const h = getValue(TimeSections.hour) as number;
    const m = getValue(TimeSections.minute) as number;

    if (ampm) {
      const p = getValue(TimeSections.period);
      if (h !== null && m !== null && p !== null) {
        if (h === 12) {
          value.setHours(`${p}` === 'PM' ? 12 : 0);
        } else {
          value.setHours(h + (`${p}` === 'PM' ? 12 : 0));
        }
        value.setMinutes(m);

        if (lastChangeValue !== value.getTime()) {
          onChange(value);
          setLastChangeValue(value.getTime());
        }
      }
    } else {
      value.setHours(h);
      value.setMinutes(m);
      if (lastChangeValue !== value.getTime()) {
        onChange(value);
        setLastChangeValue(value.getTime());
      }
    }
  };

  const getText = (section: TimeSections): string => {
    const { stateValue, list } = sections[section];

    if (stateValue === -1 || stateValue === null) {
      return '--';
    }
    return list[stateValue].text;
  };

  const getValue = (section: TimeSections): number | string | null => {
    const { stateValue, list } = sections[section];
    if (stateValue === -1 || stateValue === null) {
      return null;
    }
    return list[stateValue].value;
  };

  let inputDisplayValue = `${
    selectedSection === TimeSections.hour && typedChars.length === 1 ? `${typedChars} ` : getText(TimeSections.hour)
  }:${
    selectedSection === TimeSections.minute && typedChars.length === 1 ? `${typedChars} ` : getText(TimeSections.minute)
  }${
    ampm
      ? ` ${
          selectedSection === TimeSections.period && typedChars.length === 1
            ? `${typedChars} `
            : getText(TimeSections.period)
        }`
      : ''
  }`;

  if (!isFocused && inputDisplayValue === '12:00 AM') {
    inputDisplayValue = 'midnight';
  }

  return (
    <>
      <TextField
        id="standard-read-only-input"
        label={label}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton
              onClick={() => {
                setPopupOpen(true);
              }}
            >
              <AccessTimeIcon fontSize="small" />
            </IconButton>
          ),
        }}
        error={error}
        helperText={helperText}
        variant={variant}
        onClick={textFieldOnClickHandler}
        ref={tfRef}
        className="time-selector"
        value={inputDisplayValue}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onSelect={onSelectHandler}
        onKeyDown={onKeyDownHandler}
        size={size}
        fullWidth
      />

      {tfRef.current && (
        <Popover
          className="time-selector-popup"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isPopupOpen}
          anchorEl={tfRef.current}
          onClose={onCloseHandler}
        >
          <Box padding={2}>
            <Box textAlign="right" className="close">
              <IconButton onClick={onCloseHandler}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box className="dialers">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <SelectDial
                    className="hour-list"
                    items={sections[TimeSections.hour].list}
                    onValueChange={(item: SelectDialItem | null) => {
                      setSelectedHour(item ? sections[TimeSections.hour].list.indexOf(item) : -1);
                    }}
                    value={sections[TimeSections.hour].list[selectedHour]}
                  />
                </Grid>

                <Grid item className="hm-separator">
                  :
                </Grid>

                <Grid item>
                  <SelectDial
                    className="minute-list"
                    items={sections[TimeSections.minute].list}
                    onValueChange={(item: SelectDialItem | null) => {
                      setSelectedMinute(item ? sections[TimeSections.minute].list.indexOf(item) : -1);
                    }}
                    value={sections[TimeSections.minute].list[selectedMinute]}
                  />
                </Grid>
                {ampm && (
                  <Grid item>
                    <SelectDial
                      className="period-list"
                      items={sections[TimeSections.period].list}
                      onValueChange={(item: SelectDialItem | null) => {
                        setSelectedPeriod(item ? sections[TimeSections.period].list.indexOf(item) : -1);
                      }}
                      value={sections[TimeSections.period].list[selectedPeriod]}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Popover>
      )}
    </>
  );
};

export default TimeSelector;
