import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';

import { Salary } from '../../../models/admin/Salary';
import { setSalary, selectSalary } from '../../../slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { AdminInfoRequest } from '../../../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../../../models/admin/AdminInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { Loading } from '../../common/Loading';
import { Table, TableHeaderCell, TableRowData } from '../../common/Table';
import { useFormat } from '../../../utility/useFormat';
import { FormSelect } from '../../common/input/FormSelect';

interface MonthlySalaryValues {
  year: number;
  month: number;
}
const now = new Date();
now.setMonth(now.getMonth() - 1);
const currentMonth = now.getMonth() + 1;

const salaryCells: TableHeaderCell[] = [
  {
    id: 'agent',
    disablePadding: true,
    isNumeric: false,
    label: 'Értékesítő',
    width: '20%',
  },
  {
    id: 'baseSalary',
    disablePadding: true,
    isNumeric: true,
    label: 'Alapbér (Br - H)',
    width: '16%',
  },
  {
    id: 'achievedPoints',
    disablePadding: true,
    isNumeric: true,
    label: 'Elért pontok',
    width: '16%',
  },
  {
    id: 'bonus',
    disablePadding: true,
    isNumeric: true,
    label: 'Jutalék (Br - H)',
    width: '16%',
  },
  {
    id: 'deduction',
    disablePadding: true,
    isNumeric: true,
    label: 'Visszatérítési kompenzáció',
    width: '16%',
  },
  {
    id: 'totalBrut',
    disablePadding: true,
    isNumeric: true,
    label: 'Teljes bruttó (Br - H)',
    width: '16%',
  },
];

export const MonthlySalary: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formatNumber } = useFormat();
  let ref = useRef(null) as any;

  const salary: Salary[] | undefined | null = useSelector(selectSalary);

  const initialValues: MonthlySalaryValues = {
    year: now.getFullYear(),
    month: currentMonth,
  };

  const loadSalary = (year: number, month: number) => {
    dispatch(setSalary(null));
    setIsLoading(true);
    setIsInitialized(true);
    const access = new Access();
    const criteria: AdminInfoRequest = {
      getAgentsMonthlySalaries: { year, month },
    };
    access
      .adminInfo(criteria)
      .then((value: AdminInfoResponse | undefined) => {
        if (value?.error == null && value?.adminInfo?.agentsMonthlySalaries != null) {
          dispatch(setSalary(value.adminInfo.agentsMonthlySalaries));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (salary === undefined) {
      loadSalary(ref?.values?.year ?? initialValues.year, ref?.values?.month ?? initialValues.month);
    } else if (salary != null && !isInitialized) {
      dispatch(setSalary(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salary]);

  const handleSubmit = (value: MonthlySalaryValues) => {
    loadSalary(value.year, value.month);
  };

  const yearOptions = useMemo(() => {
    const options = [];
    const currentYear = now.getFullYear();
    for (let i = currentYear - 2; i <= currentYear; i++) {
      options.push({
        key: i,
        value: i,
      });
    }
    return options;
  }, []);

  const monthOptions = useMemo(() => {
    const options = [];
    let label = '';
    for (let i = 1; i <= 12; i++) {
      switch (i) {
        case 1:
          label = 'Január';
          break;
        case 2:
          label = 'Február';
          break;
        case 3:
          label = 'Március';
          break;
        case 4:
          label = 'Április';
          break;
        case 5:
          label = 'Május';
          break;
        case 6:
          label = 'Június';
          break;
        case 7:
          label = 'Július';
          break;
        case 8:
          label = 'Augusztus';
          break;
        case 9:
          label = 'Szeptember';
          break;
        case 10:
          label = 'Október';
          break;
        case 11:
          label = 'November';
          break;
        case 12:
          label = 'December';
          break;
      }
      options.push({
        key: i,
        value: label,
      });
    }
    return options;
  }, []);

  const salaryData = useMemo(() => {
    if (salary == null) return undefined;

    const data: TableRowData[] = [];
    let sumBaseSalary = 0,
      sumAchievedPoints = 0,
      sumBonus = 0,
      sumDeduction = 0,
      sumTotalBrut = 0;

    salary.forEach((s: Salary, index: number) => {
      sumBaseSalary += s.baseSalary;
      sumAchievedPoints += s.achievedPoints;
      sumBonus += s.bonus;
      sumDeduction += s.deduction;
      sumTotalBrut += s.totalBrut;
      data.push({
        id: index.toString(),
        rowId: index,
        columns: [
          {
            id: 'agent',
            value: s.label,
            cValue: s.label,
          },
          {
            id: 'baseSalary',
            value: formatNumber(s.baseSalary),
            cValue: s.baseSalary,
          },
          {
            id: 'achievedPoints',
            value: formatNumber(s.achievedPoints),
            cValue: s.achievedPoints,
          },
          {
            id: 'bonus',
            value: formatNumber(s.bonus),
            cValue: s.bonus,
          },
          {
            id: 'deduction',
            value: formatNumber(s.deduction),
            cValue: s.deduction,
          },
          {
            id: 'totalBrut',
            value: formatNumber(s.totalBrut),
            cValue: s.totalBrut,
          },
        ],
      });
    });
    data.push({
      id: 'total',
      rowId: 'total',
      columns: [
        {
          id: 'agent',
          value: 'Teljes',
        },
        {
          id: 'baseSalary',
          value: formatNumber(sumBaseSalary),
        },
        {
          id: 'achievedPoints',
          value: formatNumber(sumAchievedPoints),
        },
        {
          id: 'bonus',
          value: formatNumber(sumBonus),
        },
        {
          id: 'deduction',
          value: formatNumber(sumDeduction),
        },
        {
          id: 'totalBrut',
          value: formatNumber(sumTotalBrut),
        },
      ],
      sx: { '>td': { fontWeight: 'bold' } },
    });
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salary]);

  return (
    <Box mx={3} my={3}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={(instance) => {
          ref = instance;
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Bér
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: { xs: '100%', md: '40%', lg: '30%' },
              }}
            >
              <FormControl fullWidth sx={{ mt: 1, mr: 2 }}>
                <InputLabel id="year-label" sx={{ ml: -2 }}>
                  Év
                </InputLabel>
                <FormSelect labelId="year-label" name="year" id="year" variant="standard" onChange={submitForm}>
                  {(yearOptions ?? []).map((option) => (
                    <MenuItem value={option.key} key={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </FormSelect>
              </FormControl>
              -
              <FormControl fullWidth sx={{ mt: 1, ml: 2 }}>
                <InputLabel id="month-label" sx={{ ml: -2 }}>
                  Hónap
                </InputLabel>
                <FormSelect labelId="month-label" name="month" id="month" variant="standard" onChange={submitForm}>
                  {(monthOptions ?? []).map((option) => (
                    <MenuItem
                      value={option.key}
                      key={option.key}
                      disabled={values.year === yearOptions[0].key && option.key < currentMonth}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </FormSelect>
              </FormControl>
            </Box>
          </Form>
        )}
      </Formik>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {salaryData != null && (
            <Box mt={3}>
              <Table
                id="monthlySalary"
                headCells={salaryCells}
                rows={salaryData}
                hideOthersOnSelect={false}
                selected={undefined}
                setSelected={() => {}}
                hidePaper
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
