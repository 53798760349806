import React from 'react';
import { Checkbox as MCheckbox } from '@mui/material';
import { CheckboxProps as MCheckboxProps } from '@mui/material/Checkbox';
import {
  FieldInputProps,
  FieldMetaProps,
  FieldHelperProps,
  useField,
} from 'formik';

export interface CheckboxProps
  extends Omit<
  MCheckboxProps,
  'name' | 'value' | 'error' | 'form' | 'checked' | 'defaultChecked'
  > {
  name: string;
}

export function useFieldToCheckbox<Val = unknown>(
  { disabled, name, ...props }: CheckboxProps,
  customize?: (
    props: [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>]
  ) => Partial<Omit<CheckboxProps, 'name'>>
): MCheckboxProps {
  const fieldProps = useField(name);

  const [field] = fieldProps;

  return {
    disabled,
    ...props,
    ...field,
    checked: field.value,
    value: field.value ? 'checked' : '',
    ...(customize ? fieldProps : undefined),
  };
}

export function FormCheckbox(props: CheckboxProps) {
  return <MCheckbox {...useFieldToCheckbox(props)} />;
}
