import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { PartnersState } from '../models/slices/PartnersState';
import { PartnerSummary } from '../models/partners/PartnerSummary';
import { Partner } from '../models/partners/Partner';
import { AgentSummary } from '../models/common/AgentSummary';
import { LoadPartnerResponse } from '../models/partners/LoadPartnerResponse';
import { SavePartnerResponse } from '../models/partners/SavePartnerResponse';
import { ProductWidth } from '../models/common/ProductWidth';

const initialState: PartnersState = {
  partners: undefined,
  agents: undefined,
  partner: undefined,
  openGeneralInfo: undefined,
  promptEditor: undefined,
  reloadVisits: false,
  scrollToVisit: false,
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadPartnerResponse | undefined>) => {
      state.agents = action.payload?.agents;
      state.partners = action.payload?.partners;
    },
    setAgents: (state, action: PayloadAction<AgentSummary[] | undefined>) => {
      state.agents = action.payload;
    },
    setPartners: (state, action: PayloadAction<PartnerSummary[] | null | undefined>) => {
      state.partners = action.payload;
    },
    setPartner: (state, action: PayloadAction<Partner | null | undefined>) => {
      state.partner = action.payload;
    },
    updateState: (state, action: PayloadAction<SavePartnerResponse>) => {
      if (action.payload.partner) {
        state.partner = action.payload.partner;
      }
      if (action.payload.promptEditor) {
        state.promptEditor = action.payload.promptEditor;
      }
    },
    setOpenGeneralInfo: (state, action: PayloadAction<boolean | undefined>) => {
      state.openGeneralInfo = action.payload;
    },
    setReloadVisits: (state, action: PayloadAction<{ reload: boolean; scroll: boolean }>) => {
      state.reloadVisits = action.payload.reload;
      state.scrollToVisit = action.payload.scroll;
    },
    setProductWidth: (state, action: PayloadAction<ProductWidth | null | undefined>) => {
      if (state.partner?.statistics != null) {
        state.partner.statistics.productWidth = action.payload;
      }
    },
  },
});

export const {
  setPartner,
  setPartners,
  setResponse,
  setAgents,
  setOpenGeneralInfo,
  updateState,
  setReloadVisits,
  setProductWidth,
} = partnersSlice.actions;

export const selectPartners = (state: RootState) => state.localData.partners.partners;
export const selectAgents = (state: RootState) => state.localData.partners.agents;
export const selectPartner = (state: RootState) => state.localData.partners.partner;
export const selectOpenGeneralInfo = (state: RootState) => state.localData.partners.openGeneralInfo;
export const selectPromptEditor = (state: RootState) => state.localData.partners.promptEditor;
export const selectReloadVisits = (state: RootState): { reload: boolean; scroll: boolean } => ({
  reload: state.localData.partners.reloadVisits,
  scroll: state.localData.partners.scrollToVisit,
});

export default partnersSlice.reducer;
