import { useCallback, useState } from 'react';
import { Alert, Box, Collapse, IconButton, Typography, Skeleton } from '@mui/material';
import { FileDetails } from './FileDetails';
import { QueueListing, UploadStatus } from './QueueListing';
import UploadIcon from '@mui/icons-material/Upload';
import { useDropzone } from 'react-dropzone';
import { AddFile, FileConfiguration } from './FileConfiguration';

interface Props {
  acceptedFiles: string;
  disabled?: boolean;
  multiple?: boolean;
  uploadOnDrop?: boolean;
  helperText?: string;
  onDropFiles?: (files: AddFile[]) => void;
}

export const FileUpload = ({ acceptedFiles, disabled, multiple, uploadOnDrop, helperText, onDropFiles }: Props) => {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.new);
  const [hideList, setHideList] = useState(false);

  const onHideListHandler = () => {
    setFiles([]);
    if (onDropFiles) onDropFiles([]);
    setUploadStatus(UploadStatus.new);
    setHideList(true);
  };

  const openFilePicker = () => {
    if (!disabled) {
      open();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const files = acceptedFiles.map(
          (x: any) =>
            ({
              name: x.name,
              size: x.size,
              type: `${x.type}`,
              file: x,
            } as FileDetails)
        );
        // if (uploadOnDrop) onAddFiles(files);
        // else

        setFiles(files);

        if (onDropFiles) {
          const addActions = files.map((f) => FileConfiguration.fileDetailsToAddFileRequest(f));
          Promise.all(addActions)
            .then((result) => {
              if (result != null) {
                var results = result.filter((r) => r != null).map((r) => r!);
                onDropFiles(results);
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }

        setUploadStatus(UploadStatus.new);

        setHideList(false);
      }
    },
    [onDropFiles]
  );

  const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noDrag: disabled,
    onDrop,
    accept: acceptedFiles,
    multiple,
  });

  return (
    <>
      <Box>
        <Box
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
          })}
          sx={(theme) => ({
            border: isDragReject
              ? '2px dashed ' + theme.palette.error.main
              : isDragActive
              ? '2px dashed ' + theme.palette.success.main
              : '2px dashed #ddd',
            fontSize: '25px',
            padding: 15,
            backgroundColor: '#fafafa',
            textAlign: 'center',
            '& .uploadTextColor': {
              color: isDragReject ? theme.palette.error.light : isDragActive ? theme.palette.success.light : '#bbb',
            },
          })}
        >
          <input {...getInputProps()} />
          <IconButton
            size="large"
            sx={{ mt: 2 }}
            className="uploadTextColor"
            onClick={openFilePicker}
            disabled={disabled}
          >
            <UploadIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h5"
            sx={{ mb: 2, cursor: disabled ? 'default' : 'pointer' }}
            className="uploadTextColor"
            onClick={openFilePicker}
          >
            Kép feltöltése
          </Typography>
          {isDragReject && <Alert severity="error">At least one of the files is not in the accepted format</Alert>}
          <Collapse in={files.length > 0 && !hideList}>
            <Box>
              <QueueListing files={files} onHide={onHideListHandler} status={uploadStatus} />
            </Box>
          </Collapse>
          {!uploadOnDrop && files.length > 0 && (
            <>
              {helperText && (
                <Typography component={Box} variant="caption" sx={{ textAlign: 'left' }}>
                  {helperText}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export const MediaDropZoneSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={158} />
    </Box>
  );
};
