import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';

interface Props {
  text: string;
  maxLength: number;
  variant: 'body1' | 'caption';
  moreText?: string;
  lessText?: string;
}

export const ReadMore: React.FC<Props> = ({ text, maxLength, variant, moreText, lessText }: Props) => {
  const [showFullText, setShowFullText] = useState(false);

  let isFullText = true;
  let textToShow = text;

  if (text && text.length > maxLength) {
    textToShow = `${text.substring(0, maxLength)}... `;
    isFullText = false;
  }

  return (
    <>
      <Typography variant={variant}>
        {showFullText ? text : textToShow}
        {!isFullText && (
          <Button
            color="primary"
            size="small"
            onClick={() => setShowFullText(!showFullText)}
            sx={{ py: 0, minWidth: 'unset' }}
          >
            {showFullText ? lessText : moreText}
          </Button>
        )}
      </Typography>
    </>
  );
};

ReadMore.defaultProps = {
  lessText: 'kevesebb',
  moreText: 'több',
};
