import React, { useRef, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import clsx from 'clsx';

export interface SelectDialItem {
  value: number | string;
  text: string;
}

interface SelectDialItemView extends SelectDialItem {
  isExtra?: boolean;
}

export interface SelectDialProps {
  items: SelectDialItem[];
  className?: string;
  value?: SelectDialItem | null;
  onValueChange: (item: SelectDialItem | null) => void;
}

export const SelectDial: React.FC<SelectDialProps> = ({ items, value, className, onValueChange }: SelectDialProps) => {
  const taRef = useRef(null);
  const viewItems = [
    { isExtra: true, value: 'first', text: items[items.length - 1].text } as SelectDialItemView,
    ...items,
    { isExtra: true, value: 'last', text: items[0].text } as SelectDialItemView,
  ] as Array<SelectDialItemView>;
  const sel = viewItems.findIndex((el, idx) => idx > 0 && `${el.value}` === `${value?.value}`);
  const [selectedItemIdx, setSelectedIdx] = React.useState(sel);

  if (selectedItemIdx === 0) {
    onValueChange(null);
  }

  useEffect(() => {
    selectIndex(selectedItemIdx);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const increment = (val: number) => {
    let newVal = selectedItemIdx + val;
    if (newVal === 0) {
      newVal = viewItems.length - 2;
    } else if (newVal > viewItems.length - 2) {
      newVal = 1;
    }
    selectIndex(newVal);
  };

  const listKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.keyCode === 38) {
      // up
      increment(-1);
      ev.preventDefault();
      return false;
    }

    if (ev.keyCode === 40) {
      // down
      increment(1);
      ev.preventDefault();
      return false;
    }

    if (ev.keyCode === 32) {
      // space
      ev.preventDefault();
    }
  };

  const listClick = (ev: React.MouseEvent, index: number) => {
    selectIndex(index);
  };

  const selectIndex = (index: number) => {
    let newIndex = index;
    if (newIndex === 0) {
      newIndex = viewItems.length - 2;
    } else if (newIndex > viewItems.length - 2) {
      newIndex = 1;
    }

    setSelectedIdx(newIndex);
    onValueChange(viewItems[newIndex]);

    if (taRef !== null && taRef.current !== null) {
      const el: any = taRef.current;
      if (el) {
        el.scrollTop = (newIndex - 1) * 36;
        el.focus();
      }
    }
  };

  return (
    <Box
      className={clsx('select-dial-component', className, items.length <= 3 ? 'no-scroll' : '')}
      sx={{
        '&.no-scroll': {
          '& .list-item.extra': {
            visibility: 'hidden',
          },
          '& .scroll-button': {
            visibility: 'hidden',
          },
        },
      }}
    >
      <Button
        className="scroll-button"
        onClick={() => increment(-1)}
        onKeyDown={listKeyDown}
        onFocus={() => {
          (taRef.current as any).focus();
        }}
        sx={{
          height: 30,
          minWidth: '100%',
          '&.MuiButton-root': {
            padding: 0,
          },
        }}
        tabIndex={-1}
      >
        <KeyboardArrowUpIcon />
      </Button>

      <List
        sx={{
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          height: 108,
          p: 0,
          scrollSnapType: 'y mandatory',
          transition: 'scroll-snap-type 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '& .MuiListItem-button': {
            textAlign: 'center',
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
        }}
        component="nav"
        ref={taRef}
      >
        {viewItems.map((item, idx) => (
          <ListItem
            key={`item_${item.value}`}
            button
            selected={selectedItemIdx === idx}
            onClick={(ev: React.MouseEvent) => listClick(ev, idx)}
            onKeyDown={(ev: React.KeyboardEvent) => listKeyDown(ev)}
            dense
            className={clsx('list-item', item.isExtra ? 'extra' : '')}
            sx={{ height: 36, scrollSnapAlign: 'center' }}
            tabIndex={selectedItemIdx === idx ? 0 : -1}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Button className="scroll-button" onClick={() => increment(1)} onKeyDown={listKeyDown} tabIndex={-1}>
        <KeyboardArrowDownIcon />
      </Button>
    </Box>
  );
};

export default SelectDial;
