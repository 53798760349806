import { AxiosInstance } from 'axios';
import { axiosClientFactory } from './axiosClient';

export async function apiClient(): Promise<AxiosInstance> {
  const apiUrl = process.env.REACT_APP_API_URL;

  if (apiUrl == null) throw Error('Api Url is missing');

  return axiosClientFactory(apiUrl);
}
