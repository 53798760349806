import { FileDetails } from './FileDetails';
import { getFileContent } from './FileHelper';

export interface AddFile {
  size: number;
  contentType: string;
  content: string;
}

export interface FileConfigurationItem {
  typeName: string;
  isOfThisType: (fileType: string) => boolean;
  fileDetailsToAddFileRequest: (fileDetails: FileDetails) => Promise<AddFile>;
}

const fileConfigs: FileConfigurationItem[] = [
  {
    typeName: 'image',
    isOfThisType: (fileType: string) => {
      return fileType.startsWith('image/');
    },

    fileDetailsToAddFileRequest: async (fileDetails: FileDetails): Promise<AddFile> =>
      new Promise(function (resolve, reject) {
        getFileContent(fileDetails.file)
          .then(function (fileContentResult) {
            resolve({
              size: fileDetails.size,
              contentType: fileDetails.type,
              content: `data:${fileDetails.type};base64,${fileContentResult.content}`,
            });
          })
          .catch(function (err) {
            reject(err);
          });
      }),
  },
];

const getFileConfiguration = (fileType: string): FileConfigurationItem | undefined => {
  return fileConfigs.find((x) => x.isOfThisType(fileType));
};

const fileDetailsToAddFileRequest = (fileDetails: FileDetails): Promise<AddFile> | undefined => {
  const config = fileConfigs.find((x) => x.isOfThisType(fileDetails.type));
  if (config) {
    return config.fileDetailsToAddFileRequest(fileDetails);
  }
  return undefined;
};

export const FileConfiguration = {
  getFileConfiguration,
  fileDetailsToAddFileRequest,
};
