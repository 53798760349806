import { useEffect, useState } from 'react';
import { PartnerDistribution as PartnerDistributionModel } from '../../../models/myTarget/PartnerDistribution';
import { Grid, Box, Typography } from '@mui/material';

import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, ScatterController } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartnerDistribution, setPartnerDistribution } from '../../../slices/myTargetSlice';
import { MyTargetInfoRequest } from '../../../models/myTarget/MyTargetInfoRequest';
import { Access } from '../../../access/Access';
import { MyTargetInfoResponse } from '../../../models/myTarget/MyTargetInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Loading } from '../../common/Loading';
import { useFormat } from '../../../utility/useFormat';

interface Props {
  agentId: number;
}

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, ScatterController);

const partnerOption = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      text: 'Boltok száma és megoszlása',
      padding: { bottom: 20 },
    },

    datalabels: {
      labels: {
        title: {
          align: 'top' as any,
          anchor: 'end' as any,
          offset: 0,
          color: 'rgba(0, 0, 0, 0.87)',
          formatter: function (value: any, context: any) {
            if (context.datasetIndex === 4 && context.dataIndex < 3) {
              return context.dataset.data[3][context.dataIndex];
            }
            return null;
          },
        },
        value: {
          align: 'center' as any,
          formatter: function (value: any, context: any) {
            if (
              context.dataset.datalabels?.value != null &&
              context.dataset.datalabels.value[context.dataIndex] != null
            ) {
              return value > 0 ? `${value} (${context.dataset.datalabels?.value[context.dataIndex]}%)` : null;
            }
            return value;
          },
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const PartnerDistribution: React.FC<Props> = ({ agentId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { formatNumber } = useFormat();
  const partnerDistribution: PartnerDistributionModel | undefined | null = useSelector(selectPartnerDistribution);

  useEffect(() => {
    if (partnerDistribution === undefined) {
      dispatch(setPartnerDistribution(null));
      setIsLoading(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getPartnerDistribution: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.partnerDistribution != null) {
            dispatch(setPartnerDistribution(value.agentInfo.partnerDistribution));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, partnerDistribution]);

  let partnerData = null;
  if (partnerDistribution != null) {
    const labels = ['Jelenleg', 'Tavalyi Év', 'Referencia - Wolker - Jelenleg'];
    const sumCurrent12Months =
      partnerDistribution.current12Months.active +
      partnerDistribution.current12Months.reactivated +
      partnerDistribution.current12Months.attrition +
      partnerDistribution.current12Months.new +
      partnerDistribution.current12Months.underPerforming;
    const sumPrevious12Months =
      partnerDistribution.previous12Months.active +
      partnerDistribution.previous12Months.reactivated +
      partnerDistribution.previous12Months.attrition +
      partnerDistribution.previous12Months.new +
      partnerDistribution.previous12Months.underPerforming;
    const sumBenchmark12Months =
      partnerDistribution.benchmark12Months.active +
      partnerDistribution.benchmark12Months.reactivated +
      partnerDistribution.benchmark12Months.attrition +
      partnerDistribution.benchmark12Months.new +
      partnerDistribution.benchmark12Months.underPerforming;

    const current12ActivePercent = formatNumber(
      (partnerDistribution.current12Months.active * 100) / sumCurrent12Months,
      undefined,
      2
    );
    const previous12ActivePercent = formatNumber(
      (partnerDistribution.previous12Months.active * 100) / sumPrevious12Months,
      undefined,
      2
    );
    const benchmark12ActivePercent = formatNumber(
      (partnerDistribution.benchmark12Months.active * 100) / sumBenchmark12Months,
      undefined,
      2
    );

    const current12ReactivatedPercent = formatNumber(
      (partnerDistribution.current12Months.reactivated * 100) / sumCurrent12Months,
      undefined,
      2
    );
    const previous12ReactivatedPercent = formatNumber(
      (partnerDistribution.previous12Months.reactivated * 100) / sumPrevious12Months,
      undefined,
      2
    );
    const benchmark12ReactivatedPercent = formatNumber(
      (partnerDistribution.benchmark12Months.reactivated * 100) / sumBenchmark12Months,
      undefined,
      2
    );

    const current12AttritionPercent = formatNumber(
      (partnerDistribution.current12Months.attrition * 100) / sumCurrent12Months,
      undefined,
      2
    );
    const previous12AttritionPercent = formatNumber(
      (partnerDistribution.previous12Months.attrition * 100) / sumPrevious12Months,
      undefined,
      2
    );
    const benchmark12AttritionPercent = formatNumber(
      (partnerDistribution.benchmark12Months.attrition * 100) / sumBenchmark12Months,
      undefined,
      2
    );

    const current12UnderPerformingPercent = formatNumber(
      (partnerDistribution.current12Months.underPerforming * 100) / sumCurrent12Months,
      undefined,
      2
    );
    const previous12UnderPerformingPercent = formatNumber(
      (partnerDistribution.previous12Months.underPerforming * 100) / sumPrevious12Months,
      undefined,
      2
    );
    const benchmark12UnderPerformingPercent = formatNumber(
      (partnerDistribution.benchmark12Months.underPerforming * 100) / sumBenchmark12Months,
      undefined,
      2
    );

    const current12NewPercent = formatNumber(
      (partnerDistribution.current12Months.new * 100) / sumCurrent12Months,
      undefined,
      2
    );
    const previous12NewPercent = formatNumber(
      (partnerDistribution.previous12Months.new * 100) / sumPrevious12Months,
      undefined,
      2
    );
    const benchmark12NewPercent = formatNumber(
      (partnerDistribution.benchmark12Months.new * 100) / sumBenchmark12Months,
      undefined,
      2
    );

    partnerData = {
      labels,
      datasets: [
        {
          label: 'Aktív: Lemorzsolódás veszélyes',
          data: [
            partnerDistribution.current12Months.attrition,
            partnerDistribution.previous12Months.attrition,
            partnerDistribution.benchmark12Months.attrition,
          ],
          datalabels: {
            value: [current12AttritionPercent, previous12AttritionPercent, benchmark12AttritionPercent],
          } as any,
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Aktív: Alulteljesítő',
          data: [
            partnerDistribution.current12Months.underPerforming,
            partnerDistribution.previous12Months.underPerforming,
            partnerDistribution.benchmark12Months.underPerforming,
          ],
          datalabels: {
            value: [
              current12UnderPerformingPercent,
              previous12UnderPerformingPercent,
              benchmark12UnderPerformingPercent,
            ],
          } as any,
          backgroundColor: '#FFC235',
        },
        {
          label: 'Aktív: Normál',
          data: [
            partnerDistribution.current12Months.active,
            partnerDistribution.previous12Months.active,
            partnerDistribution.benchmark12Months.active,
          ],
          datalabels: {
            value: [current12ActivePercent, previous12ActivePercent, benchmark12ActivePercent],
          } as any,
          backgroundColor: 'rgb(53, 162, 235)',
        },
        {
          label: 'Újra aktivált',
          data: [
            partnerDistribution.current12Months.reactivated,
            partnerDistribution.previous12Months.reactivated,
            partnerDistribution.benchmark12Months.reactivated,
          ],
          datalabels: {
            value: [current12ReactivatedPercent, previous12ReactivatedPercent, benchmark12ReactivatedPercent],
          } as any,
          backgroundColor: 'rgb(75, 192, 192)',
        },
        {
          label: 'Új bolt',
          data: [
            partnerDistribution.current12Months.new,
            partnerDistribution.previous12Months.new,
            partnerDistribution.benchmark12Months.new,
            [sumCurrent12Months, sumPrevious12Months, sumBenchmark12Months],
          ],
          datalabels: {
            value: [current12NewPercent, previous12NewPercent, benchmark12NewPercent],
          } as any,
          backgroundColor: 'rgba(237, 125, 49, 0.9)',
        },
      ],
    };
  }

  return (
    <Grid item xs={12} md={6}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {partnerDistribution != null && partnerDistribution !== undefined && (
            <>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Boltok száma és megoszlása
              </Typography>
              <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
                <Bar options={partnerOption} data={partnerData!} plugins={[ChartDataLabels]} />
              </Box>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
