import { ReactNode } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useField } from 'formik';
import chroma from 'chroma-js';
//import './SelectFormField.scss';

interface Props {
  options: any[];
  isMulti?: boolean;
  name: string;
  id: string;
  setFieldValue: any;
  touched: any;
  errors: any;
  defaultValues: any;
  isSearchable?: boolean | true;
  isClearable?: boolean | false;
  isCreatable?: boolean | false;
  className?: string;
  onChange?: (value: any | any[]) => void;
  isLoading?: boolean;
  customStyles?: any;
  placeholder?: ReactNode;
}

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'white',
    minHeight: 40,
  }),
  option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
    const color = chroma('#000000');
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? '#000000' : isFocused ? color.alpha(0.1).css() : null,
      color: isDisabled ? '#ccc' : isSelected ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : '#000000',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        color: 'white',
        backgroundColor: !isDisabled && (isSelected ? '#5e5d5d' : color.css()),
      },
    };
  },
  multiValue: (styles: any) => {
    const color = chroma('#000000');
    return {
      ...styles,
      backgroundColor: color.css(),
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#ffffff',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#000000',
      color: 'white',
    },
  }),
  menu: (styles: any) => ({ ...styles, zIndex: 99999 }),
};

export const FormSelectField = ({
  setFieldValue,
  options,
  isMulti = false,
  name,
  id,
  touched,
  errors,
  isSearchable,
  isClearable,
  isCreatable,
  className,
  onChange,
  isLoading,
  customStyles,
  placeholder,
}: Props) => {
  const [field] = useField(name);
  const onChangeHandler = (option: any, obj: any) => {
    const { action, removedValue } = obj;
    if (action === 'clear') {
      const newValue = isMulti ? [] : null;
      setFieldValue(field.name, newValue);

      if (onChange) onChange(newValue);
    }
    if (action === 'remove-value') {
      setFieldValue(field.name, isMulti ? field.value.filter((x: any) => x !== removedValue.value) : null);
    }
    if (action === 'select-option') {
      const value = isMulti ? (option as any[]).map((item: any) => item.value) : (option as any).value;

      setFieldValue(field.name, value);

      if (onChange) onChange(value);
    }

    if (action === 'create-option') {
      const value = isMulti ? (option as any[]).map((item: any) => item.value) : (option as any).value;

      setFieldValue(field.name, value);

      if (onChange) onChange(value);
    }
    return true;
  };
  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value) ?? null;
    }
    return isMulti ? [] : ('' as any);
  };

  return (
    <>
      {isCreatable ? (
        <CreatableSelect
          className={touched[field.name] && errors[field.name] ? `${className} selectError` : className}
          name={field.name}
          id={id}
          onBlur={field.onBlur}
          value={getValue()}
          onChange={onChangeHandler}
          styles={{ ...colourStyles, ...customStyles }}
          options={options}
          isMulti={isMulti}
          isSearchable={isSearchable}
          isClearable={isClearable}
          classNamePrefix="react-select"
          isLoading={isLoading}
          placeholder={placeholder}
        />
      ) : (
        <Select
          className={touched[field.name] && errors[field.name] ? `${className} selectError` : className}
          name={field.name}
          id={id}
          onBlur={field.onBlur}
          value={getValue()}
          onChange={onChangeHandler}
          styles={{ ...colourStyles, ...customStyles }}
          options={options}
          isMulti={isMulti}
          isSearchable={isSearchable}
          isClearable={isClearable}
          classNamePrefix="react-select"
          isLoading={isLoading}
          placeholder={placeholder}
        />
      )}
      {touched[field.name] && errors[field.name] && (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ color: 'red' }}>
          {errors[field.name]}
        </p>
      )}
    </>
  );
};
