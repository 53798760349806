import { useMemo } from 'react';
import { PerformanceReference as PerformanceReferenceModel } from '../../../models/common/PerformanceReference';
import { Grid, Box, Typography } from '@mui/material';

import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, ScatterController } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useFormat } from '../../../utility/useFormat';
import { Info } from '../Info';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Module } from '../../../models/Module';

interface Props {
  data: PerformanceReferenceModel[];
  agentId?: number;
  module: Module;
}

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, ScatterController);

const median = (values: number[]): number => {
  if (values.length === 0) {
    throw new Error('Input array is empty');
  }

  const sorted = [...values].sort((a, b) => a - b);

  const half = Math.floor(sorted.length / 2);

  return sorted.length % 2 ? sorted[half] : (sorted[half - 1] + sorted[half]) / 2;
};

export const PerformanceReference: React.FC<Props> = ({ data, agentId, module }: Props) => {
  const { formatNumber } = useFormat();

  const scatterData = useMemo(() => {
    let options = null;

    let maxX = 0;
    let minX = Number.MAX_SAFE_INTEGER;
    let maxY = 0;
    let minY = Number.MAX_SAFE_INTEGER;
    let sumX = 0;
    let sumY = 0;
    const y: number[] = [];

    const scatterData: { x: number; y: number; label: string }[] = [];
    const agentData: { x: number; y: number; label: string }[] = [];
    data.forEach((p: PerformanceReferenceModel) => {
      sumX += p.dynamicGrowth;
      sumY += p.achievedPoints;
      if (p.dynamicGrowth > maxX) maxX = p.dynamicGrowth;
      if (p.dynamicGrowth < minX) minX = p.dynamicGrowth;
      if (p.achievedPoints > maxY) maxY = p.achievedPoints;
      if (p.achievedPoints < minY) minY = p.achievedPoints;

      if (p.agentId === agentId) {
        agentData.push({ x: p.dynamicGrowth, y: p.achievedPoints, label: p.label ?? '' });
      } else {
        scatterData.push({ x: p.dynamicGrowth, y: p.achievedPoints, label: p.label ?? '' });
      }
      y.push(p.achievedPoints);
    });

    const startX = minX - Math.floor(maxX * 0.2),
      endX = maxX * 1.2,
      avgX = sumX / data.length,
      avgY = sumY / data.length,
      startY = -1 * (avgY / avgX) * startX,
      endY = -1 * (avgY / avgX) * endX,
      med = y.length > 0 ? median(y) : 0;

    const greenLineStartY = startY + med * 1.8;
    const redLineStartY = startY + med * 0.2;
    const greenLineEndY = endY + med * 1.8;
    const redLineEndY = endY + med * 0.2;

    const chartData = {
      minX,
      maxX,
      minY,
      maxY,
      datasets: [
        {
          type: 'line' as const,
          label: 'MidleX',
          borderColor: 'rgba(0, 0, 0, 0.2)',
          borderWidth: 2,
          fill: false,
          data: [
            { x: minX - Math.floor(maxX * 0.2), y: (minY + maxY) / 2 },
            { x: maxX * 1.2, y: (minY + maxY) / 2 },
          ],
        },
        {
          type: 'line' as const,
          label: 'MidleY',
          borderColor: 'rgba(0, 0, 0, 0.2)',
          borderWidth: 2,
          fill: false,
          data: [
            { y: -100000000, x: 0 },
            { y: maxY * 1.3, x: 0 },
          ],
        },
        {
          type: 'line' as const,
          label: 'Kiemelkedő',
          borderColor: 'rgb(44, 216, 41)',
          borderWidth: 2,
          fill: false,
          data: [
            { x: startX, y: greenLineStartY },
            { x: endX, y: greenLineEndY },
          ],
        },

        {
          type: 'line' as const,
          label: 'Gyenge',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: [
            { x: startX, y: redLineStartY },
            { x: endX, y: redLineEndY },
          ],
        },

        {
          type: 'scatter' as const,
          data: scatterData as any,
          backgroundColor: 'rgba(175, 171, 171, 0.9)',
        },
        {
          type: 'scatter' as const,
          data: agentData as any,
          backgroundColor: 'rgba(237, 125, 49, 0.9)',
        },
      ],
    };

    options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          labels: {
            title: {
              align: 'top' as any,
              anchor: 'end' as any,
              offset: 0,
              color: '#000',
              formatter: (value: any, context: any) => (module === Module.admin && value.label ? value.label : null),
            },
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const labels: string[] = [];
              const name = context.dataset.data[context.dataIndex]?.label;
              const dynamic = context.dataset.data[context.dataIndex]?.x;
              const achieved = context.dataset.data[context.dataIndex]?.y;
              if (name) labels.push(name);
              if (dynamic != null) labels.push(`Dinamika: ${formatNumber(dynamic)}`);
              if (achieved != null) labels.push(`Elért pontok: ${formatNumber(achieved)}`);

              return labels;
            },
          },
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Elért pontok',
          },
          min: minY - Math.floor(chartData.maxY * 0.1),
          max: Math.floor(chartData.maxY * 1.2),
          ticks: {
            sampleSize: 100,
          },
        },
        x: {
          title: {
            display: true,
            text: 'Dinamika (százalék)',
          },
          min: chartData.minX - Math.floor(chartData.maxX * 0.1),
          max: Math.floor(chartData.maxX * 1.1),
        },
      },
    };

    return { options, chartData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, agentId, module]);

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Időarányos növekedés és pont eredmény
        <Info
          text={`Elért pontok: Az idei évben szerzett pontok
Dinamika (százalék): Az idei évben szerzett pontok változása
(növekmény vagy csökkenés) a tavalyi év azonos időszakában szerzett pontokhoz képest.`}
        />
      </Typography>
      <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
        <Chart
          type="scatter"
          options={{
            ...scatterData.options,
            plugins: {
              ...scatterData.options!.plugins,
              title: {
                display: true,
                text: 'Időarányos növekedés és pont eredmény',
              },
            },
          }}
          data={scatterData.chartData}
          plugins={module === Module.admin ? [ChartDataLabels] : undefined}
        />
      </Box>
    </Grid>
  );
};
