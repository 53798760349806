import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import { LayoutReducerActions } from "modules/office/actions/LayoutReducer";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IdleDetector } from './IdleDetector';
import { MessageHandler } from './MessageHandler';
import ErrorBoundary from './ErrorBoundary';
import { Footer } from './Footer';
import { Avatar, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { AppRoute, UserType, routes } from '../../router/AppRoutes';
import { Logo2 } from '../../assets/Logo2';
import { AuthenticationGuard } from './AuthenticationGuard';
import { isAdminRole, selectUserProfile, setUserProfile } from '../../slices/userSlice';
import { securityClient } from '../../security/securityClient';
// import { Dev } from './Dev';

const drawerWidth = 240;

interface Props {
  children: any;
}

export const Layout = (props: Props) => {
  const { children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const isAdmin = useSelector(isAdminRole);

  const [openUserMenu, setOpenUserMenu] = useState(false);
  const userMenuRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const title = 'Ügynök Portál';

  const profilePictureUrl: string = '';

  const { firstName, lastName } = useSelector(selectUserProfile);

  const user = firstName
    ? {
        firstName,
        lastName,
      }
    : undefined;

  const toggleUserMenu = () => {
    setOpenUserMenu((prevOpen) => !prevOpen);
  };

  const closeUserMenu = (event: MouseEvent | TouchEvent) => {
    if (userMenuRef.current && userMenuRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenUserMenu(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenUserMenu(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();
  const currentPath = location.pathname.toLocaleLowerCase().replace(/\/$/, '');

  const activeRoutes = useMemo(() => {
    if (isAdmin == null) return routes.filter((r) => r.roles == null);
    const userRole = isAdmin ? UserType.admin : UserType.agent;
    return routes.filter((r) => r.roles == null || r.roles.some((r) => r === userRole));
  }, [isAdmin]);

  const activeRoute = useMemo(() => {
    return routes.find((n: AppRoute) => {
      const dots = n.path.indexOf(':');

      const path = dots > -1 ? n.path.substring(0, dots) : n.path;
      const current = dots > -1 ? currentPath.substring(0, dots - 1) : currentPath;

      return path.toLowerCase().replace(/\/$/, '') === current;
    });
  }, [currentPath]);

  const isPublicPage = activeRoute?.isPublic ?? false;
  const hideMenu = activeRoute?.hideMenu ?? false;
  const description = activeRoute?.name ?? '';

  const logOut = () => {
    dispatch(setUserProfile({ email: '', firstName: '', lastName: '', role: '' }));
    securityClient.signOut();
  };

  const drawer = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
      <Box>
        <Toolbar>
          <Box sx={{ width: '100%' }}>
            <Logo2 style={{ height: 46, width: 112 }} />
          </Box>
        </Toolbar>
        <Divider />
        <List className="navigation">
          {activeRoutes
            .filter((route) => route.showInMenu)
            .map(({ name, icon, path, menu }) => (
              <ListItemButton
                key={name}
                alignItems="center"
                selected={activeRoute?.menu === menu}
                component={NavLink}
                onClick={() => setMobileOpen(false)}
                to={path}
                sx={(theme) => ({
                  marginLeft: theme.spacing(1),
                  minWidth: theme.spacing(6),
                  fill: 'currentColor',
                })}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            ))}
        </List>
      </Box>
      <Box p={1}>
        <Footer />
      </Box>
    </Box>
  );

  const renderLayout = () => (
    <Box sx={{ display: 'flex' }}>
      {!hideMenu && (
        <>
          <AppBar
            position="fixed"
            sx={(theme) => ({
              width: { lg: `calc(100% - ${drawerWidth}px)` },
              ml: { lg: `${drawerWidth}px` },
              color: theme.layout.appBar.color,
              backgroundColor: theme.layout.appBar.backgroundColor,
            })}
          >
            {/* <Dev /> */}
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={(theme) => ({
                  mr: 2,
                  display: { lg: 'none' },
                  '& svg': { color: theme.layout.appBar.color },
                })}
              >
                <MenuIcon />
              </IconButton>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" noWrap component="div">
                  {title}
                  {description ? ` - ${description}` : ''}
                </Typography>
                {user !== null && (
                  <>
                    <Box display="flex" alignItems="center" paddingRight={1}>
                      <Button
                        ref={userMenuRef}
                        aria-controls={openUserMenu ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={toggleUserMenu}
                      >
                        <Typography
                          variant="body1"
                          sx={(theme) => ({
                            marginRight: theme.spacing(1),
                            fontSize: '0.875rem',
                            display: { xs: 'none', md: 'block' },
                            color: theme.layout.appBar.color,
                          })}
                        >
                          {lastName ?? ''} {firstName ?? ''}
                        </Typography>
                        <Avatar src={profilePictureUrl}>{`${lastName && lastName.length > 0 ? lastName[0] : ''}${
                          firstName && firstName.length > 0 ? firstName[0] : ''
                        }`}</Avatar>
                      </Button>
                    </Box>

                    <Popper
                      open={openUserMenu}
                      anchorEl={userMenuRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      placement="bottom-end"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={closeUserMenu}>
                              <MenuList autoFocusItem={openUserMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem
                                  onClick={() => {
                                    setOpenUserMenu(false);
                                  }}
                                  component={NavLink}
                                  to="my-account"
                                >
                                  Profilom
                                </MenuItem>

                                <MenuItem onClick={logOut}>Kijelentkezés</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                )}
              </Box>
            </Toolbar>
          </AppBar>
          <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', lg: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          maxWidth: '100%',
        }}
      >
        <Toolbar />
        <MessageHandler />
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
    </Box>
  );

  return isPublicPage === true ? (
    renderLayout()
  ) : (
    <AuthenticationGuard routes={activeRoutes} activeRoute={activeRoute}>
      <IdleDetector idleTime={60 * 50} promptTime={60 * 10} />
      {renderLayout()}
    </AuthenticationGuard>
  );
};
