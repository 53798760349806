export const scrollToElement = (selector: string, delay = 0, yOffset = 0) => {
  setTimeout(() => {
    const element = document.querySelector(selector);

    if (element) {
      // When there is an input, scroll this input into view.
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, delay);
};

export const scrollToBottom = (delay = 0) => {
  setTimeout(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, delay);
};
