import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserProfile } from '../models/UserProfile';
import { RootState } from '../store/store';

const initialState: UserProfile = {
  email: '',
  firstName: '',
  lastName: '',
  role: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      //state = { ...state, ...action.payload };
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.role = action.payload.role;
    },
  },
});

export const { setUserProfile } = userSlice.actions;

export const selectUserProfile = (state: RootState) => state.localData.user;
export const isAdminRole = (state: RootState) => state.localData.user.role === 'Admin';

export default userSlice.reducer;
