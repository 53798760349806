import { Visit } from '../../../models/visits/Visit';
import { Table, TableHeaderCell, TableRowData } from '../Table';
import { useFormat } from '../../../utility/useFormat';
import { ReadMore } from '../ReadMore';
import { Alert, Box, Button, Collapse, Fade, IconButton, Modal, Theme, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { EditVisit } from './EditVisit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { Access } from '../../../access/Access';
import { LoadVisitsResponse } from '../../../models/visits/LoadVisitsResponse';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slices/applicationSlice';
import { LoadVisitsRequest } from '../../../models/visits/LoadVisitsRequest';
import { Form, Formik } from 'formik';
import { FormDateRangePicker } from '../input/FormDateRangePicker';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { PartnerStatus } from '../../../models/partners/PartnerStatus';
import { scrollToElement } from '../../../utility/scroll';
import PhotoIcon from '@mui/icons-material/Photo';
import { Loading } from '../Loading';

interface Props {
  id: string;
  partnerId: number | undefined;
  agentId: number | undefined;
  isAdmin: boolean;
  reloadVisits?: { reload: boolean; scroll: boolean };
  setReloadVisits?: (value: boolean) => void;
  from: Date;
  to: Date;
}

interface VisitFilterValues {
  from: Date;
  to: Date;
}

export const Visits: React.FC<Props> = ({
  id,
  partnerId,
  agentId,
  isAdmin,
  reloadVisits,
  setReloadVisits,
  from,
  to,
}: Props) => {
  const { formatDate } = useFormat();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visits, setVisits] = useState<Visit[] | undefined | null>(undefined);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [selectedVisit, setSelectedVisit] = useState<Visit | undefined>(undefined);
  const [image, setImage] = useState<string | undefined>(undefined);
  let ref = useRef(null) as any;

  const handleCreateVisit = (): void => {
    if (partnerId != null) {
      setSelectedVisit({
        id: 0,
        date: new Date(),
        from: '09:00',
        to: '10:00',
        notes: '',
        adminNotes: '',
        partner: {
          id: partnerId,
          name: '',
          agentId: agentId ?? 0,
        },
      });
    }
  };

  const loadVisits = (from: Date, to: Date) => {
    setVisits(null);
    const access = new Access();
    const criteria: LoadVisitsRequest = {
      partnerId,
      from: formatDate(from, 'YYYY-MM-DDT00:00:00.000[Z]'),
      to: formatDate(to, 'YYYY-MM-DDT23:59:59.000[Z]'),
    };

    if (partnerId == null) criteria.agentId = agentId;

    access
      .loadVisits(criteria)
      .then((value: LoadVisitsResponse | undefined) => {
        if (value?.error == null && value?.visits != null) {
          setVisits(value.visits);
          if (reloadVisits?.scroll) {
            scrollToElement('#visit-section');
          }
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
          setVisits([]);
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
        setVisits([]);
      });
  };

  const initialValues: VisitFilterValues = {
    from,
    to,
  };
  useEffect(() => {
    if (visits === undefined || reloadVisits?.reload === true) {
      loadVisits(ref?.values?.from ?? initialValues.from, ref?.values?.to ?? initialValues.to);
      if (reloadVisits?.reload === true && setReloadVisits) setReloadVisits(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visits, reloadVisits?.reload]);

  const handleEditVisit = (visit: Visit | undefined): void =>
    setSelectedVisit(
      visit == null ? undefined : { ...visit, partner: { id: partnerId ?? 0, name: '', agentId: agentId ?? 0 } }
    );

  const handleCancel = (): void => handleEditVisit(undefined);

  const handleEdited = (): void => {
    handleEditVisit(undefined);
    if (setReloadVisits) setReloadVisits(true);
  };

  const openPhoto = (visit: Visit): void => {
    setImage(visit.photoUrl);
  };

  const headCells: TableHeaderCell[] = [
    {
      id: 'date',
      isNumeric: false,
      label: 'Dátum',
      disablePadding: false,
      width: partnerId != null ? '30%' : '10%',
    },
  ];

  if (partnerId == null) {
    headCells.push({
      id: 'partner',
      isNumeric: false,
      label: 'Partner',
      disablePadding: false,
      width: '10%',
    });
  }

  headCells.push({
    id: 'notes',
    isNumeric: false,
    label: 'Megjegyzés',
    disablePadding: false,
    width: '30%',
  });
  headCells.push({
    id: 'admin-notes',
    isNumeric: false,
    label: 'Megjegyzés (Admin)',
    disablePadding: false,
    width: '30%',
  });

  headCells.push({
    id: 'operation',
    isNumeric: false,
    label: '',
    disablePadding: false,
    width: '10%',
  });

  const openPartner = (id: number, agentId: number | string | undefined): void => {
    const queryString = isAdmin && agentId ? `?agent=${agentId}` : '';
    navigate(`/partners/${id}${queryString}`);
  };

  const rows: TableRowData[] = useMemo(() => {
    if (visits == null) return [];
    return visits.map((visit: Visit) => {
      const value: TableRowData = {
        id: visit.id,
        rowId: visit.id,
        plusId: visit.partner?.id,
        extraId: visit.partner?.agentId,
        columns: [
          {
            id: 'date',
            cValue: visit.date,
            value: (
              <>
                {formatDate(visit.date)}
                <Typography variant="caption" component="div">
                  {visit.from} - {visit.to}
                </Typography>
              </>
            ),
          },
        ],
      };

      if (partnerId == null) {
        value.columns.push({
          id: 'partner',
          cValue: visit.partner?.name ?? '',
          value: visit.partner ? (
            <>
              {visit.partner.status === PartnerStatus.NewTarget ||
              visit.partner.status === PartnerStatus.WebRegistered ? (
                <Typography component="div" variant="body2" sx={{ pl: 1 }}>
                  {visit.partner.name}
                </Typography>
              ) : (
                <Button onClick={() => openPartner(visit.partner!.id, visit.partner!.agentId)}>
                  {visit.partner.name}
                </Button>
              )}
            </>
          ) : (
            ''
          ),
        });
      }

      value.columns.push({
        id: 'notes',
        cValue: visit.notes,
        value: <ReadMore text={visit.notes} maxLength={100} variant="caption" />,
      });
      value.columns.push({
        id: 'adminNotes',
        cValue: visit.notes,
        value: visit.adminNotes ? <ReadMore text={visit.adminNotes} maxLength={100} variant="caption" /> : '',
      });

      value.columns.push({
        id: 'operation',
        cValue: '',
        value: (
          <>
            {isAdmin && (
              <IconButton onClick={() => handleEditVisit(visit)}>
                <EditOutlinedIcon />
              </IconButton>
            )}
            {visit.photoUrl && (
              <IconButton onClick={() => openPhoto(visit)}>
                <PhotoIcon />
              </IconButton>
            )}
          </>
        ),
      });

      return value;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visits, isAdmin]);

  const handleSubmit = (values: VisitFilterValues) => {
    loadVisits(values.from, values.to);
  };

  return (
    <Box sx={{ mt: 2 }} id="visit-section">
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Látogatások
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>

      <Collapse in={!isCollapsed}>
        {partnerId != null && (
          <Button
            variant="contained"
            sx={{ my: 2 }}
            color="primary"
            onClick={handleCreateVisit}
            startIcon={<LocationOnIcon sx={(theme: Theme) => ({ fill: '#fff' })} />}
          >
            Meglátogatom
          </Button>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={(instance) => {
            ref = instance;
          }}
        >
          {({ values }) => (
            <Form>
              <FormDateRangePicker nameFrom="from" nameTo="to" popOver={true} onClose={() => handleSubmit(values)} />
            </Form>
          )}
        </Formik>

        {visits == null ? (
          <Loading />
        ) : (
          <>
            {rows.length > 0 ? (
              <Table
                id={`${id}_partner_${partnerId}_agent_${agentId}`}
                title=""
                headCells={headCells}
                rows={rows}
                hideOthersOnSelect={false}
                selected={undefined}
                setSelected={() => {}}
                hidePaper
                storeState
                defaultPagination={{ page: 0, order: 'desc', orderBy: 'date', rowsPerPage: 5 }}
                filterByColumnId={['partner', 'notes', 'adminNotes']}
              />
            ) : (
              <Alert severity="info" sx={{ my: 2 }} variant="standard">
                Nincsenek előző látogatások
              </Alert>
            )}
          </>
        )}
      </Collapse>

      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={image != null}
        onClose={() => setImage(undefined)}
        closeAfterTransition
      >
        <Fade in={image != null} timeout={500} style={{ outline: 'none' }}>
          <img src={image} alt="Zoomed" style={{ maxHeight: '90%', maxWidth: '90%' }} />
        </Fade>
      </Modal>
      {selectedVisit && (
        <EditVisit visit={selectedVisit} onCancel={handleCancel} onEdited={handleEdited} isAdmin={isAdmin} />
      )}
    </Box>
  );
};
