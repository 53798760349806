import { Box, Button, LinearProgress, SwipeableDrawer, Typography } from '@mui/material';
import { TaskStatus } from '../../../../models/myTarget/TaskStatus';

import { Task } from '../../../../models/myTarget/Task';
import { Form, Formik } from 'formik';
import { FormTextField } from '../../../common/input/FormTextField';
import { TaskValues } from './Tasks';
import * as Yup from 'yup';

interface Props {
  data: Task;
  isAdmin: boolean;
  onClose: () => void;
  onSave: (values: TaskValues) => void;
}

export const EditTask: React.FC<Props> = ({ data, isAdmin, onClose, onSave }: Props) => {
  const initialValues: TaskValues = {
    id: data.id,
    name: data.name ?? '',
    description: data.description ?? '',
    status: data.status,
    notes: data.notes ?? '',
    adminNotes: data.adminNotes ?? '',
  };

  const getTitle = (): string => {
    switch (data.status) {
      case TaskStatus.inProgress:
        return 'Feladat befejezése';
      case TaskStatus.done:
        return 'Feladat elfogadása';
      case undefined:
        return 'Új feladat hozzáadása';
      default:
        return '';
    }
  };

  const handleSubmit = (value: TaskValues): void => onSave(value);
  const validationSchema = Yup.object({
    name: Yup.string().when('status', (values, schema) => {
      const [status] = values;
      if (status == null) return schema.required('Kérem adja meg a nevet');
      return schema;
    }),
    description: Yup.string().when('status', (values, schema) => {
      const [status] = values;
      if (status == null) return schema.required('Kérem adja meg a leírást');
      return schema;
    }),
    notes: Yup.string().when('status', (values, schema) => {
      const [status] = values;
      if (status === TaskStatus.inProgress) return schema.required('Kérem adja meg az eredményt');
      return schema;
    }),
    adminNotes: Yup.string().when('status', (values, schema) => {
      const [status] = values;
      if (status === TaskStatus.done) return schema.required('Kérem adja meg visszajelzését');
      return schema;
    }),
  });

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 400 }, p: 3 }}>
        <Typography variant="h5">{getTitle()}</Typography>
        <Box sx={{ pt: 4 }}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, errors, values, touched }) => (
              <>
                {isSubmitting && (
                  <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Form>
                  {data.status === TaskStatus.inProgress && (
                    <Box pb={2} pl={2}>
                      <FormTextField
                        size="small"
                        label="Eredmény leírása"
                        name="notes"
                        id="notes"
                        fullWidth
                        multiline
                      />
                    </Box>
                  )}
                  {isAdmin && (
                    <>
                      {data.status == null && (
                        <>
                          <Box pb={2}>
                            <FormTextField size="small" label="Név" name="name" id="name" fullWidth />
                          </Box>
                          <Box pb={2}>
                            <FormTextField
                              size="small"
                              label="Leírás"
                              name="description"
                              id="description"
                              fullWidth
                              multiline
                              maxRows={15}
                            />
                          </Box>
                        </>
                      )}
                      {data.status === TaskStatus.done && (
                        <Box pb={2}>
                          <FormTextField
                            size="small"
                            label="Visszajelzés"
                            name="adminNotes"
                            id="adminNotes"
                            fullWidth
                            multiline
                            maxRows={15}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} disabled={isSubmitting} sx={{ mr: 2 }}>
                      Mégse
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Mentés
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
