interface Props {
  style?: any;
}

export const Logo2 = (props: Props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 356 146"
      enableBackground="new 0 0 126 146"
      xmlSpace="preserve"
      width="356px"
      height="146px"
      {...props}
    >
      <rect
        fill="#E26025"
        width="126"
        height="126"
        transform="translate(20,10)"
      ></rect>
      <g transform="translate(176,-90)">
        <path
          fill="#A4A4A5"
          d="M0,135.413h6.739l2.723,9.24l3.108-9.297h5.556l3.108,9.297l2.723-9.24h6.629l-6.298,19.391h-5.611 l-3.383-9.571l-3.384,9.571H6.298L0,135.413z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M41.313,155.079c-1.485,0-2.866-0.258-4.139-0.771c-1.274-0.512-2.384-1.218-3.329-2.117 c-0.944-0.899-1.682-1.957-2.214-3.177c-0.532-1.219-0.798-2.525-0.798-3.92v-0.053c0-1.396,0.271-2.702,0.812-3.921 c0.541-1.22,1.283-2.283,2.229-3.191c0.944-0.906,2.058-1.623,3.342-2.146C38.5,135.262,39.883,135,41.369,135 c1.485,0,2.865,0.257,4.139,0.77c1.274,0.514,2.383,1.22,3.329,2.118c0.944,0.897,1.682,1.956,2.214,3.176 c0.532,1.22,0.798,2.526,0.798,3.919v0.056c0,1.396-0.271,2.7-0.812,3.92c-0.542,1.22-1.284,2.281-2.228,3.191 c-0.945,0.907-2.059,1.621-3.342,2.145C44.184,154.817,42.799,155.079,41.313,155.079z M41.369,149.386 c0.605,0,1.155-0.119,1.651-0.357c0.495-0.237,0.916-0.555,1.264-0.949s0.614-0.849,0.798-1.362 c0.184-0.514,0.275-1.052,0.275-1.623v-0.053c0-0.568-0.097-1.115-0.289-1.637c-0.193-0.524-0.464-0.986-0.812-1.39 c-0.349-0.403-0.771-0.727-1.266-0.964s-1.054-0.356-1.678-0.356c-0.605,0-1.155,0.119-1.65,0.356s-0.917,0.556-1.265,0.95 c-0.35,0.394-0.614,0.849-0.798,1.362c-0.183,0.512-0.275,1.053-0.275,1.622v0.056c0,0.568,0.096,1.113,0.288,1.636 c0.193,0.522,0.463,0.986,0.812,1.389c0.348,0.403,0.77,0.726,1.266,0.963C40.186,149.266,40.744,149.386,41.369,149.386z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M53.196,135.413h6.436v13.752h9.05v5.501H53.196V135.413z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M69.672,135.413h6.437v7.207l5.474-7.207h7.48l-6.711,8.361l6.85,10.892h-7.674l-3.77-6.105l-1.65,2.007 v4.099h-6.436V135.413z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M89.338,135.413h16.421v5.28H95.664v1.953h9.407v4.648h-9.407v2.09h10.231v5.281H89.338V135.413z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M107.217,135.413h9.408c1.723,0,3.14,0.197,4.25,0.59c1.107,0.396,2.002,0.931,2.68,1.61 c0.587,0.586,1.026,1.246,1.319,1.981c0.295,0.732,0.441,1.595,0.441,2.584v0.056c0,1.412-0.34,2.604-1.019,3.577 c-0.677,0.973-1.604,1.742-2.778,2.31l4.484,6.547h-7.346l-3.63-5.501h-0.055h-1.32v5.501h-6.438v-19.255H107.217z M116.434,144.571c0.768,0,1.374-0.166,1.814-0.497c0.438-0.328,0.659-0.785,0.659-1.372v-0.056c0-0.624-0.221-1.088-0.659-1.39 c-0.439-0.303-1.037-0.454-1.788-0.454h-2.807v3.769H116.434z"
        ></path>
      </g>
      <g transform="translate(176,-90)">
        <path
          fill="#A4A4A5"
          d="M2.526,165.277c0.084,0.213,0.236,0.404,0.456,0.576c0.22,0.175,0.523,0.331,0.912,0.473 c0.389,0.142,0.885,0.274,1.494,0.4c1.226,0.272,2.129,0.651,2.711,1.134s0.873,1.149,0.873,1.997c0,0.474-0.094,0.904-0.28,1.291 c-0.187,0.389-0.442,0.719-0.77,0.994c-0.327,0.271-0.721,0.482-1.183,0.629c-0.461,0.147-0.963,0.221-1.508,0.221 c-0.88,0-1.685-0.143-2.413-0.427c-0.728-0.283-1.422-0.723-2.083-1.318l0.771-0.911c0.575,0.521,1.157,0.913,1.744,1.17 c0.587,0.256,1.264,0.386,2.027,0.386c0.744,0,1.34-0.174,1.785-0.52c0.445-0.347,0.668-0.803,0.668-1.369 c0-0.263-0.042-0.497-0.125-0.701c-0.084-0.205-0.231-0.392-0.441-0.56c-0.21-0.168-0.502-0.319-0.874-0.456 s-0.849-0.269-1.426-0.393c-0.632-0.137-1.179-0.293-1.646-0.473c-0.468-0.178-0.854-0.393-1.159-0.644 c-0.305-0.253-0.53-0.547-0.677-0.883c-0.147-0.336-0.221-0.734-0.221-1.196c0-0.439,0.089-0.844,0.267-1.211 c0.178-0.368,0.43-0.684,0.754-0.952c0.325-0.27,0.708-0.479,1.147-0.632c0.441-0.151,0.922-0.228,1.446-0.228 c0.806,0,1.512,0.11,2.114,0.333c0.603,0.223,1.187,0.558,1.752,1.003l-0.724,0.959c-0.512-0.417-1.029-0.723-1.548-0.909 c-0.519-0.188-1.061-0.285-1.626-0.285c-0.356,0-0.678,0.05-0.967,0.144c-0.288,0.094-0.535,0.221-0.739,0.379 c-0.204,0.155-0.362,0.346-0.472,0.565c-0.109,0.222-0.165,0.456-0.165,0.708C2.4,164.833,2.443,165.069,2.526,165.277z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M16.468,162.934v-1.1h8.582v0.835l-7.136,9.067h7.136v1.101h-8.818v-0.836l7.121-9.067H16.468z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M40.814,162.967h-6.711v3.756h6.003v1.13h-6.003v3.852h6.79v1.133h-8.032v-11.005h7.953V162.967z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M56.202,172.837l-3.191-4.275h-3.144v4.275h-1.241v-11.003h4.731c0.607,0,1.155,0.079,1.642,0.236 c0.487,0.156,0.904,0.38,1.25,0.668c0.346,0.286,0.614,0.635,0.802,1.039c0.188,0.403,0.282,0.852,0.282,1.344 c0,0.461-0.073,0.873-0.219,1.234c-0.147,0.362-0.354,0.677-0.621,0.943c-0.267,0.267-0.584,0.49-0.951,0.669 c-0.367,0.18-0.765,0.304-1.195,0.379l3.38,4.491L56.202,172.837L56.202,172.837z M55.352,163.555 c-0.493-0.384-1.188-0.575-2.08-0.575h-3.404v4.48h3.388c0.411,0,0.788-0.052,1.135-0.157c0.348-0.104,0.647-0.255,0.899-0.45 c0.251-0.193,0.449-0.432,0.59-0.717c0.143-0.283,0.213-0.604,0.213-0.961C56.092,164.479,55.844,163.939,55.352,163.555z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M66.309,165.277c0.083,0.213,0.235,0.404,0.456,0.576c0.221,0.175,0.523,0.331,0.912,0.473 c0.387,0.142,0.884,0.274,1.492,0.4c1.226,0.272,2.129,0.651,2.712,1.134c0.582,0.482,0.873,1.149,0.873,1.997 c0,0.474-0.094,0.904-0.279,1.291c-0.186,0.389-0.443,0.719-0.771,0.994c-0.328,0.271-0.721,0.482-1.183,0.629 c-0.461,0.147-0.964,0.221-1.508,0.221c-0.879,0-1.686-0.143-2.413-0.427c-0.729-0.283-1.422-0.723-2.082-1.318l0.771-0.911 c0.575,0.521,1.156,0.913,1.744,1.17c0.587,0.256,1.262,0.386,2.026,0.386c0.744,0,1.339-0.174,1.784-0.52 c0.444-0.347,0.668-0.803,0.668-1.369c0-0.263-0.042-0.497-0.126-0.701c-0.084-0.205-0.231-0.392-0.441-0.56 c-0.211-0.168-0.501-0.319-0.874-0.456c-0.374-0.137-0.849-0.269-1.425-0.393c-0.633-0.137-1.18-0.293-1.648-0.473 c-0.468-0.178-0.854-0.393-1.157-0.644c-0.305-0.253-0.531-0.547-0.678-0.883c-0.147-0.334-0.221-0.734-0.221-1.196 c0-0.439,0.088-0.844,0.267-1.211c0.179-0.368,0.43-0.684,0.754-0.952c0.325-0.269,0.708-0.479,1.147-0.632 c0.44-0.151,0.922-0.228,1.447-0.228c0.806,0,1.512,0.11,2.114,0.333c0.602,0.223,1.186,0.558,1.751,1.003l-0.724,0.959 c-0.512-0.417-1.027-0.723-1.548-0.909c-0.517-0.188-1.061-0.285-1.625-0.285c-0.356,0-0.679,0.05-0.967,0.144 c-0.287,0.094-0.533,0.221-0.738,0.379c-0.204,0.155-0.361,0.346-0.472,0.565c-0.109,0.222-0.166,0.456-0.166,0.708 C66.181,164.833,66.223,165.069,66.309,165.277z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M80.25,162.934v-1.1h8.582v0.835l-7.137,9.067h7.137v1.101h-8.818v-0.836l7.12-9.067H80.25z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M106.892,172.837h-1.337l-1.289-2.908h-5.988l-1.306,2.908H95.7l5.013-11.081h1.164L106.892,172.837z M101.278,163.218l-2.515,5.61h5.015L101.278,163.218z M100.822,160.857l1.494-2.277l1.257,0.581l-1.774,1.697L100.822,160.857 L100.822,160.857z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M119.575,169.818h-0.062l-4.008-5.879v8.897h-1.211v-11.003h1.258l4.008,6.004l4.007-6.004h1.257v11.003 h-1.241v-8.913L119.575,169.818z"
        ></path>
      </g>
      <g transform="translate(176,-90)">
        <path
          fill="#A4A4A5"
          d="M7.711,180.468h0.983v8.938H7.89l-5.772-7.33v7.33H1.135v-8.938h0.944l5.632,7.164V180.468z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M19.422,189.406h-1.085l-1.048-2.363h-4.866l-1.06,2.363H10.33l4.074-9.004h0.945L19.422,189.406z M14.863,181.591l-2.043,4.559h4.074L14.863,181.591z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M27.255,189.157c-0.608,0.267-1.291,0.403-2.048,0.403c-0.708,0-1.339-0.12-1.897-0.359 c-0.558-0.237-1.035-0.565-1.431-0.982c-0.396-0.418-0.698-0.906-0.907-1.469c-0.208-0.563-0.313-1.162-0.313-1.8 c0-0.614,0.109-1.201,0.326-1.763c0.217-0.563,0.521-1.055,0.914-1.482c0.391-0.425,0.861-0.763,1.411-1.015 c0.549-0.251,1.16-0.377,1.832-0.377c0.374,0,0.717,0.024,1.028,0.077c0.311,0.051,0.601,0.128,0.869,0.229 c0.269,0.103,0.519,0.228,0.753,0.377c0.234,0.148,0.461,0.318,0.683,0.505l-0.651,0.767c-0.169-0.154-0.349-0.292-0.536-0.417 c-0.188-0.121-0.39-0.229-0.606-0.318c-0.217-0.09-0.456-0.158-0.715-0.211c-0.26-0.052-0.547-0.075-0.862-0.075 c-0.494,0-0.95,0.099-1.366,0.294c-0.418,0.197-0.775,0.461-1.073,0.792c-0.297,0.335-0.532,0.724-0.702,1.171 c-0.169,0.448-0.256,0.923-0.256,1.427c0,0.537,0.083,1.034,0.249,1.492c0.167,0.455,0.402,0.85,0.708,1.181 c0.307,0.335,0.677,0.594,1.111,0.782c0.435,0.187,0.923,0.281,1.469,0.281c0.511,0,0.992-0.087,1.443-0.264 c0.451-0.175,0.83-0.387,1.136-0.634v-2.231h-2.72v-0.893h3.691v3.552C28.377,188.569,27.865,188.891,27.255,189.157z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M34.747,189.406h-1.009v-3.538l-3.716-5.4h1.225l3.007,4.469l3.034-4.469h1.175l-3.716,5.388V189.406z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M47.568,189.406h-1.264l-3.436-4.33l-1.903,1.929v2.401h-1.008v-8.938h1.008v5.339l5.134-5.339h1.303 l-3.832,3.906L47.568,189.406z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M55.472,181.388h-5.453v3.051h4.878v0.92h-4.878v3.13h5.517v0.919H49.01v-8.938h6.462V181.388z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M63.594,189.406l-2.592-3.475h-2.554v3.475h-1.009v-8.938h3.845c0.493,0,0.938,0.062,1.334,0.189 c0.395,0.13,0.732,0.31,1.014,0.544c0.281,0.234,0.497,0.516,0.651,0.842c0.153,0.329,0.229,0.693,0.229,1.094 c0,0.374-0.061,0.708-0.179,1.001c-0.119,0.295-0.288,0.551-0.505,0.769c-0.217,0.217-0.473,0.397-0.772,0.542 c-0.298,0.146-0.622,0.246-0.971,0.308l2.747,3.649L63.594,189.406L63.594,189.406z M62.903,181.866 c-0.399-0.31-0.964-0.468-1.69-0.468h-2.764v3.641h2.752c0.333,0,0.639-0.042,0.922-0.129c0.281-0.085,0.525-0.207,0.73-0.364 c0.204-0.16,0.365-0.354,0.479-0.585c0.116-0.229,0.173-0.491,0.173-0.781C63.505,182.617,63.305,182.178,62.903,181.866z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M73.006,181.388h-5.453v3.051h4.88v0.92h-4.88v3.13h5.517v0.919h-6.525v-8.938h6.462V181.388 L73.006,181.388z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M75.854,183.267c0.068,0.172,0.191,0.327,0.369,0.468c0.181,0.141,0.426,0.268,0.743,0.385 c0.315,0.114,0.718,0.224,1.213,0.325c0.995,0.222,1.73,0.528,2.202,0.923c0.471,0.393,0.709,0.932,0.709,1.623 c0,0.383-0.076,0.732-0.228,1.048s-0.36,0.585-0.625,0.806c-0.267,0.223-0.587,0.392-0.962,0.512 c-0.373,0.119-0.782,0.18-1.226,0.18c-0.715,0-1.369-0.116-1.959-0.348c-0.593-0.229-1.157-0.587-1.692-1.071l0.625-0.741 c0.469,0.426,0.94,0.744,1.418,0.951c0.476,0.21,1.025,0.313,1.646,0.313c0.605,0,1.088-0.142,1.451-0.423 c0.36-0.281,0.543-0.652,0.543-1.113c0-0.213-0.035-0.401-0.104-0.568c-0.066-0.166-0.188-0.317-0.358-0.454 c-0.171-0.138-0.407-0.26-0.711-0.371c-0.304-0.109-0.688-0.217-1.158-0.32c-0.511-0.108-0.958-0.238-1.338-0.384 c-0.378-0.146-0.692-0.318-0.94-0.524c-0.247-0.204-0.431-0.443-0.551-0.716c-0.121-0.274-0.18-0.597-0.18-0.973 c0-0.357,0.073-0.686,0.219-0.983c0.144-0.3,0.348-0.556,0.612-0.775c0.265-0.217,0.575-0.388,0.933-0.511 c0.356-0.125,0.748-0.186,1.174-0.186c0.656,0,1.228,0.091,1.718,0.271c0.491,0.181,0.964,0.453,1.425,0.814l-0.59,0.779 c-0.417-0.341-0.837-0.587-1.256-0.74c-0.423-0.153-0.862-0.229-1.323-0.229c-0.29,0-0.551,0.037-0.785,0.114 c-0.233,0.076-0.436,0.179-0.6,0.309c-0.167,0.127-0.294,0.281-0.385,0.458c-0.088,0.181-0.135,0.373-0.135,0.577 C75.751,182.905,75.786,183.098,75.854,183.267z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M90.63,189.406h-1.264l-3.437-4.33l-1.901,1.929v2.401H83.02v-8.938h1.009v5.339l5.133-5.339h1.303 l-3.832,3.906L90.63,189.406z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M98.535,181.388h-5.452v3.051h4.877v0.92h-4.877v3.13h5.516v0.919h-6.526v-8.938h6.462L98.535,181.388 L98.535,181.388z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M108.01,186.693c-0.229,0.549-0.555,1.022-0.976,1.423s-0.924,0.717-1.508,0.946 c-0.583,0.229-1.225,0.344-1.923,0.344h-3.102v-8.938h3.102c0.698,0,1.34,0.111,1.923,0.337c0.584,0.226,1.087,0.54,1.508,0.94 c0.421,0.398,0.747,0.872,0.976,1.415c0.229,0.546,0.346,1.135,0.346,1.763C108.355,185.554,108.24,186.144,108.01,186.693z M107.047,183.558c-0.175-0.434-0.422-0.81-0.741-1.13c-0.32-0.319-0.71-0.57-1.168-0.753c-0.461-0.184-0.972-0.276-1.534-0.276 h-2.093v7.075h2.093c0.563,0,1.073-0.089,1.534-0.266c0.458-0.181,0.848-0.43,1.168-0.748c0.319-0.319,0.566-0.692,0.741-1.119 c0.174-0.426,0.261-0.888,0.261-1.391C107.308,184.456,107.221,183.992,107.047,183.558z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M116.822,181.388h-5.452v3.051h4.878v0.92h-4.878v3.13h5.516v0.919h-6.527v-8.938h6.464V181.388 L116.822,181.388z M112.89,179.676l1.212-1.853l1.022,0.473l-1.444,1.378h-0.79V179.676z"
        ></path>
        <path
          fill="#A4A4A5"
          d="M119.669,183.267c0.069,0.172,0.192,0.327,0.37,0.468c0.182,0.141,0.428,0.268,0.742,0.385 c0.315,0.114,0.718,0.224,1.213,0.325c0.995,0.222,1.73,0.528,2.203,0.923c0.472,0.393,0.707,0.932,0.707,1.623 c0,0.383-0.073,0.732-0.225,1.048c-0.15,0.315-0.36,0.585-0.626,0.806c-0.267,0.223-0.588,0.392-0.962,0.512 c-0.374,0.119-0.782,0.18-1.226,0.18c-0.713,0-1.367-0.116-1.96-0.348c-0.592-0.229-1.155-0.587-1.692-1.071l0.627-0.741 c0.468,0.426,0.939,0.744,1.418,0.951c0.475,0.21,1.024,0.313,1.646,0.313c0.604,0,1.088-0.142,1.451-0.423 c0.36-0.281,0.543-0.652,0.543-1.113c0-0.213-0.034-0.401-0.102-0.568c-0.068-0.166-0.188-0.317-0.36-0.454 c-0.169-0.138-0.405-0.26-0.71-0.371c-0.305-0.109-0.688-0.217-1.159-0.32c-0.512-0.108-0.959-0.238-1.336-0.384 c-0.381-0.146-0.693-0.318-0.941-0.524c-0.247-0.204-0.431-0.443-0.551-0.716c-0.121-0.274-0.181-0.597-0.181-0.973 c0-0.357,0.072-0.686,0.219-0.983c0.143-0.3,0.348-0.556,0.611-0.775c0.266-0.217,0.576-0.388,0.932-0.511 c0.359-0.125,0.749-0.186,1.175-0.186c0.657,0,1.228,0.091,1.718,0.271c0.491,0.181,0.965,0.453,1.425,0.814l-0.588,0.779 c-0.416-0.341-0.838-0.587-1.256-0.74c-0.424-0.153-0.863-0.229-1.322-0.229c-0.291,0-0.553,0.037-0.785,0.114 c-0.234,0.076-0.436,0.179-0.601,0.309c-0.167,0.127-0.295,0.281-0.384,0.458c-0.09,0.181-0.136,0.373-0.136,0.577 C119.568,182.905,119.603,183.098,119.669,183.267z"
        ></path>
      </g>
      <g transform="translate(20, 10)">
        <polygon
          fill="#FFFFFF"
          points="41.798,35.79 41.75,35.6 20.05,35.6 36.55,90.6 55.435,90.6 "
        ></polygon>
        <polygon
          fill="#FFFFFF"
          points="90.18,87.541 105.95,35.6 84.55,35.6 80.813,49.906 "
        ></polygon>
        <polygon
          fill="#FFFFFF"
          points="72.07,35.473 72.05,35.4 53.95,35.4 50.085,48.4 58.342,81.577 62.95,67.2 70.25,90.6 85.788,90.6 "
        ></polygon>
      </g>
    </svg>
  );
};
