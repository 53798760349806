import moment from 'moment';

export const useFormat = () => {
  const defaultDateFormat = 'YYYY.MM.DD';
  const formatDate = (date: Date, dateFormat?: string) =>
    moment(date).format(dateFormat ? dateFormat : defaultDateFormat);

  const formatCurrency = (value: number, digit?: number) => {
    const formatter = new Intl.NumberFormat('hu-HU', {
      style: 'currency',
      currency: 'huf',
      minimumFractionDigits: 0,
      maximumFractionDigits: digit ?? 0,
    });
    return formatter.format(value);
  };
  const formatNumber = (value: number | undefined, suffix?: string, digit?: number) => {
    const numberFormatter = new Intl.NumberFormat('hu-HU', {
      minimumFractionDigits: 0,
      maximumFractionDigits: digit ?? 0,
    });
    return value != null ? `${numberFormatter.format(value)}${suffix ? suffix : ''}` : 'N/A';
  };
  return { formatDate, formatCurrency, formatNumber };
};
