import { useEffect, useState } from 'react';
import { Divider, Typography, Theme, Collapse, Box } from '@mui/material';

import { Statistics as StatisticsModel } from '../../../models/myTarget/Statistics';
import { Fulfillment } from './Fulfillment';
import { selectStatistics, setStatistics } from '../../../slices/myTargetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { MyTargetInfoRequest } from '../../../models/myTarget/MyTargetInfoRequest';
import { MyTargetInfoResponse } from '../../../models/myTarget/MyTargetInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Loading } from '../../common/Loading';

interface Props {
  agentId: number;
}

export const Statistics: React.FC<Props> = ({ agentId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const statistics: StatisticsModel | undefined | null = useSelector(selectStatistics);

  useEffect(() => {
    if (statistics === undefined) {
      dispatch(setStatistics(null));
      setIsLoading(true);
      const access = new Access();
      const criteria: MyTargetInfoRequest = {
        agentId,
        getStatistics: {},
      };
      access
        .myTargetInfo(criteria)
        .then((value: MyTargetInfoResponse | undefined) => {
          if (value?.error == null && value?.agentInfo?.statistics != null) {
            dispatch(setStatistics(value.agentInfo.statistics));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics]);

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Adott évi teljesítmény
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Box mb={3}>{isLoading ? <Loading /> : <>{statistics && <Fulfillment statistics={statistics} />}</>}</Box>
      </Collapse>
      <Divider sx={{ mb: 3 }} />
    </>
  );
};
