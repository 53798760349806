import { useEffect, useMemo, useState } from 'react';
import { Divider, Typography, Theme, Collapse, Box, Grid } from '@mui/material';

import { FinancialPerformanceIndicators as FinancialPerformanceIndicatorsModel } from '../../../models/admin/FinancialPerformanceIndicators';
import { setFinancialPerformanceIndicators, selectFinancialPerformanceIndicators } from '../../../slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { AdminInfoRequest } from '../../../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../../../models/admin/AdminInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Loading } from '../../common/Loading';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Info } from '../../common/Info';
import { useFormat } from '../../../utility/useFormat';
import { TargetFulfillment } from '../../../models/common/TargetFulfillment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

export const FinancialPerformanceIndicators: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formatNumber, formatCurrency } = useFormat();

  const financialPerformanceIndicators: FinancialPerformanceIndicatorsModel | undefined | null = useSelector(
    selectFinancialPerformanceIndicators
  );

  useEffect(() => {
    if (financialPerformanceIndicators === undefined) {
      dispatch(setFinancialPerformanceIndicators(null));
      setIsLoading(true);
      setIsInitialized(true);
      const access = new Access();
      const criteria: AdminInfoRequest = {
        getFinancialPerformanceIndicators: {},
      };
      access
        .adminInfo(criteria)
        .then((value: AdminInfoResponse | undefined) => {
          if (value?.error == null && value?.adminInfo?.financialPerformanceIndicators != null) {
            dispatch(setFinancialPerformanceIndicators(value.adminInfo.financialPerformanceIndicators));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (financialPerformanceIndicators != null && !isInitialized) {
      dispatch(setFinancialPerformanceIndicators(undefined));
    }
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialPerformanceIndicators]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const fulfillmentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        labels: {
          title: {
            align: 'top' as any,
            anchor: 'end' as any,
            offset: 0,
            color: 'rgba(0, 0, 0, 0.87)',
            formatter: function (value: any, context: any) {
              if (context.dataset.percent != null && context.dataset.percent[context.dataIndex]) {
                return formatNumber(context.dataset.percent[context.dataIndex], '%');
              }

              return null;
            },
          },
          value: {
            rotation: 270,
            formatter: (value: any) => formatCurrency(value),
          },
        },
      },
    },
  };

  const pointsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        labels: {
          title: {
            align: 'top' as any,
            anchor: 'end' as any,
            offset: 0,
            color: 'rgba(0, 0, 0, 0.87)',
            formatter: function (value: any, context: any) {
              if (context.dataset.percent != null && context.dataset.percent[context.dataIndex]) {
                return formatNumber(context.dataset.percent[context.dataIndex], '%');
              }

              return null;
            },
          },
          value: {
            rotation: 270,
            formatter: (value: any) => formatNumber(value),
          },
        },
      },
    },
  };

  const cumulativeSaleData = useMemo(() => {
    if (financialPerformanceIndicators?.cumulativeSaleData == null) {
      return undefined;
    }

    const labels: string[] = financialPerformanceIndicators.cumulativeSaleData.income.map((_, index: number) =>
      (index + 1).toString()
    );

    const points = {
      labels,
      datasets: [
        {
          label: 'Elvárható pont',
          data: financialPerformanceIndicators.cumulativeSaleData.pointsTarget,
          borderColor: 'rgb(73, 173, 245)',
          backgroundColor: 'rgba(73, 173, 245, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Pont',
          data: financialPerformanceIndicators.cumulativeSaleData.points,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
        },
      ],
    };

    const sales = {
      labels,
      datasets: [
        {
          label: 'Árbevétel',
          data: financialPerformanceIndicators.cumulativeSaleData.income,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49, 0.5)',
        },
        {
          label: 'Előző évi Árbevétel',
          data: financialPerformanceIndicators.cumulativeSaleData.incomeTarget,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Előző évi Marzs (Ft)',
          data: financialPerformanceIndicators.cumulativeSaleData.marginTarget,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Marzs (Ft)',
          data: financialPerformanceIndicators.cumulativeSaleData.margin,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
        },
      ],
    };

    return { points, sales };
  }, [financialPerformanceIndicators?.cumulativeSaleData]);

  const periodicSaleData = useMemo(() => {
    if (financialPerformanceIndicators?.periodicSaleData == null) {
      return undefined;
    }

    const labels: string[] = financialPerformanceIndicators.periodicSaleData.income.map((_, index: number) =>
      (index + 1).toString()
    );

    const points = {
      labels,
      datasets: [
        {
          label: 'Elvárható pont',
          data: financialPerformanceIndicators.periodicSaleData.pointsTarget,
          borderColor: 'rgb(73, 173, 245)',
          backgroundColor: 'rgba(73, 173, 245, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Pont',
          data: financialPerformanceIndicators.periodicSaleData.points,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
        },
      ],
    };

    const sales = {
      labels,
      datasets: [
        {
          label: 'Előző évi Árbevétel',
          data: financialPerformanceIndicators.periodicSaleData.incomeTarget,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Árbevétel',
          data: financialPerformanceIndicators.periodicSaleData.income,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49, 0.5)',
        },
        {
          label: 'Előző évi Marzs (Ft)',
          data: financialPerformanceIndicators.periodicSaleData.marginTarget,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
          borderDash: [5, 5],
        },
        {
          label: 'Marzs (Ft)',
          data: financialPerformanceIndicators.periodicSaleData.margin,
          borderColor: 'rgb(21, 42, 230)',
          backgroundColor: 'rgba(21, 42, 230, 0.5)',
        },
      ],
    };

    return { points, sales };
  }, [financialPerformanceIndicators?.periodicSaleData]);

  const monthlyData = useMemo(() => {
    const incomeLabels: string[] = [];
    const incomeAccomplished: number[] = [];
    const incomeTarget: number[] = [];
    const incomePercent: number[] = [];

    const marginLabels: string[] = [];
    const marginAccomplished: number[] = [];
    const marginTarget: number[] = [];
    const marginPercent: number[] = [];

    const pointsLabels: string[] = [];
    const pointsAccomplished: number[] = [];
    const pointsTarget: number[] = [];
    const pointsPercent: number[] = [];

    if (financialPerformanceIndicators?.monthlyComparison == null) {
      return undefined;
    }

    financialPerformanceIndicators.monthlyComparison.income.forEach((f: TargetFulfillment) => {
      incomeLabels.push(f.label);
      incomeAccomplished.push(f.accomplished);
      incomeTarget.push(f.target);
      const p = f.target !== 0 ? (f.accomplished * 100) / f.target : 0;
      incomePercent.push(p);
    });

    financialPerformanceIndicators.monthlyComparison.margin.forEach((f: TargetFulfillment) => {
      marginLabels.push(f.label);
      marginAccomplished.push(f.accomplished);
      marginTarget.push(f.target);
      const p = f.target !== 0 ? (f.accomplished * 100) / f.target : 0;
      marginPercent.push(p);
    });

    financialPerformanceIndicators.monthlyComparison.points.forEach((f: TargetFulfillment) => {
      pointsLabels.push(f.label);
      pointsAccomplished.push(f.accomplished);
      pointsTarget.push(f.target);
      const p = f.target !== 0 ? (f.accomplished * 100) / f.target : 0;
      pointsPercent.push(p);
    });

    const income = {
      labels: incomeLabels,
      datasets: [
        {
          label: 'Előző naptári év (Ft)',
          data: incomeTarget,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
        },
        {
          label: 'Árbevétel (Ft)',
          data: incomeAccomplished,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49)',
          percent: incomePercent,
        },
      ],
    };

    const margin = {
      labels: marginLabels,
      datasets: [
        {
          label: 'Előző naptári év (Ft)',
          data: marginTarget,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
        },
        {
          label: 'Marzs (Ft)',
          data: marginAccomplished,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49)',
          percent: marginPercent,
        },
      ],
    };

    const points = {
      labels: pointsLabels,
      datasets: [
        {
          label: 'Elvárható pontok (Pont)',
          data: pointsTarget,
          borderColor: 'rgb(175, 171, 171)',
          backgroundColor: 'rgba(175, 171, 171)',
        },
        {
          label: 'Elért pontok (Pont)',
          data: pointsAccomplished,
          borderColor: 'rgb(237, 125, 49)',
          backgroundColor: 'rgba(237, 125, 49)',
          percent: pointsPercent,
        },
      ],
    };
    return { income, margin, points };
  }, [financialPerformanceIndicators?.monthlyComparison]);

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Időszaki pénzügyi teljesítmény adatok
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Box mb={3}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {financialPerformanceIndicators && (
                <>
                  {cumulativeSaleData && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Kumulativ értékesítési adatok (Pont)
                          <Info
                            text={`Elvárható pont: A teljes adott évi pont cél napra pontos historikus szezonalitással leosztva.
                          Nem egyezik az egyénileg meghatározott negyedéves pont célokkal.`}
                          />
                        </Typography>
                        <Box mb={3}>
                          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 500 }}>
                            <Line
                              options={{
                                ...options,
                                plugins: {
                                  ...options.plugins,
                                },
                              }}
                              data={cumulativeSaleData.points}
                              updateMode="resize"
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Kumulativ értékesítési adatok (Árbevétel - Ft)
                        </Typography>
                        <Box mb={3}>
                          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 500 }}>
                            <Line
                              options={{
                                ...options,
                                plugins: {
                                  ...options.plugins,
                                },
                              }}
                              data={cumulativeSaleData.sales}
                              updateMode="resize"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  {periodicSaleData && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Időszaki értékesítési adatok (Pont)
                          <Info
                            text={`Elvárható pont: A teljes adott évi pont cél napra pontos historikus szezonalitással leosztva.
                          Nem egyezik az egyénileg meghatározott negyedéves pont célokkal.`}
                          />
                        </Typography>
                        <Box mb={3}>
                          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 500 }}>
                            <Line
                              options={{
                                ...options,
                                plugins: {
                                  ...options.plugins,
                                },
                              }}
                              data={periodicSaleData.points}
                              updateMode="resize"
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Időszaki értékesítési adatok (Árbevétel - Ft)
                        </Typography>
                        <Box mb={3}>
                          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 500 }}>
                            <Line
                              options={{
                                ...options,
                                plugins: {
                                  ...options.plugins,
                                },
                              }}
                              data={periodicSaleData.sales}
                              updateMode="resize"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {monthlyData?.income && (
                    <>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Árbevétel vs. Előző naptári év (Ft)
                      </Typography>
                      <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
                        <Bar
                          options={{
                            ...fulfillmentOptions,
                            plugins: {
                              ...fulfillmentOptions.plugins,
                            },
                          }}
                          data={monthlyData.income}
                          plugins={[ChartDataLabels]}
                        />
                      </Box>
                    </>
                  )}

                  {monthlyData?.margin && (
                    <>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Marzs vs. Előző naptári év (Ft)
                      </Typography>
                      <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
                        <Bar
                          options={{
                            ...fulfillmentOptions,
                            plugins: {
                              ...fulfillmentOptions.plugins,
                            },
                          }}
                          data={monthlyData.margin}
                          plugins={[ChartDataLabels]}
                        />
                      </Box>
                    </>
                  )}

                  {monthlyData?.points && (
                    <>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Havi elért pont vs. cél (Pont)
                        <Info text={`Elvárható pont: Az egyénileg meghatározott havi pont célok összege.`} />
                      </Typography>
                      <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
                        <Bar
                          options={{
                            ...pointsOptions,
                            plugins: {
                              ...pointsOptions.plugins,
                            },
                          }}
                          data={monthlyData.points}
                          plugins={[ChartDataLabels]}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Collapse>
      <Divider sx={{ mb: 3 }} />
    </>
  );
};
