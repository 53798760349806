export enum PartnerStatus {
  Active = 0,
  ShouldBeReActivated = 1,
  NewRegistration = 2,
  RegisteredButNoOrder = 3,
  UnderPerforming = 4,
  NewTarget = 5,
  WebRegistered = 6,
  ReActivated = 7,
}
