import { useEffect, useState } from 'react';
import { Divider, Typography, Theme, Collapse, Box, Grid } from '@mui/material';

import { AgentsPerformance as AgentsPerformanceModel } from '../../../models/admin/AgentsPerformance';
import { setAgentsPerformance, selectAgentsPerformance } from '../../../slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { AdminInfoRequest } from '../../../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../../../models/admin/AdminInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Loading } from '../../common/Loading';
import { PerformanceReference } from '../../common/statistics/PerformanceReference';
import { IncomeContribution } from './IncomeContribution';
import { AgentsDetailedIndicators } from './AgentsDetailedIndicators';
import { Salary } from './Salary';
import { Module } from '../../../models/Module';

export const AgentsPerformance: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch();

  const agentsPerformance: AgentsPerformanceModel | undefined | null = useSelector(selectAgentsPerformance);

  useEffect(() => {
    if (agentsPerformance === undefined) {
      dispatch(setAgentsPerformance(null));
      setIsLoading(true);
      setIsInitialized(true);
      const access = new Access();
      const criteria: AdminInfoRequest = {
        getAgentsPerformance: {},
      };
      access
        .adminInfo(criteria)
        .then((value: AdminInfoResponse | undefined) => {
          if (value?.error == null && value?.adminInfo?.agentsPerformance != null) {
            dispatch(setAgentsPerformance(value.adminInfo.agentsPerformance));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (agentsPerformance != null && !isInitialized) {
      dispatch(setAgentsPerformance(undefined));
    }
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsPerformance]);

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Értékesítői teljesítmény
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Box mb={3}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {agentsPerformance != null && (
                <Grid container spacing={3}>
                  <PerformanceReference data={agentsPerformance.performanceReference} module={Module.admin} />
                  <IncomeContribution data={agentsPerformance.incomeContribution} />
                </Grid>
              )}

              <AgentsDetailedIndicators />
              <Salary />
            </>
          )}
        </Box>
      </Collapse>
      <Divider sx={{ mb: 3 }} />
    </>
  );
};
