import { SignInResponse } from '../models/SignInResponse';
import { apiClient } from './apiClient';
import { IAccess } from './IAccess';
import { SignInRequest } from '../models/SignInRequest';
import { ValidateUser } from '../models/ValidateUser';
import { LoadPartnerResponse } from '../models/partners/LoadPartnerResponse';
import { PartnerInfoRequest } from '../models/partners/PartnerInfoRequest';
import { PartnerInfoResponse } from '../models/partners/PartnerInfoResponse';
import { SavePartnerRequest } from '../models/partners/SavePartnerRequest';
import { SavePartnerResponse } from '../models/partners/SavePartnerResponse';
import { SaveAccountRequest } from '../models/account/SaveAccountRequest';
import { SaveAccountResponse } from '../models/account/SaveAccountResponse';
import { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import { ForgotPasswordResponse } from '../models/ForgotPasswordResponse';
import { MyTargetInfoRequest } from '../models/myTarget/MyTargetInfoRequest';
import { MyTargetInfoResponse } from '../models/myTarget/MyTargetInfoResponse';
import { LoadMyTargetResponse } from '../models/myTarget/LoadMyTargetResponse';
import { SaveMyTargetRequest } from '../models/myTarget/SaveMyTargetRequest';
import { SaveMyTargetResponse } from '../models/myTarget/SaveMyTargetResponse';
import { LoadVisitsRequest } from '../models/visits/LoadVisitsRequest';
import { LoadVisitsResponse } from '../models/visits/LoadVisitsResponse';
import { SaveVisitRequest } from '../models/visits/SaveVisitRequest';
import { SaveVisitResponse } from '../models/visits/LoadVisitresponse';
import { AdminInfoRequest } from '../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../models/admin/AdminInfoResponse';

export class Access implements IAccess {
  async signIn(criteria: SignInRequest): Promise<SignInResponse | undefined> {
    const promise: Promise<SignInResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/signIn.php', criteria);
          const response: SignInResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async validateUser(): Promise<ValidateUser | undefined> {
    const promise: Promise<ValidateUser | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/validateUser.php');
          const response: ValidateUser | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadPartner(): Promise<LoadPartnerResponse | undefined> {
    const promise: Promise<LoadPartnerResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/partners/load.php');
          const response: LoadPartnerResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadMyTarget(): Promise<LoadMyTargetResponse | undefined> {
    const promise: Promise<LoadMyTargetResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/myTarget/load.php');
          const response: LoadMyTargetResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async loadVisits(criteria: LoadVisitsRequest): Promise<LoadVisitsResponse | undefined> {
    const promise: Promise<LoadVisitsResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/visits/load.php', criteria);
          const response: LoadVisitsResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveVisit(criteria: SaveVisitRequest): Promise<SaveVisitResponse | undefined> {
    const promise: Promise<SaveVisitResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/visits/save.php', criteria);
          const response: SaveVisitResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async partnerInfo(criteria: PartnerInfoRequest): Promise<PartnerInfoResponse | undefined> {
    const promise: Promise<PartnerInfoResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/partners/info.php', criteria);
          const response: PartnerInfoResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async savePartner(criteria: SavePartnerRequest): Promise<SavePartnerResponse | undefined> {
    const promise: Promise<SavePartnerResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/partners/save.php', criteria);
          const response: SavePartnerResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveAccount(criteria: SaveAccountRequest): Promise<SaveAccountResponse | undefined> {
    const promise: Promise<SaveAccountResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/save.php', criteria);
          const response: SaveAccountResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async forgotPassword(criteria: ForgotPasswordRequest): Promise<ForgotPasswordResponse | undefined> {
    const promise: Promise<ForgotPasswordResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/users/forgotPassword.php', criteria);
          const response: ForgotPasswordResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async myTargetInfo(criteria: MyTargetInfoRequest): Promise<MyTargetInfoResponse | undefined> {
    const promise: Promise<MyTargetInfoResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/myTarget/info.php', criteria);
          const response: MyTargetInfoResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }

  async saveMyTarget(criteria: SaveMyTargetRequest): Promise<SaveMyTargetResponse | undefined> {
    const promise: Promise<SaveMyTargetResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/myTarget/save.php', criteria);
          const response: SaveMyTargetResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }
  async adminInfo(criteria: AdminInfoRequest): Promise<AdminInfoResponse | undefined> {
    const promise: Promise<AdminInfoResponse | undefined> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolutionFunc, rejectFunction) => {
        try {
          const client = await apiClient();
          const result: any = await client.post('/api/analytics/info.php', criteria);
          const response: AdminInfoResponse | undefined = result.data;
          resolutionFunc(response);
        } catch (e) {
          rejectFunction(e);
        }
      }
    );
    return promise;
  }
}
