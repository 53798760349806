import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export interface ErrorMessageProps {
  title?: string;
  errors?: any[];
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, errors }: ErrorMessageProps) => {
  let caption;

  return (
    <Box padding={3} marginTop={20} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Typography variant="h6">{title}</Typography>
      {errors &&
        errors
          .filter((error) => error.message !== undefined)
          .map((error: any) => {
            return (
              <Typography variant="body1" key={uuidv4()} textAlign="center" component={Box} padding={2}>
                {error.message}
              </Typography>
            );
          })}
      {caption}
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh page
      </Button>
    </Box>
  );
};
