import { useEffect, useState } from 'react';
import { Divider, Typography, Theme, Collapse, Grid, Box } from '@mui/material';

import { KeyPerformanceIndicators as KeyPerformanceIndicatorsModel } from '../../../models/admin/KeyPerformanceIndicators';
import { setKeyPerformanceIndicators, selectKeyPerformanceIndicators } from '../../../slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../../../access/Access';
import { AdminInfoRequest } from '../../../models/admin/AdminInfoRequest';
import { AdminInfoResponse } from '../../../models/admin/AdminInfoResponse';
import { addMessage } from '../../../slices/applicationSlice';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Loading } from '../../common/Loading';
import { Gauge } from '../../common/Gauge';

export const KeyPerformanceIndicators: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const keyPerformanceIndicators: KeyPerformanceIndicatorsModel | undefined | null =
    useSelector(selectKeyPerformanceIndicators);

  useEffect(() => {
    if (keyPerformanceIndicators === undefined) {
      setIsInitialized(true);
      dispatch(setKeyPerformanceIndicators(null));
      setIsLoading(true);
      const access = new Access();
      const criteria: AdminInfoRequest = {
        getKeyPerformanceIndicators: {},
      };
      access
        .adminInfo(criteria)
        .then((value: AdminInfoResponse | undefined) => {
          if (value?.error == null && value?.adminInfo?.keyPerformanceIndicators != null) {
            dispatch(setKeyPerformanceIndicators(value.adminInfo.keyPerformanceIndicators));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
          setIsInitialized(true);
        });
    } else if (keyPerformanceIndicators != null && !isInitialized) {
      dispatch(setKeyPerformanceIndicators(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyPerformanceIndicators]);

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Kulcs teljesítmény mutatók
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Box mb={3}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {keyPerformanceIndicators && (
                <>
                  <Grid container spacing={3}>
                  <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Árbevétel előző évhez viszonyított alakulása"
                        data={keyPerformanceIndicators.incomePrevYearFulfillment}
                        targetText="Elvárható bevétel"
                        targetInfo={`Elvárható bevétel: Az előző évi gyűjtött bevétel alapján elvárható bevétel.`}
                        completementText="Gyűjtött bevétel"
                        completementInfo="A mai napig gyűjtött bevétel"
                        percentText="Teljesülés"
                        unit="Ft"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Marzs előző évhez viszonyított alakulása"
                        data={keyPerformanceIndicators.profitPrevYearFulfillment}
                        targetText="Elvárható marzs"
                        targetInfo={`Elvárható marzs: Az előző évi gyűjtött marzs alapján elvárható marzs.`}
                        completementText="Gyűjtött marzs"
                        completementInfo="A mai napig gyűjtött marzs"
                        percentText="Teljesülés"
                        unit="Ft"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Pontok előző évhez viszonyított alakulása"
                        data={keyPerformanceIndicators.pointPrevYearFulfillment}
                        targetText="Elvárható pont"
                        targetInfo={`Elvárható pont: Az előző évi gyűjtött pontok alapján elvárható pont.`}
                        completementText="Gyűjtött pontok"
                        completementInfo="A mai napig gyűjtött pontok"
                        percentText="Teljesülés"
                        unit="Pont"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Árbevétel cél időarányos alakulása"
                        data={keyPerformanceIndicators.incomePeriodFulfillment}
                        targetText="Időarányosan elvárható bevétel cél"
                        targetInfo={`Elvárható bevétel: A teljes adott évi bevétel cél napra pontos historikus szezonalitással leosztva.`}
                        completementText="Gyűjtött bevétel"
                        completementInfo="A mai napig gyűjtött bevétel"
                        percentText="Időarányos teljesülés"
                        unit="Ft"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Marzs cél időarányos alakulása"
                        data={keyPerformanceIndicators.profitPeriodFulfillment}
                        targetText="Időarányosan elvárható marzs cél"
                        targetInfo={`Elvárható marzs: A teljes adott évi marzs cél napra pontos historikus szezonalitással leosztva.`}
                        completementText="Gyűjtött marzs"
                        completementInfo="A mai napig gyűjtött marzs"
                        percentText="Időarányos teljesülés"
                        unit="Ft"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Pont cél időarányos alakulása"
                        data={keyPerformanceIndicators.pointPeriodFulfillment}
                        targetText="Időarányosan elvárható pont cél"
                        targetInfo={`Elvárható pont: A teljes adott évi pont cél napra pontos historikus szezonalitással leosztva.
                        Nem egyezik az egyénileg meghatározott negyedéves pont célokkal.`}
                        completementText="Gyűjtött pontok"
                        completementInfo="A mai napig gyűjtött pontok"
                        percentText="Időarányos teljesülés"
                        unit="Pont"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Árbevétel cél éves alakulása"
                        data={keyPerformanceIndicators.incomeYearlyFulfillment}
                        targetText="Éves elvárható bevétel cél"
                        targetInfo={`Elvárható bevétel: A teljes adott évi bevétel cél.`}
                        completementText="Gyűjtött bevétel"
                        completementInfo="A mai napig gyűjtött bevétel"
                        percentText="Éves teljesülés"
                        unit="Ft"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Marzs éves cél alakulása"
                        data={keyPerformanceIndicators.profitYearlyFulfillment}
                        targetText="Éves elvárható marzs cél"
                        targetInfo={`Elvárható marzs: A teljes adott évi marzs cél.`}
                        completementText="Gyűjtött marzs"
                        completementInfo="A mai napig gyűjtött marzs"
                        percentText="Éves teljesülés"
                        unit="Ft"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Gauge
                        title="Pont cél éves alakulása"
                        data={keyPerformanceIndicators.pointYearlyFulfillment}
                        targetText="Időarányosan elvárható pont cél"
                        targetInfo={`Elvárható pont: A teljes adott évi pont cél.
                        Megegyezik az egyénileg meghatározott negyedéves pont célokkal.`}
                        completementText="Gyűjtött pontok"
                        completementInfo="A mai napig gyűjtött pontok"
                        percentText="Éves teljesülés"
                        unit="Pont"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Box>
      </Collapse>
      <Divider sx={{ mb: 3 }} />
    </>
  );
};
