import { Box, Card as MuiCard, SxProps, Theme, Typography } from '@mui/material';
interface Props {
  title?: string;
  sx?: SxProps<Theme>;
  children: any;
}

export const Card: React.FC<Props> = ({ title, children, sx }: Props) => {
  return (
    <MuiCard variant="outlined" sx={sx}>
      <Box sx={{ p: 3 }}>
        {title && (
          <Typography variant="h5" component="div" sx={{ pb: 2 }}>
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </MuiCard>
  );
};
