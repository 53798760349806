import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  selectAgents,
  selectReloadVisits,
  resetAgentInfo,
  setAgents,
  setReloadVisits,
  setResponse,
} from '../../../slices/myTargetSlice';
import { AgentSummary } from '../../../models/common/AgentSummary';
import { Access } from '../../../access/Access';
import { addMessage } from '../../../slices/applicationSlice';
import { PleaseWait } from '../../common/PleaseWait';
import { Alert, Box, Container, FormControl, LinearProgress } from '@mui/material';
import { Card } from '../../common/Card';
import { LoadMyTargetResponse } from '../../../models/myTarget/LoadMyTargetResponse';
import { FormSelectField } from '../../common/input/FormSelectField';
import { Statistics } from './Statistics';
import { Performance } from './Performance';
import { PartnersAndTasks } from './PartnersAndTasks';
import { Visits } from '../../common/visit/Visits';
import { isAdminRole } from '../../../slices/userSlice';

interface MyTargetFormValue {
  agent: number | undefined;
}

const now = new Date();
const before = new Date();
before.setMonth(before.getMonth() - 1);

export const MyTarget: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const agents: AgentSummary[] | undefined | null = useSelector(selectAgents);
  const reloadVisits = useSelector(selectReloadVisits);
  const [searchParams] = useSearchParams();

  const agentId: number | undefined = useMemo(() => {
    if (isAdmin === true) {
      const value = searchParams.get('agent');
      if (value != null) {
        const parsed = parseInt(value);

        return isNaN(parsed) ? undefined : parsed;
      }
      return undefined;
    } else if (isAdmin === false) return -1;

    return undefined;
  }, [searchParams, isAdmin]);

  useEffect(() => {
    dispatch(resetAgentInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  useEffect(() => {
    if (agents === undefined && isAdmin === true && !isLoading) {
      dispatch(setAgents(null));
      setIsLoading(true);
      const access = new Access();
      access
        .loadMyTarget()
        .then((value: LoadMyTargetResponse | undefined) => {
          if (value?.error == null && value?.agents != null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, agents, isLoading]);

  const initialValues: MyTargetFormValue = {
    agent: agentId,
  };

  const agentOptions = useMemo(
    () => (agents == null ? [] : agents.map((agent: AgentSummary) => ({ value: agent.id, label: agent.name }))),
    [agents]
  );

  const handleSubmit = (values: MyTargetFormValue): void => {};

  const handleAgentChange = (value: number | undefined): void => {
    if (value) {
      const queryString = isAdmin && value ? `?agent=${value}` : '';
      navigate(`/my-target/${queryString}`);
    } else {
      navigate(`/my-target`);
      dispatch(resetAgentInfo(undefined));
    }
  };

  const handleSetReloadVisits = (value: boolean): void => {
    dispatch(setReloadVisits({ reload: value, scroll: value }));
  };

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {isAdmin === true && agents == null ? (
              <Alert severity="warning">Nincenek ügynökök</Alert>
            ) : (
              <>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                  {({ isSubmitting, errors, touched, setFieldValue, values }) => (
                    <>
                      {isSubmitting && (
                        <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                          <LinearProgress />
                        </Box>
                      )}
                      <Form>
                        {isAdmin && agentOptions != null && (
                          <Box sx={{ width: '100%', mb: 3 }}>
                            <FormControl fullWidth>
                              <FormSelectField
                                name="agent"
                                id="agent"
                                errors={errors}
                                isClearable
                                touched={touched}
                                options={agentOptions}
                                defaultValues={values.agent}
                                setFieldValue={setFieldValue}
                                placeholder="Ügynök"
                                onChange={handleAgentChange}
                              />
                            </FormControl>
                          </Box>
                        )}
                      </Form>
                    </>
                  )}
                </Formik>
                {agentId && (
                  <>
                    <Statistics agentId={agentId} />
                    <Performance agentId={agentId} />
                    <PartnersAndTasks agentId={agentId} isAdmin={isAdmin} />
                    <Box sx={{ mb: 4 }}>
                      <Visits
                        id="myTargetVisits"
                        partnerId={undefined}
                        isAdmin={isAdmin}
                        reloadVisits={reloadVisits}
                        setReloadVisits={handleSetReloadVisits}
                        agentId={agentId}
                        from={before}
                        to={now}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
