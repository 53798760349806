import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, Container, Grid, LinearProgress, Link } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FormTextField } from '../../common/input/FormTextField';
import { Card } from '../../common/Card';
import { NavLink, useNavigate } from 'react-router-dom';
import { securityClient } from '../../../security/securityClient';
import { SignInRequest } from '../../../models/SignInRequest';
import { selectMessages, selectToken, setMessages, setToken } from '../../../slices/applicationSlice';

export interface SignInFormValues {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let ref = useRef(null) as any;

  const errorMessages = useSelector(selectMessages);
  const appToken = useSelector(selectToken);
  const token = appToken ?? securityClient.getToken();

  const initialValues: SignInFormValues = {
    email: '',
    password: '',
  };

  useEffect(() => {
    if (errorMessages.length > 0 && ref) {
      ref.setSubmitting(false);
    }
  }, [errorMessages, ref]);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Kérem email címet adjon meg').required('Kérem adja meg email címét'),
    password: Yup.string().required('Kérem adja meg jelszavát'),
  });

  const handleSubmit = (values: SignInFormValues): void => {
    const criteria: SignInRequest = {
      userName: values.email,
      password: values.password,
    };
    securityClient
      .signIn(criteria)
      .then((value) => {
        dispatch(setToken(value?.token));
        dispatch(setMessages([]));
      })
      .catch((reason: any) => {
        if (reason.message) {
          dispatch(setMessages([{ message: reason.message, key: 'signin', hideSnackBar: true }]));
        }
      });
  };

  return (
    <Container maxWidth="sm">
      <Card title="Bejelentkezés">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={(instance) => {
            ref = instance;
          }}
        >
          {({ isSubmitting }) => (
            <>
              {isSubmitting && (
                <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                  <LinearProgress />
                </Box>
              )}
              <Form>
                <Grid container alignItems="stretch" justifyContent="center">
                  <Grid item component={Box} paddingTop={2} xs={10}>
                    <FormTextField label="Email" name="email" id="email" fullWidth variant="outlined" />
                  </Grid>
                  <Grid item component={Box} paddingTop={2} xs={10}>
                    <FormTextField
                      label="Jelszó"
                      name="password"
                      id="password"
                      fullWidth
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                  {errorMessages.length > 0 && (
                    <Grid item component={Box} paddingTop={2} xs={10}>
                      <Alert severity="error">{errorMessages[0].message}</Alert>
                    </Grid>
                  )}
                </Grid>

                <Box sx={{ pt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Link component={NavLink} to="/forgot-password" underline="none" color="primary">
                    Elfelejtett jelszó
                  </Link>
                  <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                    Bejelentkezés
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </Card>
    </Container>
  );
};
