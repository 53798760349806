import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { removeMessage, selectMessages } from '../../slices/applicationSlice';
import { Message } from '../../models/slices/ApplicationContext';

export const MessageHandler: React.FC = () => {
  const dispatch = useDispatch();
  const dom: JSX.Element[] = [];
  try {
    const messages = useSelector(selectMessages);

    const removeError = (key: string) => {
      dispatch(removeMessage(key));
    };

    messages
      .filter((message: Message) => message.hideSnackBar !== true)
      .forEach((value: Message) => {
        setTimeout(() => {
          removeError(value.key);
        }, 5000);

        dom.push(
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open
            onClose={() => {
              removeError(value.key);
            }}
            //autoHideDuration={3000}
            key={'topright'}
          >
            <Alert
              key={value.key}
              severity={value.severity ?? 'error'}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    removeError(value.key);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {value.message}
            </Alert>
          </Snackbar>
        );
      });
  } catch (e) {
    console.error(e);
  }

  return <>{dom.length > 0 && dom}</>;
};
