import { Route, Routes } from 'react-router-dom';
import { Partners } from '../components/views/partners/Partners';
import { NotFound } from '../components/views/NotFound';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { SignIn } from '../components/views/signIn/SignIn';
import { MyTarget } from '../components/views/myTarget/MyTarget';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { MyAccount } from '../components/views/myAccount/MyAccount';
import { ForgotPassword } from '../components/views/forgotPassword/ForgotPassword';
import { Admin } from '../components/views/admin/Admin';

export enum UserType {
  agent,
  admin,
}

export interface AppRoute {
  path: string;
  menu: string;
  name: string;
  element: React.ReactNode;
  icon: React.ReactNode;
  isPublic: boolean;
  showInMenu: boolean;
  exact: boolean;
  hideMenu: boolean;
  roles?: UserType[];
}

export const routes: AppRoute[] = [
  {
    path: '/',
    name: 'Partnerek',
    menu: 'partners',
    element: <Partners />,
    icon: <HomeOutlinedIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
  },
  {
    path: '/partners/:partnerId',
    name: 'Partnerek',
    menu: 'partners',
    element: <Partners />,
    icon: <HomeOutlinedIcon />,
    isPublic: false,
    showInMenu: false,
    exact: true,
    hideMenu: false,
  },
  {
    path: '/my-target',
    name: 'Saját teljesítmény',
    menu: 'myTarget',
    element: <MyTarget />,
    icon: <AdsClickIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
  },
  {
    path: '/admin',
    name: 'Admin',
    menu: 'admin',
    element: <Admin />,
    icon: <AdminPanelSettingsIcon />,
    isPublic: false,
    showInMenu: true,
    exact: true,
    hideMenu: false,
    roles: [UserType.admin],
  },
  {
    path: '/my-account',
    name: 'Profilom',
    menu: 'myAccount',
    element: <MyAccount />,
    icon: null,
    isPublic: false,
    showInMenu: false,
    exact: true,
    hideMenu: false,
  },
  {
    path: '/forgot-password',
    name: 'Elfelejtett jelszó',
    menu: 'forgotPassword',
    element: <ForgotPassword />,
    icon: null,
    isPublic: true,
    showInMenu: false,
    exact: true,
    hideMenu: true,
  },
  {
    icon: null,
    path: '/sign-in',
    name: 'Bejelentkezés',
    element: <SignIn />,
    exact: true,
    showInMenu: false,
    isPublic: true,
    menu: 'signin',
    hideMenu: true,
  },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route: AppRoute) => (
        <Route path={route.path} key={route.path} element={route.element} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
