import { useRef } from 'react';
import { Card, Box, Typography, LinearProgress, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Access } from '../../../access/Access';
import { useDispatch } from 'react-redux';
import { SaveAccountRequest } from '../../../models/account/SaveAccountRequest';
import { SaveAccountResponse } from '../../../models/account/SaveAccountResponse';
import { addMessage } from '../../../slices/applicationSlice';
import { FormTextField } from '../../common/input/FormTextField';

interface PasswordValues {
  old: string;
  new: string;
  newValidation: string;
}

export const Password = () => {
  const dispatch = useDispatch();
  let ref = useRef(null) as any;

  const initialValues: PasswordValues = {
    old: '',
    new: '',
    newValidation: '',
  };

  const validationSchema = Yup.object({
    old: Yup.mixed().required('Kérem adja meg a jelenlegi jelszavát'),
    new: Yup.string().required('Kérem adja meg az új jelszavát'),
    newValidation: Yup.string()
      .required('Kérem erősítse meg az új jelszavát')
      .oneOf([Yup.ref('new')], 'A két jelszó különbözik'),
  });

  const handleSubmit = (value: PasswordValues): void => {
    const access = new Access();
    const request: SaveAccountRequest = {
      savePassword: {
        old: value.old,
        new: value.new,
      },
    };
    access
      .saveAccount(request)
      .then((value: SaveAccountResponse | undefined) => {
        if (value?.error == null) {
          dispatch(addMessage({ message: 'A jelszó megváltozott', severity: 'success', key: 'save-account' }));
          if (ref) {
            ref.resetForm();
          }
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        if (ref) {
          ref.setSubmitting(false);
        }
      });
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">Jelszó módosítás</Typography>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={(instance) => {
          ref = instance;
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <>
            {isSubmitting && (
              <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                <LinearProgress />
              </Box>
            )}
            <Form>
              <Box my={2}>
                <FormTextField size="small" label="Jelenlegi jelszó" name="old" id="old" fullWidth type="password" />
              </Box>
              <Box my={2}>
                <FormTextField size="small" label="Ú jelszó" name="new" id="new" fullWidth type="password" />
              </Box>
              <Box my={2}>
                <FormTextField
                  size="small"
                  label="Új jelszó megerősítése"
                  name="newValidation"
                  id="newValidation"
                  fullWidth
                  type="password"
                />
              </Box>
              <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  Módosítás
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};
