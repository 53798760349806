import { Box, Chip, Collapse, Grid, IconButton, Theme, Typography } from '@mui/material';
import { Partner } from '../../../models/partners/Partner';
// import { Increase } from '../../../assets/Increase';
// import { Decrease } from '../../../assets/Decrease';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PartnerPriority } from '../../../models/PartnerPriority';
import { EditableValue } from '../../common/EditableValue';
import { InfoBox } from '../../common/InfoBox';
import { useFormat } from '../../../utility/useFormat';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDispatch } from 'react-redux';
import { setOpenGeneralInfo } from '../../../slices/partnersSlice';
import { SavePreviousYearOfficialIncomeCriteria } from '../../../models/partners/SavePreviousYearOfficialIncomeCriteria';
import { SavePreviousYearRelevantIncomeCriteria } from '../../../models/partners/SavePreviousYearRelevantIncomeCriteria';
import { SaveSalesPotentialCriteria } from '../../../models/partners/SaveSalesPotentialCriteria';
import { SaveSalesPointTargetCriteria } from '../../../models/partners/SaveSalesPointTargetCriteria';

interface Props {
  partner: Partner;
}

const getPartnerPriority = (priority: PartnerPriority): string => {
  switch (priority) {
    case PartnerPriority.High:
      return 'Magas';
    case PartnerPriority.Medium:
      return 'Közepes';
    case PartnerPriority.Low:
      return 'Alacsony';
  }
};

const getPartnerPriorityColor = (priority: PartnerPriority) => {
  switch (priority) {
    case PartnerPriority.High:
      return '#E26025';
    case PartnerPriority.Low:
    default:
      return 'rgba(0, 0, 0, 0.08)';
    case PartnerPriority.Medium:
      return '#49ADF5';
  }
};

export const Summary: React.FC<Props> = ({ partner }: Props) => {
  const dispatch = useDispatch();
  const now = new Date();
  const thisYear = now.getFullYear();
  const previousYear = thisYear - 1;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { formatCurrency, formatNumber } = useFormat();

  // const difference = () => {
  //   if (
  //     partner.generalSaleData.previousYearPurchasesSamePeriod != null &&
  //     partner.currentYearPurchases &&
  //     partner.generalSaleData.previousYearPurchasesSamePeriod !== partner.currentYearPurchases
  //   ) {
  //     const diff = partner.generalSaleData.previousYearPurchasesSamePeriod - partner.currentYearPurchases;
  //     const percent = 1 - partner.currentYearPurchases / partner.generalSaleData.previousYearPurchasesSamePeriod;
  //     const evolution = Math.abs(Math.round(percent * 10000) / 100);
  //     const label = (
  //       <>
  //         {diff < 0 ? <Increase style={{ marginRight: 4 }} /> : <Decrease style={{ marginRight: 4 }} />}
  //         {`${evolution}%`}
  //       </>
  //     );
  //     return <Chip size="small" label={label} color={diff < 0 ? 'success' : 'error'} />;
  //   }
  //   return null;
  // };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        Általános értékesítési alapadatok
        {isCollapsed ? (
          <KeyboardArrowUpIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={(theme: Theme) => ({
              height: theme.spacing(4),
              width: theme.spacing(4),
            })}
          />
        )}
      </Typography>
      <Collapse in={!isCollapsed}>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <InfoBox title="Bolt csoportja" info="Bolt jellege">
              <Typography
                variant="h5"
                title={partner.generalSaleData.partnerGroup ? partner.generalSaleData.partnerGroup : 'N/A'}
                sx={{ opacity: 0.5, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
              >
                {partner.generalSaleData.partnerGroup ? partner.generalSaleData.partnerGroup : 'N/A'}
              </Typography>
            </InfoBox>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} sx={{ '& .MuiTypography-h5': { opacity: 0.5 } }}>
            <InfoBox
              title={`${partner.generalSaleData.previousYearOfficialIncome.valueForYear} - Bolt előző évi árbevétele`}
              info="A bolt hivatalos bevétele, amennyiben elérhető az információ"
            >
              <EditableValue<SavePreviousYearOfficialIncomeCriteria>
                id="generalSaleData.previousYearOfficialIncome"
                partnerId={partner.id}
                actualLabel={`${partner.generalSaleData.previousYearOfficialIncome.valueForYear} - Bolt előző évi árbevétele`}
                data={partner.generalSaleData.previousYearOfficialIncome}
                title="Bolt előző évi árbevétele"
                label={`Új érték - ${partner.generalSaleData.previousYearOfficialIncome.editForYear}`}
                propertyName="savePreviousYearOfficialIncome"
              />
            </InfoBox>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} sx={{ '& .MuiTypography-h5': { opacity: 0.5 } }}>
            <InfoBox
              title={`${partner.generalSaleData.previousYearRelevantIncome.valueForYear} - Wolker releváns bevétel`}
              info="A bolt becsült Wolker releváns árbevétele"
            >
              <EditableValue<SavePreviousYearRelevantIncomeCriteria>
                id="generalSaleData.previousYearRelevantIncome"
                partnerId={partner.id}
                actualLabel={`${partner.generalSaleData.previousYearRelevantIncome.valueForYear} - Wolker releváns bevétel`}
                data={partner.generalSaleData.previousYearRelevantIncome}
                title="Wolker releváns bevétel"
                label={`Új érték - ${partner.generalSaleData.previousYearRelevantIncome.editForYear}`}
                propertyName="savePreviousYearRelevantIncome"
              />
            </InfoBox>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} sx={{ '& .MuiTypography-h5': { opacity: 0.5 } }}>
            <InfoBox
              title={`${partner.generalSaleData.salesPotential.valueForYear} - Wolker értékesítési potenciál`}
              info={`A boltban hosszabb távon elérhető Wolker bevétel becslés.
                  Alapbeállításként a Bolt releváns bevételének 1/3-a`}
            >
              <EditableValue<SaveSalesPotentialCriteria>
                id="generalSaleData.salesPotential"
                partnerId={partner.id}
                actualLabel={`${partner.generalSaleData.salesPotential.valueForYear} - Wolker értékesítési potenciál`}
                data={partner.generalSaleData.salesPotential}
                title="Wolker értékesítési potenciál"
                label={`Új érték - ${partner.generalSaleData.salesPotential.editForYear}`}
                propertyName="saveSalesPotential"
              />
            </InfoBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <InfoBox
              title="Bolt prioritása"
              info={`Számolt érték a bolt potenciálja és vásárlási szokásai alapján.
                  A látogatási gyakoriságot és odafigyelést ez alapján érdemes kalibrálnod`}
            >
              <Chip
                size="small"
                sx={{ my: 0.3, backgroundColor: getPartnerPriorityColor(partner.generalSaleData.partnerPriority) }}
                label={getPartnerPriority(partner.generalSaleData.partnerPriority)}
              />
            </InfoBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InfoBox title={`${previousYear} - Előző évi Wolker értékesítés`} info="Előző évi tény értékesítés">
              <Typography variant="h5">
                {partner.generalSaleData.previousYearPurchases
                  ? formatCurrency(partner.generalSaleData.previousYearPurchases)
                  : 'N/A'}
              </Typography>
            </InfoBox>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InfoBox
              title={`${partner.generalSaleData.salesPointTarget.valueForYear} - Értékesítési pont cél`}
              info="A személyes érétkesítői pont célból adott boltra eső pont cél"
            >
              <EditableValue<SaveSalesPointTargetCriteria>
                id="generalSaleData.salesPointTarget"
                partnerId={partner.id}
                actualLabel={`${partner.generalSaleData.salesPointTarget.valueForYear} - Értékesítési pont cél`}
                data={partner.generalSaleData.salesPointTarget}
                title="Értékesítési pont cél"
                label={`Új érték - ${partner.generalSaleData.salesPointTarget.editForYear}`}
                format={(value: number) => formatNumber(value, ' Pont')}
                propertyName="saveSalesPointTarget"
              />
            </InfoBox>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InfoBox
              title={`${partner.generalSaleData.engagement?.valueForYear} - Bónusz megállapodás`}
              info="Bolttal kötött ez évi bónusz megállapodás értéke"
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  variant="h5"
                  sx={
                    partner.generalSaleData.engagement?.isActual === false
                      ? (theme: Theme) => ({
                          textDecoration: 'line-through',
                          textDecorationColor: theme.palette.error.light,
                        })
                      : undefined
                  }
                >
                  {partner.generalSaleData.engagement?.value != null
                    ? formatCurrency(partner.generalSaleData.engagement.value)
                    : 'N/A'}
                </Typography>{' '}
                {partner.generalSaleData.engagement?.isEditable && (
                  <IconButton
                    title="Módosítás"
                    onClick={() => {
                      dispatch(setOpenGeneralInfo(true));
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              {partner.generalSaleData.engagement?.isActual === false && (
                <Typography variant="caption" color="error" sx={{ display: 'block', mt: -1, mb: -2 }}>
                  Az érték már nem releváns.
                </Typography>
              )}
            </InfoBox>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};
