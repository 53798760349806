import { Box, Grid, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { useFormat } from '../../../utility/useFormat';
import { Purchases as PurchasesModel } from '../../../models/common/Purchases';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Module } from '../../../models/Module';
import { useMemo } from 'react';

interface Props {
  data: PurchasesModel;
  module: Module;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
};

export const Purchases: React.FC<Props> = ({ data, module }: Props) => {
  const { formatNumber, formatCurrency } = useFormat();

  const chartData = useMemo(() => {
    const info = {
      average: {
        [Module.partners]: 'Hányszor vásárol évente',
        [Module.myTarget]: 'Hányszor vásárolnak a boltjai évente',
        [Module.admin]: 'Hányszor vásárolnak boltok évente',
      },
      previousYearAverage: {
        [Module.partners]: 'Hányszor vásárolt az előző évben',
        [Module.myTarget]: 'Hányszor vásároltak a boltjai az előző évben',
        [Module.admin]: 'Hányszor vásároltak a boltok az előző évben',
      },
      cartSize: {
        [Module.partners]: 'Átlagosan mekkora értékben vásárol',
        [Module.myTarget]: 'Átlagosan mekkora értékben vásárolnak a boltjai',
        [Module.admin]: 'Átlagosan mekkora értékben vásárolnak a boltok',
      },
      cartSizePreviousYear: {
        [Module.partners]: 'Átlagosan mekkora értékben vásárolt az előző évben',
        [Module.myTarget]: 'Átlagosan mekkora értékben vásároltak a boltjai az előző évben',
        [Module.admin]: 'Átlagosan mekkora értékben vásároltak a boltok az előző évben',
      },
    };

    const purchaseFrequency = {
      labels: [''],
      datasets: [
        {
          label: 'Gördülő 12 havi vásárlási gyakoriság',
          info: info.average[module],
          data: [data.purchaseFrequency.average],
          backgroundColor: 'rgb(237, 125, 49)',
        },
        {
          label: 'Előző évi vásárlási gyakoriság',
          info: info.previousYearAverage[module],
          data: [data.purchaseFrequency.previousYearAverage],
          backgroundColor: 'rgba(237, 148, 40, 1)',
        },
      ],
    };

    if (data.purchaseFrequency.similarStores) {
      purchaseFrequency.datasets.push({
        label: 'Referencia - saját csoport',
        info: 'Az ilyen típusú boltok első kvadránsában hányszor vásárolnak',
        data: [data.purchaseFrequency.similarStores],
        backgroundColor: 'rgb(112,112,112)',
      });
    }

    purchaseFrequency.datasets.push({
      label: 'Referencia - teljes Wolker',
      info: 'Az összes bolt első kvadránsában hányszor vásárolnak',
      data: [data.purchaseFrequency.allStores],
      backgroundColor: 'rgb(160,160,160)',
    });

    const cartSize = {
      labels: [''],
      datasets: [
        {
          label: 'Gördülő 12 havi átlagos kosárméret',
          info: info.cartSize[module],
          data: [data.cartSize.average],
          backgroundColor: 'rgb(237, 125, 49)',
        },
        {
          label: 'Előző évi átlagos kosárméret',
          info: info.cartSizePreviousYear[module],
          data: [data.cartSize.previousYearAverage],
          backgroundColor: 'rgba(237, 148, 40, 1)',
        },
      ],
    };
    if (data.cartSize.similarStores) {
      cartSize.datasets.push({
        label: 'Referencia - saját csoport',
        info: 'Az ilyen típusú boltok első kvadránsában mi az átlagos kosárméret',
        data: [data.cartSize.similarStores],
        backgroundColor: 'rgb(112,112,112)',
      });
    }

    cartSize.datasets.push({
      label: 'Referencia - teljes Wolker',
      info: 'A összes bolt első kvadránsában mi az átlagos kosárméret',
      data: [data.cartSize.allStores],
      backgroundColor: 'rgb(160,160,160)',
    });
    return { purchaseFrequency, cartSize };
  }, [data, module]);

  return (
    <Box mt={2}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Vásárlási gyakoriság és kosárméret
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={8} lg={6}>
          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 400 }}>
            <Bar
              options={{
                ...options,
                plugins: {
                  ...options.plugins,
                  title: {
                    display: true,
                    text: 'Vásárlási gyakoriság (db / év)',
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.dataset.label || '';

                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          label += formatNumber(context.parsed.y, undefined, 2);
                        }
                        const labels = [label];

                        const info = (context.dataset as any).info;

                        if (info) labels.push(info);

                        return labels;
                      },
                    },
                  },
                },
              }}
              data={chartData.purchaseFrequency}
              updateMode="resize"
              plugins={[ChartDataLabels]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 400 }}>
            <Bar
              options={{
                ...options,
                plugins: {
                  ...options.plugins,
                  title: {
                    display: true,
                    text: 'Kosármért (Ft)',
                  },
                  datalabels: {
                    formatter: (value: any) => formatCurrency(value),
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.dataset.label || '';

                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          label += formatCurrency(context.parsed.y);
                        }
                        const labels = [label];

                        const info = (context.dataset as any).info;

                        if (info) labels.push(info);

                        return labels;
                      },
                    },
                  },
                },
              }}
              data={chartData.cartSize}
              updateMode="resize"
              plugins={[ChartDataLabels]}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
