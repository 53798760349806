import { DateValidationError, DatePicker as MuiDatePicker, MobileDatePickerProps as MuiDatePickerProps, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { FieldInputProps, FieldMetaProps, FieldHelperProps, useField } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';

export interface FormDatePickerProps extends Omit<MuiDatePickerProps<Date>, 'name' | 'value' | 'error' | 'onChange'> {
  name: string;
  onChange?: MuiDatePickerProps<Date>['onChange'];
  inputProps?: TextFieldProps;
}

export function useFieldToDatePicker<Val = unknown>(
  { name, disabled, inputProps, ...props }: FormDatePickerProps,
  customize?: (
    props: [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>]
  ) => Partial<Omit<FormDatePickerProps, 'name'>>
): MuiDatePickerProps<Date> {
  const fieldProps = useField(name);
  const [field, meta, helpers] = fieldProps;

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  const properties = {
    ...props,
    ...field,
    disabled: disabled !== undefined ? disabled : false,
    onChange(date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) {
      helpers.setValue(date);
    },
    renderInput: (params: TextFieldProps) => (
      <TextField
        {...params}
        error={showError}
        helperText={showError ? fieldError : params.helperText}
        {...inputProps}
      />
    ),
    ...(customize ? fieldProps : undefined),
  };

  return properties;
}

export function FormDatePicker({ ...props }: FormDatePickerProps) {
  return <MuiDatePicker {...useFieldToDatePicker(props)} />;
}
