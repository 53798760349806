import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPartner,
  selectPartners,
  selectAgents,
  setPartner,
  setResponse,
  setPartners,
} from '../../../slices/partnersSlice';
import { PartnerSummary } from '../../../models/partners/PartnerSummary';
import { Access } from '../../../access/Access';
import { LoadPartnerResponse } from '../../../models/partners/LoadPartnerResponse';
import { PleaseWait } from '../../common/PleaseWait';
import { Alert, Box, Container, FormControl, LinearProgress } from '@mui/material';
import { Card } from '../../common/Card';
import { Form, Formik } from 'formik';
import { FormSelectField } from '../../common/input/FormSelectField';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Partner } from '../../../models/partners/Partner';
import { PartnerInfoResponse } from '../../../models/partners/PartnerInfoResponse';
import { PartnerInfoRequest } from '../../../models/partners/PartnerInfoRequest';
import { PartnerInfo } from './PartnerInfo';
import { GeneralInfo } from './GeneralInfo';
import { addMessage } from '../../../slices/applicationSlice';
import { securityClient } from '../../../security/securityClient';
import { AgentSummary } from '../../../models/common/AgentSummary';
import { Gallery } from './Gallery';
import { isAdminRole } from '../../../slices/userSlice';

interface PartnersFormValue {
  agent: number | undefined;
  partner: number | undefined;
}

export const Partners: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoadingLoad, setIsLoadingLoad] = useState<boolean>(false);
  const [isLoadingPartner, setIsLoadingPartner] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const partners: PartnerSummary[] | undefined | null = useSelector(selectPartners);
  const agents: AgentSummary[] | undefined = useSelector(selectAgents);
  const partner: Partner | undefined | null = useSelector(selectPartner);

  const { partnerId } = useParams<{ partnerId: string }>();
  const [searchParams] = useSearchParams();
  const partnerIdentifier = partnerId ? parseInt(partnerId) : 0;

  const isValidPartnerId = (): boolean => partnerIdentifier !== 0 && !isNaN(partnerIdentifier);

  const agent: number | undefined = useMemo(() => {
    const value = searchParams.get('agent');
    if (value != null) {
      const parsed = parseInt(value);

      return isNaN(parsed) ? undefined : parsed;
    }
    return undefined;
  }, [searchParams]);

  const isLoading = isLoadingLoad || isLoadingPartner;

  useEffect(() => {
    if (isValidPartnerId()) {
      dispatch(setPartner(null));
      setIsLoadingPartner(true);
      const access = new Access();
      const criteria: PartnerInfoRequest = {
        partnerId: partnerIdentifier,
        getInfo: {},
      };
      access
        .partnerInfo(criteria)
        .then((value: PartnerInfoResponse | undefined) => {
          if (value?.error == null && value?.partner != null) {
            dispatch(setPartner(value.partner));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingPartner(false);
        });
    } else if (partner != null) {
      dispatch(setPartner(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerIdentifier]);

  useEffect(() => {
    if (partners === undefined && !isLoading) {
      dispatch(setPartners(null));
      setIsLoadingLoad(true);
      const access = new Access();
      access
        .loadPartner()
        .then((value: LoadPartnerResponse | undefined) => {
          if (value?.error == null && value?.partners != null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingLoad(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agentOptions = useMemo(
    () => (agents == null ? [] : agents.map((agent: AgentSummary) => ({ value: agent.id, label: agent.name }))),
    [agents]
  );

  const initialValues: PartnersFormValue = {
    partner: partnerIdentifier,
    agent: agent,
  };

  const handleSubmit = (values: PartnersFormValue): void => {};

  const handlePartnerChange = (value: any, agentId: number | undefined): void => {
    if (value) {
      const queryString = isAdmin && agentId ? `?agent=${agentId}` : '';
      navigate(`/partners/${value}${queryString}`);
    } else {
      navigate(`/`);
      dispatch(setPartner(undefined));
    }
  };

  const handleAgentChange = () => {
    dispatch(setPartner(undefined));
  };

  const images =
    partner?.generalInfo.photos != null && partner.generalInfo.photos.length > 0
      ? partner.generalInfo.photos?.map((p: string, index: number) => ({
          src: p,
          id: index,
          title: '',
        }))
      : // csereld ki ezt ures tombre, amint megvannak az adatok []
        [];

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoading || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {partners == null ? (
              <Alert severity="warning">Nincenek partnerek</Alert>
            ) : (
              <>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                  {({ isSubmitting, errors, touched, setFieldValue, values }) => {
                    const partnerOptions =
                      partners == null
                        ? []
                        : (!isAdmin ? partners : partners.filter((partner) => partner.agentId === values.agent)).map(
                            (partner: PartnerSummary) => ({ value: partner.id, label: partner.name })
                          );

                    return (
                      <>
                        {isSubmitting && (
                          <Box className="working" marginLeft={-3} marginRight={-3} marginBottom={2}>
                            <LinearProgress />
                          </Box>
                        )}
                        <Form>
                          {isAdmin && agentOptions != null && (
                            <Box sx={{ width: '100%', mb: 3 }}>
                              <FormControl fullWidth>
                                <FormSelectField
                                  name="agent"
                                  id="agent"
                                  errors={errors}
                                  isClearable
                                  touched={touched}
                                  options={agentOptions}
                                  defaultValues={values.agent}
                                  setFieldValue={setFieldValue}
                                  placeholder="Ügynök"
                                  onChange={handleAgentChange}
                                />
                              </FormControl>
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ width: '100%' }}>
                              <FormControl fullWidth>
                                <FormSelectField
                                  name="partner"
                                  id="partner"
                                  errors={errors}
                                  isClearable
                                  touched={touched}
                                  options={partnerOptions}
                                  defaultValues={values.partner}
                                  setFieldValue={setFieldValue}
                                  placeholder="Partner"
                                  onChange={(value) => handlePartnerChange(value, values.agent)}
                                />
                              </FormControl>
                            </Box>
                            {partner && (
                              <Box mx={3} sx={{ display: 'flex' }}>
                                <GeneralInfo partner={partner} />
                                <Gallery images={images} />
                              </Box>
                            )}
                          </Box>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
                {partner && <PartnerInfo partner={partner} isAdmin={isAdmin} agentId={agent} />}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
