import { Box, Grid, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { useFormat } from '../../../utility/useFormat';
import { ProductWidth as ProductWidthModel } from '../../../models/common/ProductWidth';
import { HeatMap } from './HeatMap';
import { useMemo } from 'react';
import { TopBrand } from '../../../models/common/TopBrand';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Module } from '../../../models/Module';

interface Props {
  data: ProductWidthModel;
  module: Module;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
};

const topBrandsOption = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      text: 'Top forgalmazott márkák (százalékos megoszlás)',
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const colors = [
  '#659E37',
  'rgb(200, 162, 235)',
  'rgb(75, 192, 192)',
  'rgb(237, 125, 49)',
  'rgb(255, 99, 132)',
  'rgb(50,50,50)',
  'rgba(255, 0, 40, 1)',
  'rgb(160,160,160)',
  'rgb(53, 162, 235)',
  'rgba(237, 0, 200, 0.9)',
];

export const ProductWidth: React.FC<Props> = ({ data, module }: Props) => {
  const { formatNumber } = useFormat();

  const productWidthData = useMemo(() => {
    const info = {
      currentYear: {
        [Module.partners]: 'Az összes Wolker által értékesített termék közül hány fajtát forgalmaz a bolt',
        [Module.myTarget]: 'Az összes Wolker által értékesített termék közül hány fajtát forgalmaznak a boltjai',
        [Module.admin]: 'Az összes Wolker által értékesített termék közül hány fajtát forgalmaznak a boltok',
      },
      previousYear: {
        [Module.partners]:
          'Az összes Wolker által értékesített termék közül hány fajtát forgalmazott a bolt az előző évben',
        [Module.myTarget]:
          'Az összes Wolker által értékesített termék közül hány fajtát forgalmaztak a boltjai az előző évben',
        [Module.admin]:
          'Az összes Wolker által értékesített termék közül hány fajtát forgalmaztak a boltok az előző évben',
      },
    };

    const productWidth = {
      labels: [''],
      datasets: [
        {
          label: 'Gördülő 12 havi átlagos termék szélesség (db)',
          info: info.currentYear[module],
          data: [data.currentYear.count],
          percent: data.currentYear.percent,
          backgroundColor: 'rgb(237, 125, 49)',
        },
        {
          label: 'Előző évi átlagos termék szélesség (db)',
          info: info.previousYear[module],
          data: [data.previousYear.count],
          percent: data.previousYear.percent,
          backgroundColor: 'rgba(237, 148, 40, 1)',
        },
      ],
    };
    if (data.similarStores) {
      productWidth.datasets.push({
        label: 'Referencia - saját csoport',
        info: 'Az ilyen típusú boltok első kvadránsában hány terméket tartanak átlagosan',
        data: [data.similarStores.count],
        percent: data.similarStores.percent,
        backgroundColor: 'rgb(112,112,112)',
      });
    }

    productWidth.datasets.push({
      label: 'Referencia - teljes Wolker',
      info: 'Az összes boltunk első kvadránsában hány terméket tartanak átlagosan',
      data: [data.allStores.count],
      percent: data.allStores.percent,
      backgroundColor: 'rgb(160,160,160)',
    });

    return productWidth;
  }, [data, module]);

  const topBrandsData = useMemo(() => {
    if (data.topBrands == null) return null;
    const label = {
      current12Months: {
        [Module.partners]: 'Gördülő 12 havi',
        [Module.myTarget]: 'Saját - Jelenlegi 12 hónap',
        [Module.admin]: '',
      },
      previous12Months: {
        [Module.partners]: 'Előző évi',
        [Module.myTarget]: 'Saját - Előző évi',
        [Module.admin]: '',
      },
      benchmarkGroup12Months: {
        [Module.partners]: 'Referencia - saját csoport',
        [Module.myTarget]: '',
        [Module.admin]: '',
      },
      benchmark12Months: {
        [Module.partners]: 'Referencia - teljes Wolker',
        [Module.myTarget]: 'Referencia Wolker - Jelenlegi 12 hónap',
        [Module.admin]: '',
      },
    };

    const hasBenchmarkGroup12Months = data.topBrands.length > 0 && data.topBrands[0].benchmarkGroup12Months != null;
    const labels = [label.current12Months[module], label.previous12Months[module]];
    if (hasBenchmarkGroup12Months) {
      labels.push(label.benchmarkGroup12Months[module]);
    }
    labels.push(label.benchmark12Months[module]);

    const datasets = data.topBrands.map((topBrand: TopBrand, index: number) => {
      const colorIndex = index > colors.length ? index % colors.length : index;
      const data = [topBrand.current12Months, topBrand.previous12Months];
      if (hasBenchmarkGroup12Months) {
        data.push(topBrand.benchmarkGroup12Months);
      }
      data.push(topBrand.benchmark12Months);
      return {
        label: topBrand.name,
        data,
        backgroundColor: colors[colorIndex],
      };
    });

    const topBrands = {
      labels,
      datasets,
    };

    return topBrands;
  }, [data.topBrands, module]);

  return (
    <Box mt={2}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Termék szélesség
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={6}>
          <Box sx={{ position: 'relative', margin: 'auto', width: '100%', height: 400 }}>
            <Bar
              options={{
                ...options,
                plugins: {
                  ...options.plugins,
                  title: {
                    display: true,
                    text: 'Termék szélesség (fajta)',
                  },
                  datalabels: {
                    formatter: (value: any, context) => {
                      let label = value;

                      const percent = (context.dataset as any).percent;
                      if (percent != null) {
                        label += ' - ' + formatNumber(percent) + ' %';
                      }
                      return label;
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.dataset.label || '';

                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.y !== null) {
                          label += formatNumber(context.parsed.y);
                        }
                        const percent = (context.dataset as any).percent;

                        if (percent != null) {
                          label += ' - ' + formatNumber(percent) + ' %';
                        }

                        const labels = [label];

                        const info = (context.dataset as any).info;

                        if (info) labels.push(info);

                        return labels;
                      },
                    },
                  },
                },
              }}
              data={productWidthData}
              updateMode="resize"
              plugins={[ChartDataLabels]}
            />
          </Box>
        </Grid>
        {topBrandsData && (
          <Grid item xs={12} md={8} lg={6}>
            <Box sx={{ position: 'relative', margin: 'auto', width: '100%', maxWidth: '100%', height: 500 }}>
              <Bar options={topBrandsOption} data={topBrandsData} plugins={[ChartDataLabels]} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Box sx={{ my: 3 }}>
        <HeatMap data={data.heatMap} module={module}/>
      </Box>
    </Box>
  );
};
