import { Container } from '@mui/material';
import { Card } from '../../common/Card';
import { KeyPerformanceIndicators } from './KeyPerformanceIndicators';
import { FinancialPerformanceIndicators } from './FinancialPerformanceIndicators';
import { AgentsPerformance } from './AgentsPreference';

export const Admin: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        <KeyPerformanceIndicators />
        <FinancialPerformanceIndicators />
        <AgentsPerformance />
      </Card>
    </Container>
  );
};
